import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link, NavLink } from 'react-router-dom';
import AuthDetails from './auth/AuthDetails';
import { FaSearch } from 'react-icons/fa';
import { getDatabase, onValue } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { ref } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import Logo from './Favicon.png';
import WhiteLogo from './LogoWhite3.png';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'

function Navbar() {
  const [bracketData, setBracketData] = useState({});
  const [bracketIdSuggestions, setBracketIdSuggestions] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng); // Set the language state to the selected language
  };

  const [language, setLanguage] = useState('en'); // Default language is English
  

 

  return (
    <div className='Nav-headerV8'>
      <nav className='navbar navbar-expand-lg navbar-light bg-light border-bottom border-dark-subtle'>
        <div className='container-fluid'>
          <Link to='/' className='navbar-brand' href='#' style={{ color: '' }}>
            {/* Interstellar Predictions */}
            <img src={WhiteLogo} alt="" width='120' height="35"/>
            {/* Interstellar Predictions */}

          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' style={{ color: 'black' }}></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item dropdown text-dark'>
                <a
                  className='nav-link dropdown-toggle text-dark navhover'
                  href='#'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Huddles
                </a>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <li className='text-dark'>
                    <NavLink to='/dashboard' className='dropdown-item  custom-bg-purple'>
                      Dashboard
                    </NavLink>
                  </li>
        <li>
          <NavLink to='/globalleaderboard' className='dropdown-item custom-bg-purple'>
          {t('player_leaderboard')}
          </NavLink>
        </li>
                  <hr className='dropdown-divider' />
             
              
                  <li>
                  <NavLink to='/joinhuddle' className='dropdown-item  custom-bg-purple'>
  {t('join_huddle_link')}
</NavLink>

                  </li>
                  <li>
                    <NavLink to='/createhuddle' className='dropdown-item  custom-bg-purple'>
                    {t('create_huddle_link')}
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to='/faq' className='dropdown-item '>
                      Rules
                    </NavLink>
                  </li> */}
                </ul>
              </li>
              

              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle text-dark navhover'
                  href='#'
                  id='navbarDropdown2'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                    {t('predictions')}

                </a>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown2 custom-bg-purple'>
                  <li>
                
                    <NavLink to='/premierleague' className='dropdown-item  custom-bg-purple'>
                      EPL 24/25
                    </NavLink>
                  </li>
                  <li>
                
                <NavLink to='/championshippicks' className='dropdown-item  custom-bg-purple'>
                 Championship 24/25
                </NavLink>
              </li>
                  <li>
                    <NavLink to='/scottishpicks24' className='dropdown-item custom-bg-purple'>
                      SPFL 24/25 
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/laligapicks' className='dropdown-item custom-bg-purple'>
                      La Liga 24/25 
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/uclpicks' className='dropdown-item custom-bg-purple'>
                      Champions League 24/25 
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/bundesligapicks' className='dropdown-item custom-bg-purple'>
                      Bundesliga 24/25 
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/serieapicks' className='dropdown-item custom-bg-purple'>
                      Serie A 24/25 
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/sasoccerpicks' className='dropdown-item custom-bg-purple'>
                      Premier Soccer League A 24/25 
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle text-dark navhover'
                  href='#'
                  id='navbarDropdown2'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                    {t('fixtures')}

                </a>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown2 custom-bg-purple'>
                  
                  <li>
                    <NavLink to='/fixture' className='dropdown-item  custom-bg-purple'>
                      EPL 24/25
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/championshipfixtures' className='dropdown-item  custom-bg-purple'>
                      Championship 24/25
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/scottishfixtures24' className='dropdown-item custom-bg-purple'>
                      SPFL 24/25
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/laligafixtures' className='dropdown-item custom-bg-purple'>
                      La Liga 24/25
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/uclfixture' className='dropdown-item custom-bg-purple'>
                      Champions League 24/25
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/bundesligafixtures' className='dropdown-item custom-bg-purple'>
                      Bundesliga 24/25
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/serieafixtures' className='dropdown-item custom-bg-purple'>
                      Serie A 24/25
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/sasoccerfixtures' className='dropdown-item custom-bg-purple'>
                      Premier Soccer League 24/25
                    </NavLink>
                  </li>
              
                </ul>
              </li>


              <li className='nav-item dropdown'>
      <a
        className='nav-link dropdown-toggle text-dark navhover'
        href='#'
        id='navbarDropdown2'
        role='button'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
          {t('standings')}

      </a>
      <ul className='dropdown-menu' aria-labelledby='navbarDropdown2'>
     
        <li>
          <NavLink to='/standings' className='dropdown-item custom-bg-purple'>
            EPL 24/25
          </NavLink>
        </li>
        {/* Uncomment and add class if needed */}
        <li>
          <NavLink to='/scottishstandingsoldapi' className='dropdown-item custom-bg-purple'>
          SPFL 24/25
          </NavLink>
        </li>
        <li>
          <NavLink to='/championshipstandings' className='dropdown-item custom-bg-purple'>
          Championship 24/25
          </NavLink>
        </li>
        <li>
          <NavLink to='/laligastandings' className='dropdown-item custom-bg-purple'>
          La Liga 24/25
          </NavLink>
        </li>
        <li>
          <NavLink to='/uclstandings' className='dropdown-item custom-bg-purple'>
          Champions League 24/25
          </NavLink>
          <li>
          <NavLink to='/bundesligastandings' className='dropdown-item custom-bg-purple'>
          Bundesliga 24/25
          </NavLink>
        </li>
        <li>
          <NavLink to='/serieastandings' className='dropdown-item custom-bg-purple'>
          Serie A 24/25
          </NavLink>
        </li>
        <li>
                    <NavLink to='/sasoccerstandings' className='dropdown-item custom-bg-purple'>
                    Premier Soccer League 24/25
                    </NavLink>
                  </li>
        </li>
        
      </ul>
    </li>
              <li className='nav-item text-dark navhover'>
                <NavLink to='/news' className='nav-link text-dark navhover' href='about'>
                {t('news')}

                </NavLink>
              </li>
              <li className='nav-item text-dark'>
                <NavLink to='/social' className='nav-link text-dark navhover' href='news'>
                {t('social')}

                </NavLink>
              </li>
              {/* <li className='nav-item'>
                <NavLink to='/contact' className='nav-link'>
                  Contact
                </NavLink>
              </li> */}
                  

           

            </ul>
            
         {/* Flags */}
         <div className='navbar-nav ms-auto mb-2 mb-lg-0'>
              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle text-dark'
                  href='#'
                  id='navbarDropdown2'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <Flag code={i18n.language === 'en' ? 'gb' : 'de'} height='20' />
                </a>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown2'>
                  <li>
                    <Link className='dropdown-item custom-bg-purple text-center' onClick={() => changeLanguage('en')}>
                      <Flag code='gb' height='16' />
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <Link className='dropdown-item custom-bg-purple text-center' onClick={() => changeLanguage('de')}>
                      <Flag code='de' height='18' />
                    </Link>
                  </li>
                </ul>
              </li>
            </div>

            <AuthDetails /> 
            
          <ul></ul>
          {/* <form className="d-flex">
    <input
        className="form-control me-2 bg-transparent text-light placeholder-white"
        type="search"
        placeholder="Search"
        aria-label="Search"
        // onChange={handleSearchChange}
    />
    <button className="btn btn-outline-light" type="submit">
        <FaSearch />
    </button>
</form> */}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
