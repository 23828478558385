import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Spinner } from 'react-bootstrap';

function Referees() {
  const [referees, setReferees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReferees = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/referees');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        console.log('API Response:', responseData);
        // Check if data is an array before setting the state
        if (Array.isArray(responseData.data)) {
          setReferees(responseData.data);
        } else {
          throw new Error('Data received is not an array');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching referees:', error);
        setLoading(false);
      }
    };

    fetchReferees();
  }, []);

  return (
    <div className='bg-dark'>
      <div className='App-headerV6'>
        <Navbar />
        <div className='container'>
        <h2 className='text-center text-light'>Scottish & Danish League Referees</h2>
        <hr className="w-50 mx-auto mb-3 mb-xl-9 border-dark-subtle" />
        </div>
        <div className='container'>
          {loading ? (
            <h4 className='text-light text-center'><Spinner /></h4>
          ) : (
            <ul className="list-group text-center">
              {referees.map((referee, index) => (
          <div class="card">
          <ul class="list-group list-group-flush">
            <li key={index} class="list-group-item">{referee.name}</li>
       
          </ul>
        </div>
                
              ))}
            </ul>
            
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Referees;
