import Torino from '../Team-Kits-Images/Torino.png'
import Napoli from '../Team-Kits-Images/Napoli.png'
import Udinese from '../Team-Kits-Images/Udinese.png'
import Juventus from '../Team-Kits-Images/Juventus.png'
import Empoli from '../Team-Kits-Images/Empoli.png'
import Inter from '../Team-Kits-Images/Inter.png'
import Milan from '../Team-Kits-Images/Milan.png'
import Lazio from '../Team-Kits-Images/Lazio.png'
import Roma from '../Team-Kits-Images/Roma.png'
import Verona from '../Team-Kits-Images/Verona.png'
import Fiorentina from '../Team-Kits-Images/Fiorentina.png'
import Atalanta from '../Team-Kits-Images/Atalanta.png'
import Bologna from '../Team-Kits-Images/Bologna.png'
import Parma from '../Team-Kits-Images/Parma.png'
import Como from '../Team-Kits-Images/Com.png'
import Genoa from '../Team-Kits-Images/Genoa.png'
import Lecce from '../Team-Kits-Images/Lecce.png'
import Venezia from '../Team-Kits-Images/Venezia.png'
import Monza from '../Team-Kits-Images/Monza.png'
import Cagliari from '../Team-Kits-Images/Cagliari.png'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
 import { Link } from 'react-router-dom'

 function SerieAFixtures() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); 
  const [buttonsToShow, setButtonsToShow] = useState(0);

  const [dateRanges] = useState([
    { start: '2024-06-11', end: '2024-08-20' },  // Matchweek 1
    { start: '2024-08-20', end: '2024-08-27' },  // Matchweek 2
    { start: '2024-08-27', end: '2024-09-02' },  // Matchweek 3
    { start: '2024-09-02', end: '2024-09-17' },  // Matchweek 4
    { start: '2024-09-17', end: '2024-09-25' },  // Matchweek 5
    { start: '2024-09-25', end: '2024-10-01' },  // Matchweek 6
    { start: '2024-10-01', end: '2024-10-07' },  // Matchweek 7
    { start: '2024-10-07', end: '2024-10-22' },  // Matchweek 8
    { start: '2024-10-22', end: '2024-10-28' },  // Matchweek 9
    { start: '2024-10-28', end: '2024-11-01' },  // Matchweek 10
    { start: '2024-11-01', end: '2024-11-05' },  // Matchweek 11
    { start: '2024-11-05', end: '2024-11-11' },  // Matchweek 12
    { start: '2024-11-11', end: '2024-11-26' },  // Matchweek 13
    { start: '2024-11-26', end: '2024-12-02' },  // Matchweek 14
    { start: '2024-12-02', end: '2024-12-09' },  // Matchweek 15 // 
    { start: '2024-12-09', end: '2024-12-16' },  // Matchweek 16
    { start: '2024-12-16', end: '2024-12-23' },  // Matchweek 17
    { start: '2024-12-23', end: '2024-12-30' },  // Matchweek 18
    { start: '2024-12-30', end: '2025-01-06' },  // Matchweek 19
    { start: '2025-01-06', end: '2025-01-13' },  // Matchweek 20
    { start: '2025-01-13', end: '2025-01-20' },  // Matchweek 21
    { start: '2025-01-20', end: '2025-01-27' },  // Matchweek 22
    { start: '2025-01-27', end: '2025-02-03' },  // Matchweek 23
    { start: '2025-02-03', end: '2025-02-10' },  // Matchweek 24
    { start: '2025-02-10', end: '2025-02-17' },  // Matchweek 25
    { start: '2025-02-17', end: '2025-02-24' },  // Matchweek 26
    { start: '2025-02-24', end: '2025-03-03' },  // Matchweek 27
    { start: '2025-03-03', end: '2025-03-10' },  // Matchweek 28
    { start: '2025-03-10', end: '2025-03-17' },  // Matchweek 29
    { start: '2025-03-17', end: '2025-04-01' },  // Matchweek 30
    { start: '2025-04-01', end: '2025-04-07' },  // Matchweek 31
    { start: '2025-04-07', end: '2025-04-14' },  // Matchweek 32
    { start: '2025-04-14', end: '2025-04-21' },  // Matchweek 33
    { start: '2025-04-21', end: '2025-04-28' },  // Matchweek 34
    { start: '2025-04-28', end: '2025-05-05' },  // Matchweek 35
    { start: '2025-05-05', end: '2025-05-12' },  // Matchweek 36
    { start: '2025-05-12', end: '2025-05-19' },  // Matchweek 37
    { start: '2025-05-19', end: '2025-07-20' },  // Matchweek 38
        // Add more matchweeks as needed
]);

useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);

  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/135/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(Round);
    handlePageChange(index + 1);
  };
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamLogos = {
    'Torino': Torino,
    'Napoli': Napoli,
    'Udinese': Udinese,
    'Juventus': Juventus,
    'Empoli': Empoli,
    'Inter': Inter,
    'AC Milan': Milan,
    'Lazio': Lazio,
    'AS Roma': Roma,
    'Verona': Verona,
    'Fiorentina': Fiorentina,
    'Atalanta': Atalanta,
    'Bologna': Bologna,
    'Parma': Parma,
    'Como': Como,
    'Genoa': Genoa,
    'Lecce': Lecce,
    'Venezia': Venezia,
    'Monza': Monza,
    'Cagliari': Cagliari
  };
  
  const teamInitials = {
    'Torino': 'TOR',
    'Napoli': 'NAP',
    'Udinese': 'UDI',
    'Juventus': 'JUV',
    'Empoli': 'EMP',
    'Inter': 'INT',
    'AC Milan': 'ACM',
    'Lazio': 'LAZ',
    'AS Roma': 'ROM',
    'Verona': 'VER',
    'Fiorentina': 'FIO',
    'Atalanta': 'ATA',
    'Bologna': 'BOL',
    'Parma': 'PAR',
    'Como': 'COM',
    'Genoa': 'GEN',
    'Lecce': 'LEC',
    'Venezia': 'VEN',
    'Monza': 'MON',
    'Cagliari': 'CAG'
  };
  const renderTeamLogo = (teamName) => {
    const logo = teamLogos[teamName];
    if (logo) {
      return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
    }
    return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
  };
  
  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  const stadiums = {
    'Torino': 'Stadium: Stadio Olimpico Grande Torino',
    'Napoli': 'Stadium: Stadio Diego Armando Maradona',
    'Udinese': 'Stadium: Dacia Arena',
    'Juventus': 'Stadium: Allianz Stadium',
    'Empoli': 'Stadium: Stadio Carlo Castellani',
    'Inter': 'Stadium: San Siro',
    'AC Milan': 'Stadium: San Siro',
    'Lazio': 'Stadium: Stadio Olimpico',
    'AS Roma': 'Stadium: Stadio Olimpico',
    'Verona': 'Stadium: Stadio Marcantonio Bentegodi',
    'Fiorentina': 'Stadium: Stadio Artemio Franchi',
    'Atalanta': 'Stadium: Gewiss Stadium',
    'Bologna': 'Stadium: Stadio Renato Dall\'Ara',
    'Parma': 'Stadium: Stadio Ennio Tardini',
    'Como': 'Stadium: Stadio Giuseppe Sinigaglia',
    'Genoa': 'Stadium: Stadio Luigi Ferraris',
    'Lecce': 'Stadium: Stadio Via del Mare',
    'Venezia': 'Stadium: Stadio Pierluigi Penzo',
    'Monza': 'Stadium: U-Power Stadium',
    'Cagliari': 'Stadium: Unipol Domus',
  };
  
  const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

  const roundsContainerRef = useRef(null);

  // Handle Previous Button Click
  // Handle Direct Matchday Selection
  const handleMatchdaySelectNew = (index) => {
    setSelectedMatchdayIndex(index);
  };
  
  useEffect(() => {
    const calculateButtonsToShow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const calculatedButtonWidth = 100; // Adjust width as needed
        const calculatedButtonsToShow = Math.floor(containerWidth / (calculatedButtonWidth + 8));
        setButtonWidth(calculatedButtonWidth);
        setButtonsToShow(calculatedButtonsToShow);
        
        // Dynamically set startIndex around the current round
        calculateStartIndex(currentPage, calculatedButtonsToShow); 
      }
    };
  
    calculateButtonsToShow();
  
    // Recalculate on window resize
    window.addEventListener('resize', calculateButtonsToShow);
    return () => {
      window.removeEventListener('resize', calculateButtonsToShow);
    };
  }, [currentPage, containerRef.current]);
  
  // Adjust calculateStartIndex to center the rounds based on current page
  const calculateStartIndex = (currentPage, buttonsToShow) => {
    let minIndex;
  
    // Center start index around current round within available buttonsToShow
    if (currentPage - Math.floor(buttonsToShow / 2) >= 0) {
      minIndex = currentPage - Math.floor(buttonsToShow / 2);
    } else {
      minIndex = 0; // fallback for early rounds
    }
    
    // Ensure we don’t exceed the available rounds
    minIndex = Math.min(minIndex, dateRanges.length - buttonsToShow);
  
    setStartIndex(minIndex);
  };
  


  const handleLeftArrowClick = () => {
    if (roundsContainerRef.current && startIndex > 0) {
      roundsContainerRef.current.scrollBy({
        left: -buttonWidth, // Scroll by one button width
        behavior: 'smooth', // Make the scroll smooth
      });
      setStartIndex(startIndex - 1); // Decrement startIndex by 1
    }
  };
  
  // Function to handle right arrow click (move by 1 round)
  const handleRightArrowClick = () => {
    if (roundsContainerRef.current && startIndex + 1 < dateRanges.length) {
      roundsContainerRef.current.scrollBy({
        left: buttonWidth, // Scroll by one button width
        behavior: 'smooth', // Make the scroll smooth
      });
      setStartIndex(startIndex + 1); // Increment startIndex by 
    }
  };
  
  return (
    <div>
    <div className='App-headerV'>
      <h2 className='text-dark text-center padtop'style={{ fontFamily: 'Lato, sans-serif' }}>Serie A Fixtures</h2>
      <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
        <div className="dropdown d-inline-block">
          <button
            className="btn btn-purple dropdown-toggle"
            type="button"
            id="groupStageDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedMatchday}
          </button>
          <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
            <ul className="list-group">
              {dateRanges.map((range, index) => (
                <li key={index} className="list-group-item">
                  <button className="dropdown-item" onClick={() => handleMatchdaySelect(index)}>
                    Round {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
   
      <div className='container'>
        <div className='row'>        
          {/* Main content area */}
          <div className=' col-12'>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
              let points = 0;
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-0'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
      <h6 className='card-title mb-3'>{stadiums[fixture.teams.home.name]}</h6>
    )}
              
                  <div className="row align-items-center">
                    <div className="col-2 text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                                  {/* Input */}
                    <>
<div className="col-4 padtop1 ">
<input
  className="form-control text-center fs-3 "
  style={{ backgroundColor: '#fff', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
  disabled
/>
</div>
<div className="col-4 padtop1">
<input
  className="form-control text-center fs-3"
  style={{ backgroundColor: '#fff', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
  disabled
/>
</div>
</>
              {/* Input */}
                    <div className="col-2 text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
              
                </div>
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
 style={{ 
   backgroundColor: '#9370DB', 
   borderRadius: '5px', 
   borderBottomLeftRadius: '5px',  // Set bottom-left corner radius to 0
   borderBottomRightRadius: '5px'  // Set bottom-right corner radius to 0
 }}
>
<p className="text-center mb-2 btn-sm text-bold" 
 style={{ 
   color: '#fff', 
   paddingTop: '4px',  
   borderTopLeftRadius: '1px' 
 }}
>
Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}
</p>
</div>
<hr className="my-3" style={{ height: '4px', backgroundColor: '#9370DB', border: 'none' }} />

              </div>
              );
            })}
          </div>         
        </div>
      </div>
    </div>
  </div>
  );
}

export default SerieAFixtures;
