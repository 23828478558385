import React, { useState, useEffect } from 'react';
import { ref, get, set, getDatabase, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';


function Testing() {
  const [participantsCount, setParticipantsCount] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [participants, setParticipants] = useState({});
  const [ user, setUser ] = useState('')
  const [error, setError ] = useState(false)
  const [userUid, setUserUid] = useState('');

  const auth = getAuth();
const navigate = useNavigate('')

useEffect(() => {
  const fetchTestDetails = async () => {
      try {
          const database = getDatabase();
          const testRef = ref(database, 'test');
          const testSnapshot = await get(testRef);
          if (testSnapshot.exists()) {
              const data = testSnapshot.val();
              setParticipantsCount(data.participantsCount || 0);
              const usersObject = data.participants || {}; // Initialize as an object
              setParticipants(usersObject);                    
              const user = auth.currentUser;
              if (user && usersObject[user.uid]) {
                  setButtonClicked(true);
              }
          }
      } catch (error) {
          console.error('Error fetching test details:', error);
      }
  };
  

  fetchTestDetails();
}, []);

useEffect(() => {
  const user = auth.currentUser;
  if (user && participants[user.uid]) {
      // If the user has already clicked, disable the button
      setButtonClicked(true);
  }
}, [auth, participants]);


const handleButtonClick = async () => {
  const user = auth.currentUser;
  if (user && !buttonClicked) {
      try {
          const database = getDatabase();
          const testRef = ref(database, 'test');
          const newUserId = user.uid;
          const displayName = user.displayName; // Get user's display name

          // Check if the user has already clicked, if yes, don't update
          if (participants[newUserId]) {
              console.log('User already clicked');
              return;
          }

          // Create a copy of the clickedUsers object and update the new user's information
          const updatedParticipants = { ...participants };
          updatedParticipants[newUserId] = { displayName: displayName };

          // Update only the new user's information in the database
          await update(testRef, { [`participants/${newUserId}`]: { displayName: displayName } });

          // Update the participants count in the database
          const newParticipantsCount = participantsCount + 1;
          await update(testRef, { participantsCount: newParticipantsCount });

          // Update state and set button clicked
          setParticipantsCount(newParticipantsCount);
          setButtonClicked(true);
      } catch (error) {
          console.error('Error updating participants count:', error);
      }
  }
};
useEffect(() => {
  const user = auth.currentUser;
  if (user && participants[user.uid]) {
      // If the user has already clicked, disable the button
      setButtonClicked(true);
  }
}, [auth, participants]);

  return (
    <div>
    <div className='App-headerV3'>
        <Navbar />
        <form>
                    <label>
                        <input type="number" value={participantsCount} readOnly />
                    </label>
                </form>
                <button onClick={handleButtonClick} disabled={buttonClicked}>
                    {buttonClicked ? 'Clicked' : 'Click'}
                </button>
    </div>
        <Footer />
</div>
)
}

export default Testing