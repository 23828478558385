import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './firebase'; // Adjust the import to your actual useAuth implementation
import { Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const { auth } = useAuth(); // Use auth from your useAuth function
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Check if the user is authenticated and their email is verified
        setIsAuthenticated(user.emailVerified);
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [auth]);

  if (loading) return null; // Render nothing while loading

  // Pass the current location to the login page if not authenticated
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoute;
