
import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { onValue, off } from 'firebase/database';
import Navbar from '../Navbar';
import axios from 'axios';
import Footer from '../Footer';



function PointsSystem() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [ sent, setSent ] = useState(false)
    const [ error, setError ] = useState(false)
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const [points, setPoints] = useState(0); // Step 1: Initialize points state

    useEffect(() => {
        const fetchFixturesAndPicks = async () => {
            try {
                // Fetch fixtures data from the API
                const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2023", {
                    headers: {
                        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                    }
                });
                const fixturesData = await fixturesResponse.json();
                console.log("Fetched fixtures data:", fixturesData);
    
                // Fetch user's picks
                if (userId) {
                    const picksRef = ref(getDatabase(), `picks/${userId}`);
                    const snapshot = await get(picksRef);
                    const picksData = snapshot.val();
    
                    if (picksData) {
                        setInputsData(picksData);
                    }
                }
    
                // Find the current round
                const now = new Date();
                const currentRound = rounds.find(round => now >= round.startDate && now <= round.endDate);
                console.log("Current Round:", currentRound); // Log the current round to verify
    
                // Filter fixtures for the current round
                const fixturesForCurrentRound = fixturesData.filter(fixture => {
                    const fixtureDate = new Date(fixture.fixture.date);
                    return fixtureDate >= currentRound.startDate && fixtureDate <= currentRound.endDate;
                });
    
                // Sort the fixtures for the current round by date
                fixturesForCurrentRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
                // Set the fixtures state with the fixtures for the current round
                setFixtures(fixturesForCurrentRound);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { homeTeam, awayTeam, points: 0 } // Initialize points to 0 for each fixture
        }));
    };
    

    const onSubmit = () => {
        const picksRef = ref(getDatabase(), `picks/${userId}`);
        const updatedPicksData = { ...inputsData, }; // Include points in the picks data
        set(picksRef, updatedPicksData)
            .then(() => {
                console.log('Picks submitted successfully');
                setPoints(prevPoints => prevPoints + 1); // Increment points by 1
            })
            .catch(error => console.error('Error submitting picks:', error));
        setSent(true);
        setTimeout(() => {
            setSent(false);
            return;
        }, 5000);
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2023-08-11'), endDate: new Date('2023-08-17') },
        { number: 2, startDate: new Date('2023-08-18'), endDate: new Date('2023-08-24') },
        { number: 3, startDate: new Date('2023-08-25'), endDate: new Date('2023-08-31') },
        { number: 4, startDate: new Date('2023-09-01'), endDate: new Date('2023-09-08') },
        { number: 5, startDate: new Date('2023-09-09'), endDate: new Date('2023-09-19') },
        { number: 6, startDate: new Date('2023-09-22'), endDate: new Date('2023-09-25') },
        { number: 7, startDate: new Date('2023-09-29'), endDate: new Date('2023-10-02') },
        { number: 8, startDate: new Date('2023-10-03'), endDate: new Date('2023-10-09') },
        { number: 9, startDate: new Date('2023-10-10'), endDate: new Date('2023-10-24') },
        { number: 10, startDate: new Date('2023-10-25'), endDate: new Date('2023-10-30') },
        { number: 11, startDate: new Date('2023-11-01'), endDate: new Date('2023-11-07') }, 
        { number: 12, startDate: new Date('2023-11-08'), endDate: new Date('2023-11-13') },
        { number: 13, startDate: new Date('2023-11-14'), endDate: new Date('2023-11-28') },
        { number: 14, startDate: new Date('2023-11-29'), endDate: new Date('2023-12-04') },
        { number: 15, startDate: new Date('2023-12-05'), endDate: new Date('2023-12-08') },
        { number: 16, startDate: new Date('2023-12-09'), endDate: new Date('2023-12-11') },
        { number: 17, startDate: new Date('2023-12-12'), endDate: new Date('2023-12-18') },
        { number: 18, startDate: new Date('2023-12-19'), endDate: new Date('2023-12-25') },
        { number: 19, startDate: new Date('2023-12-26'), endDate: new Date('2023-12-29') },
        { number: 20, startDate: new Date('2023-12-20'), endDate: new Date('2024-01-03') }, // 2023
        { number: 21, startDate: new Date('2024-01-04'), endDate: new Date('2024-01-23') },
        { number: 22, startDate: new Date('2024-01-24'), endDate: new Date('2024-02-02') },
        { number: 23, startDate: new Date('2024-02-03'), endDate: new Date('2024-02-06') },
        { number: 24, startDate: new Date('2024-02-07'), endDate: new Date('2024-02-13') },
        { number: 25, startDate: new Date('2024-02-14'), endDate: new Date('2024-02-21') },
        { number: 26, startDate: new Date('2024-02-21'), endDate: new Date('2024-02-27') },
        { number: 27, startDate: new Date('2024-02-27'), endDate: new Date('2024-03-05') },
        { number: 28, startDate: new Date('2024-03-05'), endDate: new Date('2024-03-12') },
        { number: 29, startDate: new Date('2024-03-12'), endDate: new Date('2024-03-18') },
        { number: 30, startDate: new Date('2024-03-18'), endDate: new Date('2024-04-01') },
        { number: 31, startDate: new Date('2024-04-02'), endDate: new Date('2024-04-05') },
        { number: 32, startDate: new Date('2024-04-05'), endDate: new Date('2024-04-07') },
        { number: 33, startDate: new Date('2024-04-07'), endDate: new Date('2024-04-14') },
        { number: 34, startDate: new Date('2024-04-14'), endDate: new Date('2024-04-21') },
        { number: 35, startDate: new Date('2024-04-21'), endDate: new Date('2024-04-28') },
        { number: 36, startDate: new Date('2024-04-28'), endDate: new Date('2024-05-05') },
        { number: 37, startDate: new Date('2024-05-05'), endDate: new Date('2024-05-12') },
        { number: 38, startDate: new Date('2024-05-12'), endDate: new Date('2024-05-20') },
    ];

    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2023?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    

    useEffect(() => {
        const picksRef = ref(getDatabase(), `picks/${userId}`);
        const picksListener = onValue(picksRef, (snapshot) => {
            const picksData = snapshot.val();
            if (picksData) {
                // Iterate through each fixture in picksData
                Object.keys(picksData).forEach(fixtureId => {
                    const fixture = picksData[fixtureId];
                    if (fixture.status === 'FT') {
                        // Update points for the fixture
                        setInputsData(prevInputsData => ({
                            ...prevInputsData,
                            [fixtureId]: {
                                ...prevInputsData[fixtureId],
                                points: prevInputsData[fixtureId].points + 1 // Increment points by 1
                            }
                        }));
                    }
                });
            }
        });

        return () => off(picksRef, 'value', picksListener); // Cleanup listener
    }, [userId]);

    useEffect(() => {
        const interval = setInterval(() => {
            // Increment points by 1 every 5 minutes
            setPoints(prevPoints => prevPoints + 1);
        }, 5 * 60 * 1000); // 5 minutes in milliseconds
    
        return () => clearInterval(interval); // Cleanup interval
    }, []);
    

    
    return (
        <div className='bg-dark'>
            <Navbar />
            <div className='App-headerV3'>
            <h2 className="display-5 mb-5 text-center" style={{ color: 'white' }}>Your Picks</h2>
      <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown text-center padbot">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className="container bg-dark">
                    <div className=''>
                       
                            <div className='bg-dark'>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3' style={{ backgroundColor: '#e6eeff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row">
                                        <div className="col text-center"> 
                                        {/* Display kit of home team */}
                       
                                        {/* Display kit of home team */}
                                        </div>


                                        <>
    <div className="col ">
        {fixture.fixture.status.elapsed > 0 && !inputsData[fixture.fixture.id] ? ( // Check if the game has started and user has not made a pick
            <input
                className="form-control text-center"
                value={"No pick"}
                readOnly // Make the input read-only
            />
        ) : (
            <input
                className="form-control text-center"
                placeholder={`Home`}
                onChange={(e) => handleInputChange(fixture.fixture.id, e.target.value, inputsData[fixture.fixture.id]?.awayTeam || '')}
                value={inputsData[fixture.fixture.id]?.homeTeam || ''}
                disabled={fixture.fixture.status.elapsed > 0} // Disable the input field if the game has started
            />
        )}
    </div>
    <div className="col ">
        {fixture.fixture.status.elapsed > 0 && !inputsData[fixture.fixture.id] ? ( // Check if the game has started and user has not made a pick
            <input
                className="form-control text-center"
                value={"No pick"}
                readOnly // Make the input read-only
            />
        ) : (
            <input
                className="form-control text-center"
                placeholder={`Away`}
                onChange={(e) => handleInputChange(fixture.fixture.id, inputsData[fixture.fixture.id]?.homeTeam || '', e.target.value)}
                value={inputsData[fixture.fixture.id]?.awayTeam || ''}
                disabled={fixture.fixture.status.elapsed > 0} // Disable the input field if the game has started
            />
        )}
    </div>
</>



                                            <div className="col text-center"> 
                                        {/* Display kit of away team */} 
                          
                                        {/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                    {' '}
                                                        {
                                                                                                                         
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.home.name}
                                                                                                                                </span>
                                                                                                                            }    
                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                    {' '}
                                                        {
                                                                                                                         
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.away.name}
                                                                                                                                </span>
                                                                                                                            }    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                        <p className='text-center padbot'>Results: {fixture.score.fulltime.home} - {fixture.score.fulltime.away}</p>
                                    </div>
                                ))}
                            </div>
              
                    </div>
                </div>
            </div>
            <div>
                <div className='text-center bg-dark'>
                    <div className='bg-dark'></div>
                    <div className='btn btn-info' onClick={onSubmit}>Submit</div>
                </div>
                <div className='col-md-8 mx-auto padtop'>

                {sent && (
                  <div className="alert alert-success text-center " role="alert">
                    Picks Submitted successfully!
                  </div>
                )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PointsSystem;