import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getDatabase, ref, get, update, remove } from 'firebase/database';
import { Bar } from 'react-chartjs-2'; // Import Bar from Chart.js
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'; // Register necessary Chart.js components

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Users() {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [countryCounts, setCountryCounts] = useState({}); // State for country counts
  const [mostPopularSport, setMostPopularSport] = useState({ sport: '', count: 0 });
  const [mostPopularCountry, setMostPopularCountry] = useState({ country: '', count: 0 }); 

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const allowedEmails = ['denis@kingjtech.com', 'denis@atomtech.live', 'support@predictify.app', 'jeremy@fall.com.mt'];
        if (allowedEmails.includes(user.email)) {
          setIsAuthorized(true);
          fetchUsers();
        } else {
          navigate('/');
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchUsers = async () => {
    try {
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        // Filter users who have a non-empty, non-null username
        const usersArray = Object.keys(usersData)
          .map((id) => ({
            id,
            ...usersData[id],
          }))
          .filter((user) => user.username && user.username.trim() !== ''); // Only include users with valid usernames
  
        setUsers(usersArray);
        setDisplayedUsers(usersArray.slice(0, 10));
        countNewUsers(usersArray);
        countUsersByCountry(usersArray);
        determineMostPopularSport(usersArray); // Call here
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  
  
  const determineMostPopularSport = (usersArray) => {
    const sportCount = {};
  
    // Count occurrences of each sport
    usersArray.forEach(user => {
      const sport = user.selectedSport || 'Unknown';
      sportCount[sport] = (sportCount[sport] || 0) + 1;
    });
  
    // Convert to array and sort by count, highest first
    const sortedSports = Object.entries(sportCount).sort((a, b) => b[1] - a[1]);
  
    let mostPopularSport = { sport: 'Unknown', count: 0 };
  
    // Select the most popular sport, unless it's "Unknown"
    if (sortedSports.length > 0) {
      const [topSport, topCount] = sortedSports[0];
      if (topSport !== 'Unknown' && topSport.trim() !== '') {
        mostPopularSport = { sport: topSport, count: topCount };
      } else if (sortedSports.length > 1) {
        // If "Unknown" is the top sport, select the second most popular
        const [secondSport, secondCount] = sortedSports[1];
        mostPopularSport = { sport: secondSport, count: secondCount };
      }
    }
  
    setMostPopularSport(mostPopularSport);
  };
  
  const countNewUsers = (usersArray) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const newUsers = usersArray.filter((user) => {
      const registrationDate = new Date(user.timestamp);
      return (
        registrationDate.getMonth() === currentMonth &&
        registrationDate.getFullYear() === currentYear
      );
    });
    setNewUsersCount(newUsers.length);
  };

  const countUsersByCountry = (usersArray) => {
    const countryCount = {};
    usersArray.forEach(user => {
      const country = user.country || 'Unknown';
      countryCount[country] = (countryCount[country] || 0) + 1;
    });
    setCountryCounts(countryCount);

    // Determine the country with the most users
    const mostPopularCountry = Object.entries(countryCount).reduce((max, [country, count]) => {
      return count > max.count ? { country, count } : max;
    }, { country: 'Unknown', count: 0 });

    setMostPopularCountry(mostPopularCountry); // Update the state
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const getMonthRange = () => {
    const currentDate = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthName = monthNames[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    return `1st to the ${new Date(year, currentDate.getMonth() + 1, 0).getDate()} of ${monthName}`;
  };

  const loadMoreUsers = () => {
    setDisplayedUsers(users);
    setShowAllUsers(true);
  };

  const toggleRowExpansion = (userId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(userId)
        ? prevExpandedRows.filter(id => id !== userId)
        : [...prevExpandedRows, userId]
    );
  };

  const sanitizeEmail = (email) => email.replace(/\./g, '_');

  const blockUser = async (userId, unblock = false) => {
    try {
      const db = getDatabase();
      const userRef = ref(db, `users/${userId}`);
      const userSnapshot = await get(userRef);
  
      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        const sanitizedEmail = sanitizeEmail(userData.email); // Sanitize email
  
        const blockedEmailsRef = ref(db, `blockedEmails/${sanitizedEmail}`);
  
        if (unblock) {
          // Remove the user's email from the blocked list
          console.log(`Unblocking user with email: ${userData.email}`);
          await remove(blockedEmailsRef);
  
          // Update user data to remove block status
          await update(userRef, { blockedUntil: null });
        } else {
          // Add the user's email to the blocked list
          console.log(`Blocking user with email: ${userData.email}`);
          await update(blockedEmailsRef, { blocked: true });
  
          // Update user data to set block status
          const blockedUntil = Date.now() + 24 * 60 * 60 * 1000; // 24 hours
          await update(userRef, { blockedUntil });
        }
  
        // Fetch updated user list
        await fetchUsers();
      } else {
        console.log("User does not exist");
      }
    } catch (error) {
      console.error("Error blocking/unblocking user:", error);
    }
  };
  
  const getTimeRemaining = (blockedUntil) => {
    const currentTime = Date.now();
    const timeLeft = blockedUntil - currentTime;
    if (timeLeft <= 0) return "0h 0m";
    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  const chartData = {
    labels: Object.keys(countryCounts), // X-axis labels: countries
    datasets: [
      {
        label: 'Users by Country',
        data: Object.values(countryCounts), // Y-axis data: user counts
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Bar color
        borderColor: 'rgba(54, 162, 235, 1)', // Border color
        borderWidth: 1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'User Distribution by Country'
      }
    }
  };

// Function to format phone number by separating country code
const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return 'N/A';
  
    // Convert phoneNumber to a string in case it's not already
    const phoneStr = String(phoneNumber);
  
    // Remove non-numeric characters
    const cleaned = phoneStr.replace(/\D/g, '');
  
    // Extract country code and the rest of the number
    const countryCodeMatch = cleaned.match(/^(\d{1,3})(\d{7,10})$/);
  
    if (countryCodeMatch) {
      const countryCode = countryCodeMatch[1]; // Extracts country code
      const restOfNumber = countryCodeMatch[2]; // Extracts the rest of the number
  
      // Format the number as "+(country code) restOfNumber"
      return `+(${countryCode}) ${restOfNumber}`;
    }
  
    return phoneStr; // Return as is if it doesn't match the pattern
  };
  
  if (!isAuthorized) {
    return null;
  }

  return (
    <div>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1 p-3">
          <div className=''>
            <div className="container mt-4">
              <h2 className='text-center mb-3'>User List</h2>
              <hr />
              {/* User Statistics */}
              <div className="container mb-3">
                <div className="row">
                  <div className="col-12">
                    <div className="container-fluid bg-light border shadow">
                      <div className="row">
                        <div className="col-12 col-md-6 p-0">
                          <div className="card border-0 bg-transparent">
                            <div className="card-body text-center p-4 p-xxl-5">
                              <h3 className="display-4 fw-bold mb-2">{newUsersCount}</h3>
                              <p className="fs-5 mb-0 text-secondary">
                                <strong>New Users This Month ({getMonthRange()})</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 p-0 border-top border-bottom border-start border-end">
                          <div className="card border-0 bg-transparent">
                            <div className="card-body text-center p-4 p-xxl-5">
                              <h3 className="display-4 fw-bold mb-2">{users.length}</h3>
                              <p className="fs-5 mb-0 text-secondary">Total Users</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Bar Chart for Country Distribution */}
              {/* <div className="container mb-4">
                <h3 className="text-center mb-3">User Distribution by Country</h3>
                <Bar data={chartData} options={chartOptions} />
              </div> */}
              <p>Country with Most Users: <strong>{mostPopularCountry.country} ({mostPopularCountry.count} users)</strong></p> {/* New info */}
              <p>Most Popular Sport: <strong>{mostPopularSport.sport} ({mostPopularSport.count} users)</strong></p>

              {/* User List */}
              {displayedUsers.map((user, index) => (
                <div key={user.id}>
                  <table className="table table-bordered">
                    <thead>
                      <tr onClick={() => toggleRowExpansion(user.id)} style={{ cursor: 'pointer' }}>
                        <th scope="col">#</th>
                        <th scope="col">Username</th>
                        <th scope="col">Email</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Country</th>
                        <th scope="col">Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" style={{ width: '5%' }}>{index + 1}</th>
                        <td style={{ width: '20%' }}>{user.username}</td>
                        <td style={{ width: '20%' }}>{user.email}</td>
                        <td style={{ width: '10%' }}>{user.firstname}</td>
                        <td style={{ width: '10%' }}>{user.lastname}</td>
                        <td style={{ width: '10%' }}>{user.country}</td>
                        <td style={{ width: '10%' }}>{formatDate(user.timestamp)}</td>
                      </tr>
                    </tbody>
                    <tbody>
                      {expandedRows.includes(user.id) && (
                        <tr>
                          <td colSpan="8">
                            <div><strong>Username:</strong> {user.username}</div>
                            <div><strong>Email:</strong> {user.email}</div>
                            <div><strong>First Name:</strong> {user.firstname}</div>
                            <div><strong>Last Name:</strong> {user.lastname}</div>
                            <div><strong>Country:</strong> {user.country}</div>
                            <div><strong>Created:</strong> {formatDate(user.timestamp)}</div>
                            <div><strong>Gender:</strong> {user.gender || 'N/A'}</div>
                            <div><strong>Phone Number:</strong> {formatPhoneNumber(user.phoneNumber)}</div>
                            <div><strong>Favourite Sport:</strong> {user.selectedSport || 'N/A'}</div>
                            <div><strong>Favourite Team:</strong> {user.selectedTeam || 'N/A'}</div>
                            <div><strong>Promo email:</strong> {user.promoemail !== undefined ? user.promoemail.toString() : 'false'}</div>
                            <div><strong>UID:</strong> {user.uid || 'N/A'}</div>

                            {user.blockedUntil && user.blockedUntil > Date.now() ? (
  <>
    <div><strong>Blocked Until:</strong> {new Date(user.blockedUntil).toLocaleString()}</div>
    <div><strong>Time Remaining:</strong> {getTimeRemaining(user.blockedUntil)}</div>
    <button 
      className="btn btn-danger btn-sm" 
      onClick={() => blockUser(user.id, true)}
    >
      Unblock
    </button>
  </>
) : (
  <div><strong>Block User:</strong> 
    <button 
      className="btn btn-warning btn-sm" 
      onClick={() => blockUser(user.id)}
    >
      Block for 24h
    </button>
  </div>
)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ))}
              {!showAllUsers && users.length > 10 && (
                <div className="text-center mt-4">
                  <button className="btn btn-primary" onClick={loadMoreUsers}>
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
