import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import axios from 'axios'


function Test() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [ sent, setSent ] = useState(false)
    const [ error, setError ] = useState(false)
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const [existingPicks, setExistingPicks] = useState({});


    useEffect(() => {
      const fetchFixturesAndPicks = async () => {
        try {
          // Fetch picks for the user
          if (userId) {
            const response = await axios.get(`http://localhost:3001/user-picks/${userId}`);
            const picksData = response.data;
            console.log("Fetched picks data:", picksData);
  
            if (picksData) {
              setInputsData(picksData.reduce((acc, curr) => {
                acc[curr.fixtureID] = { homeTeam: curr.homeTeam, awayTeam: curr.awayTeam };
                return acc;
              }, {}));
            }
          }
  
          // Fetch fixtures data from the API
          // Rest of the code remains the same
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { 
                homeTeam, 
                awayTeam,
                homeTeamPrediction: homeTeam, // Set homeTeamPrediction to the value of homeTeam
                awayTeamPrediction: awayTeam // Set awayTeamPrediction to the value of awayTeam
            }
        }));
    };

    const extractUsernameFromDisplayName = (displayName) => {
        if (!displayName) {
            return '';
        }
        const match = displayName.match(/\(([^)]+)\)/);
        return match ? match[1] : displayName;
    };


    const onSubmit = () => {
        console.log("Submitting picks:", inputsData); // Debugging statement
    
        // Extract the username from the user's displayName
        const displayName = user?.displayName;
        const username = extractUsernameFromDisplayName(displayName);
    
        // Filter out fixtures for which the user hasn't made picks and are not already stored locally (old picks)
        const newPicks = Object.entries(inputsData).reduce((acc, [fixtureID, picks]) => {
            if (picks.homeTeam && picks.awayTeam && (!existingPicks || !existingPicks[fixtureID] || existingPicks[fixtureID].homeTeam !== picks.homeTeam || existingPicks[fixtureID].awayTeam !== picks.awayTeam)) {
                acc[fixtureID] = { ...picks, username }; // Include extracted username along with homeTeam picks
            }
            return acc;
        }, {});
    
        console.log("New picks to submit:", newPicks); // Debugging statement
    
        // Post only the new picks to the backend API endpoint
        axios.post(`http://localhost:3001/user-picks/${userId}`, newPicks)
            .then(response => {
                console.log('New picks submitted successfully');
                setSent(true);
                setError(false); // Reset error state
                setTimeout(() => {
                    setSent(false);
                }, 5000);
    
                // Update existing picks state with the new picks that were successfully submitted
                setExistingPicks(prevExistingPicks => ({
                    ...prevExistingPicks,
                    ...newPicks
                }));
            })
            .catch(error => {
                console.error('Error submitting picks:', error);
                setError(true);
                setSent(false); // Reset sent state
            });
    };
    
    
    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2023-08-11'), endDate: new Date('2023-08-17') },
        { number: 2, startDate: new Date('2023-08-18'), endDate: new Date('2023-08-24') },
        { number: 3, startDate: new Date('2023-08-25'), endDate: new Date('2023-08-31') },
        { number: 4, startDate: new Date('2023-09-01'), endDate: new Date('2023-09-08') },
        { number: 5, startDate: new Date('2023-09-09'), endDate: new Date('2023-09-19') },
        { number: 6, startDate: new Date('2023-09-22'), endDate: new Date('2023-09-25') },
        { number: 7, startDate: new Date('2023-09-29'), endDate: new Date('2023-10-02') },
        { number: 8, startDate: new Date('2023-10-03'), endDate: new Date('2023-10-09') },
        { number: 9, startDate: new Date('2023-10-10'), endDate: new Date('2023-10-24') },
        { number: 10, startDate: new Date('2023-10-25'), endDate: new Date('2023-10-30') },
        { number: 11, startDate: new Date('2023-11-01'), endDate: new Date('2023-11-07') }, 
        { number: 12, startDate: new Date('2023-11-08'), endDate: new Date('2023-11-13') },
        { number: 13, startDate: new Date('2023-11-14'), endDate: new Date('2023-11-28') },
        { number: 14, startDate: new Date('2023-11-29'), endDate: new Date('2023-12-04') },
        { number: 15, startDate: new Date('2023-12-05'), endDate: new Date('2023-12-08') },
        { number: 16, startDate: new Date('2023-12-09'), endDate: new Date('2023-12-11') },
        { number: 17, startDate: new Date('2023-12-12'), endDate: new Date('2023-12-18') },
        { number: 18, startDate: new Date('2023-12-19'), endDate: new Date('2023-12-25') },
        { number: 19, startDate: new Date('2023-12-26'), endDate: new Date('2023-12-29') },
        { number: 20, startDate: new Date('2023-12-20'), endDate: new Date('2024-01-03') }, // 2023
        { number: 21, startDate: new Date('2024-01-04'), endDate: new Date('2024-01-23') },
        { number: 22, startDate: new Date('2024-01-24'), endDate: new Date('2024-02-02') },
        { number: 23, startDate: new Date('2024-02-03'), endDate: new Date('2024-02-06') },
        { number: 24, startDate: new Date('2024-02-07'), endDate: new Date('2024-02-13') },
        { number: 25, startDate: new Date('2024-02-14'), endDate: new Date('2024-02-21') },
        { number: 26, startDate: new Date('2024-02-21'), endDate: new Date('2024-02-27') },
        { number: 27, startDate: new Date('2024-02-27'), endDate: new Date('2024-03-05') },
        { number: 28, startDate: new Date('2024-03-05'), endDate: new Date('2024-03-12') },
        { number: 29, startDate: new Date('2024-03-12'), endDate: new Date('2024-03-18') },
        { number: 30, startDate: new Date('2024-03-18'), endDate: new Date('2024-04-01') },
        { number: 31, startDate: new Date('2024-04-02'), endDate: new Date('2024-04-05') },
        { number: 32, startDate: new Date('2024-04-05'), endDate: new Date('2024-04-07') },
        { number: 33, startDate: new Date('2024-04-07'), endDate: new Date('2024-04-14') },
        { number: 34, startDate: new Date('2024-04-14'), endDate: new Date('2024-04-21') },
        { number: 35, startDate: new Date('2024-04-21'), endDate: new Date('2024-04-28') },
        { number: 36, startDate: new Date('2024-04-28'), endDate: new Date('2024-05-05') },
        { number: 37, startDate: new Date('2024-05-05'), endDate: new Date('2024-05-12') },
        { number: 38, startDate: new Date('2024-05-12'), endDate: new Date('2024-05-20') },
    ];

    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2023?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
    
            // Set inputsData state with team names from the API
            const initialInputsData = {};
            fixturesForRound.forEach(fixture => {
                initialInputsData[fixture.fixture.id] = { homeTeam: fixture.teams.home.name, awayTeam: fixture.teams.away.name };
            });
            setInputsData(initialInputsData);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    

    
    
    return (
        <div className='Nav-headerV8'>
            <Navbar />
            <div className='App-headerV3'>
            <div className="fixed-section">
            <h2 className="display-5 mb-5 text-center" style={{ color: 'white' }}>Your Picks</h2>
            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
        </div>

            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown-center text-center padbot">
                    <button className="btn btn-info dropdown-toggle btn-lg" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu text-center custom" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className="container Nav-headerV8">
                    <div className=''>
                       
                            <div className='Nav-headerV8'>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3' style={{ backgroundColor: '#e6eeff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row">
                                        <div className="col text-center"> 
                                      {/* Display kit of home team */}
                                    
                                        {/* Display kit of home team */}
                                        </div>


                                        <>
    <div className="col">
        {(fixture.fixture.status.elapsed > 0 && !inputsData[fixture.fixture.id]?.homeTeam) ? (
            <input
                className="form-control text-center"
                value={"No pick"}
                readOnly // Make the input read-only
            />
        ) : (
            <input
                className="form-control text-center"
                placeholder={`Home`}
                onChange={(e) => handleInputChange(fixture.fixture.id, e.target.value, inputsData[fixture.fixture.id]?.awayTeam || '')}
                value={inputsData[fixture.fixture.id]?.homeTeam || ''}
                disabled={fixture.fixture.status.elapsed > 0} // Disable the input field if the game has started
            />
        )}
    </div>
    <div className="col">
        {(fixture.fixture.status.elapsed > 0 && !inputsData[fixture.fixture.id]?.awayTeam) ? (
            <input
                className="form-control text-center"
                value={"No pick"}
                readOnly // Make the input read-only
            />
        ) : (
            <input
                className="form-control text-center"
                placeholder={`Away`}
                onChange={(e) => handleInputChange(fixture.fixture.id, inputsData[fixture.fixture.id]?.homeTeam || '', e.target.value)}
                value={inputsData[fixture.fixture.id]?.awayTeam || ''}
                disabled={fixture.fixture.status.elapsed > 0} // Disable the input field if the game has started
            />
        )}
    </div>
</>








                                            <div className="col text-center"> 
                                       {/* Display kit of away team */} 
                                     
                                        {/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                  
                                                                                                                    {/* Display home team Initials */}

                                                                                                                    <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.home.name}
                                                                                                                                </span>
                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display home team Initials */}

                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                                                                                                         {/* Display away team Initials */}

                                                                                                                                         <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.away.name}
                                                                                                                                </span>
                                                                                                                                   {/* Display away team Initials */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                        <p className='text-center padbot'>Results: {fixture.score.fulltime.home} - {fixture.score.fulltime.away}</p>
                                        <p className='text-center padbot'>League ID {fixture.league.id}</p>

                                    </div>
                                ))}
                            </div>
              
                    </div>
                </div>
            </div>
            <div>
                <div className='text-center Nav-headerV8'>
                    <div className='Nav-headerV8'></div>
                    <div className='btn btn-info' onClick={onSubmit}>Submit</div>
                </div>
                <div className='col-md-8 mx-auto padtop'>

                {sent && (
                  <div className="alert alert-success text-center " role="alert">
                    Picks Submitted successfully!
                  </div>
                )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Test;
