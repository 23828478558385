import React, { useEffect, useState } from 'react';

function GoogleSheets() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sheetId = '1UjkVQUgnhZuJHCbjUgWAW0gMElK7NV8wZCTs_lK581U'; // Replace with your actual Spreadsheet ID
        const apiKey = 'AIzaSyB3m_SW5LSt9LTpNyDvkx3arlOHjLs2FyU'; // Your Google Sheets API key
        const range = 'Total Points!A:B'; // Adjust the range as needed

        const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`, {
            mode: 'no-cors'
          });
                  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        if (result.values) {
          setData(result.values);
        } else {
          setError('No data found in the specified range.');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Total Points</h2>
      <ul>
        {data.map((row, index) => (
          <li key={index}>
            {row.join(': ')} {/* Display each row as a simple text */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GoogleSheets;
