import React, { useState, useEffect } from 'react';
import { ref, onValue, getDatabase, update, get, remove } from 'firebase/database';
import { useAuth } from './firebase'; // Assuming you have a useAuth hook to get the current user's information
import Navbar from './Navbar';
import Footer from './Footer';
import { Link, useNavigate } from 'react-router-dom';


function Invitations() {
    const { auth } = useAuth(); // Get the auth object from the useAuth hook
    const [invitations, setInvitations] = useState([]);
    const [currentUserEmail, setCurrentUserEmail] = useState('');
    const [bracketsData, setBracketsData] = useState({});
    const [showNoInvitationsMessage, setShowNoInvitationsMessage] = useState(false);
    const [error, setError] = useState(false);
   const navigate = useNavigate()

    useEffect(() => {
        // Fetch invitations data from the Firebase database
        const fetchInvitations = async () => {
            try {
                const database = getDatabase();
                const invitationsRef = ref(database, 'invitations');
                onValue(invitationsRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        // Convert invitations object to an array and set it to state
                        const invitationsArray = Object.entries(data).map(([key, value]) => ({ ...value, id: key }));
                        setInvitations(invitationsArray);
                        setShowNoInvitationsMessage(invitationsArray.length === 0);
                    } else {
                        setShowNoInvitationsMessage(true);
                    }
                });
            } catch (error) {
                console.error('Error fetching invitations:', error);
            }
        };

        // Fetch bracket data associated with each invitation
        const fetchBracketsData = async () => {
            try {
                const database = getDatabase();
                const bracketsRef = ref(database, 'CreateBracket');
                onValue(bracketsRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        // Store bracket data in state
                        setBracketsData(data);
                    }
                });
            } catch (error) {
                console.error('Error fetching huddles data:', error);
            }
        };

        fetchInvitations();
        fetchBracketsData();

        // Get current user's email when authenticated
        if (auth.currentUser) {
            setCurrentUserEmail(auth.currentUser.email);
        }
    }, [auth]);

    const handleJoinBracket = async (bracketId, bracket, invitationId) => {
        try {
            console.log('Invitation ID:', invitationId); // Log the invitationId before removing the invitation
    
            const database = getDatabase();
            const bracketRef = ref(database, `CreateBracket/${bracketId}`);
        
            // Retrieve current bracket data
            const bracketSnapshot = await get(bracketRef);
            const bracketData = bracketSnapshot.val();
        
            // Check if the user has already joined
            if (bracketData.participants && bracketData.participants[auth.currentUser.uid]) {
                console.log('User has already joined this huddle.');
                return;
            }
            if (bracketData.participantsCount >= bracket.maxAmountOfPlayers) {
                setError('Huddle is currently full!');
                console.error('Max amount of players in huddle', error);
                return;
            }
        
            // Get the user's information
            const user = auth.currentUser;
            const userDisplayName = user.displayName;
            const userEmail = user.email;
            const userId = user.uid;
        
            // Update participants count and add user UID, email, and displayName to the list
            const updatedParticipantsCount = bracketData.participantsCount + 1;
            const updatedParticipants = {
                ...bracketData.participants,
                [userId]: {
                    displayName: userDisplayName,
                    email: userEmail // Include the user's email
                }
            };
        
            // Update bracket data with new participants count and list
            await update(bracketRef, {
                participantsCount: updatedParticipantsCount,
                participants: updatedParticipants
            });
        
            // Now that bracket update is successful, remove the invitation
            const invitationRef = ref(database, `invitations/${invitationId}`);
            await remove(invitationRef); // Use remove function to delete the invitation from the database
            console.log(`Invitation ${invitationId} removed from the database.`);
        
            // Update invitations in the local state
            setInvitations(prevInvitations => prevInvitations.filter(invitation => invitation.id !== invitationId));
            setShowNoInvitationsMessage(prevInvitations => prevInvitations.length === 1); // Check if this is the last invitation being removed
        
            // Redirect user to the bracket page using useNavigate
            navigate(`/bracket/${bracketId}/${encodeURIComponent(bracketData.nameOfBracket)}/participants`);
        } catch (error) {
            console.error('Error joining huddle:', error);
        }
    };
    


    const rejectInvitation = async (invitationId) => {
        try {
            const database = getDatabase();
            const invitationRef = ref(database, `invitations/${invitationId}`);
            await remove(invitationRef); // Use remove function to delete the invitation from the database
            setInvitations(invitations.filter(invitation => invitation.id !== invitationId)); // Remove the invitation from the local state
            setShowNoInvitationsMessage(invitations.length === 1); // Check if this is the last invitation being removed
            console.log('huddle Deleted sucessfully', invitationId )
        } catch (error) {
            console.error('Error rejecting invitation:', error);
        }
    };
    
    
    
    return (
        <div>
            <div className=''>
                {invitations.map(invitation => {
                    // Only display if the invitation's email matches the current user's email
                    if (invitation.inviteEmail === currentUserEmail) {
                        // Find the corresponding bracket data
                        const bracketData = bracketsData[invitation.bracketId];
                        if (bracketData) {
                            const bracketId = invitation.bracketId; // Define bracketId here
                            return (
                                <section className="bsb-post-pagination-2 bg-dark py-3 py-md-5 py-xl-8" key={invitation.id}>
                                    <div className="container overflow-hidden">
                                        <div className="row justify-content-lg-center">
                                            <div className="col-12 col-lg-10 col-xl-8">
                                                <div className="bg-white p-4 p-md-5 border border-light-subtle">
                                                    <div className="row gy-3 align-items-md-center">
                                                        <div className="col-12 col-md-2"></div>
                                                        <div className="col-12 col-md-8 text-center">
                                                            <h5 className="link-secondary fw-bold fs-6 mb-3">You have been invited to join {bracketData.nameOfBracket}</h5>
                                                            <Link to={`/bracket/${bracketId}/${encodeURIComponent(bracketData.nameOfBracket)}/participants`} onClick={() => handleJoinBracket(bracketId, bracketData.nameOfBracket, invitation.id)}>   <button className='btn btn-success' style={{marginRight: '5px'}}>Join</button> </Link>
                                                            <button className='btn btn-danger' onClick={() => rejectInvitation(invitation.id)}>Reject</button>

                                                        </div>
                                                        <div className="col-12 col-md-2 text-end">
                                                            <Link>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" href="/joinhuddle" />
                                                                </svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            );
                        }
                    }
                    return null;
                })}
            </div>
        </div>
    );
}

export default Invitations;
