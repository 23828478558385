import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Alaves from '../Team-Kits-Images/Alaves.png'
import Athleti from '../Team-Kits-Images/Athleti.png'
import Athletic from '../Team-Kits-Images/Athletic.png'
import Barcelona from '../Team-Kits-Images/Barcelona.png'
import Betis from '../Team-Kits-Images/Betis.png'
import CeltaVigo from '../Team-Kits-Images/CeltaVigo.png'
import Espanyol from '../Team-Kits-Images/Espanyol.png'
import Getafe from '../Team-Kits-Images/Getafe.png'
import Girona from '../Team-Kits-Images/Girona.png'
import LasPalmas from '../Team-Kits-Images/LasPalmas.png'
import Leganes from '../Team-Kits-Images/Leganes.png'
import Madrid from '../Team-Kits-Images/Madrid.png'
import Mallorca from '../Team-Kits-Images/Mallorca.png'
import Osasuna from '../Team-Kits-Images/Osasuna.png'
import Rayo from '../Team-Kits-Images/Rayo.png'
import Sevilla from '../Team-Kits-Images/Sevilla.png'
import Sociedad from '../Team-Kits-Images/Sociedad.png'
import Valencia from '../Team-Kits-Images/Valencia.png'
import Valladolid from '../Team-Kits-Images/Valladolid.png'
import Villareal from '../Team-Kits-Images/Villareal.png'
import Wolves from '../Team-Kits-Images/Wolves.png'
import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'

function LaLigaStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/140/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
      standingsMap[fixture.teams.away.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points += homeResult === "W" ? 3 : homeResult === "D" ? 1 : 0;
      standingsMap[awayTeam].points += awayResult === "W" ? 3 : awayResult === "D" ? 1 : 0;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
  
      // Update lastFive results (most recent on the right)
      standingsMap[homeTeam].lastFive = [...standingsMap[homeTeam].lastFive, homeResult].slice(-5);
      standingsMap[awayTeam].lastFive = [...standingsMap[awayTeam].lastFive, awayResult].slice(-5);
    });
  
    // Convert standings map to array
    return Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  };
  
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
        case 'Alaves':
            return Alaves;
        case 'Atletico Madrid':
            return Athleti;
        case 'Athletic Club':
            return Athletic;
        case 'Barcelona':
            return Barcelona;
        case 'Real Betis':
            return Betis;
        case 'Celta Vigo':
            return CeltaVigo;
        case 'Espanyol':
            return Espanyol;
        case 'Getafe':
            return Getafe;
        case 'Girona':
            return Girona;
        case 'Las Palmas':
            return LasPalmas;
        case 'Leganes':
            return Leganes;
        case 'Real Madrid':
            return Madrid;
        case 'Mallorca':
            return Mallorca;
        case 'Osasuna':
            return Osasuna;
        case 'Rayo Vallecano':
            return Rayo;
        case 'Sevilla':
            return Sevilla;
        case 'Real Sociedad':
            return Sociedad;
        case 'Valencia':
            return Valencia;
        case 'Valladolid':
            return Valladolid;
        case 'Villarreal':
            return Villareal;
        default:
            return null;
    }
};



  return (
<div className=''>
  <div className='App-headerV8'>
    <Navbar />
    <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>La Liga Standings</h2>
    <div className='container text-center mb-2'>
      <i className='text-center text-light' style={{ fontFamily: 'Lato, sans-serif' }}>*Click on team name to view stats </i>
    </div>

    <div className='d-flex justify-content-center'>
      <div className='col-10 col-md-6 col-lg-4'>
        <select
          className='form-select mb-3 text-center'
          style={{ backgroundColor: 'purple', color: 'white' }}
          value={season}
          onChange={(e) => setSeason(e.target.value)}
        >
          <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>La Liga 24/25</option>
          <option value="2023" style={{ backgroundColor: 'white', color: 'black' }}>La Liga 23/24</option>
          <option value="2022" style={{ backgroundColor: 'white', color: 'black' }}>La Liga 22/23</option>
          <option value="2021" style={{ backgroundColor: 'white', color: 'black' }}>La Liga 21/22</option>
          <option value="2020" style={{ backgroundColor: 'white', color: 'black' }}>La Liga 20/21</option>
        </select>
      </div>
    </div>

    <div className='container'>
      {loading ? (
        <h2 className='text-center text-dark'><Spinner /></h2>
      ) : (
        <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
          <table
            className="table transparent-table"
            style={{
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ width: '5%', color: 'white' }}>#</th>
                <th scope="col" className="team-column text-light">Team</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>MP</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>W</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>D</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>L</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>Pts</th>
                <th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>Last 5</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
                  <th scope="row" className={
                    index < 4 ? 'text-primary' :
                    index >= standings.length - 3 ? 'text-danger' :
                    index >= 4 && index <= 6 ? 'text-warning' : 'text-light'}>
                    {index + 1}
                  </th>
                  <td style={{ color: 'white' }}>
                    {getTeamKit(team.team) && (
                      <img
                        src={getTeamKit(team.team)}
                        alt={`${team.team} Kit`}
                        style={{ width: '25px', marginRight: '5px' }}
                      />
                    )}
                {team.team === 'Barcelona' ? (
    <Link to="/barcelona" style={{ textDecoration: 'none', color: 'white' }}>
      Barcelona
    </Link>
  ) : team.team === 'Real Madrid' ? (
    <Link to="/realmadrid" style={{ textDecoration: 'none', color: 'white' }}>Real Madrid</Link>
  ) : team.team === 'Atletico Madrid' ? (
    <Link to="/atleticomadrid" style={{ textDecoration: 'none', color: 'white' }}>Atletico Madrid</Link>
  ) : team.team === 'Sevilla' ? (
    <Link to="/sevilla" style={{ textDecoration: 'none', color: 'white' }}>Sevilla</Link>
  ) : team.team === 'Real Betis' ? (
    <Link to="/realbetis" style={{ textDecoration: 'none', color: 'white' }}>Real Betis</Link>
  ) : team.team === 'Villarreal' ? (
    <Link to="/villarreal" style={{ textDecoration: 'none', color: 'white' }}>Villarreal</Link>
  ) : team.team === 'Real Sociedad' ? (
    <Link to="/realsociedad" style={{ textDecoration: 'none', color: 'white' }}>Real Sociedad</Link>
  ) : team.team === 'Valencia' ? (
    <Link to="/valencia" style={{ textDecoration: 'none', color: 'white' }}>Valencia</Link>
  ) : team.team === 'Athletic Club' ? (
    <Link to="/athleticclub" style={{ textDecoration: 'none', color: 'white' }}>Athletic Club</Link>
  ) : team.team === 'Rayo Vallecano' ? (
    <Link to="/rayovallecano" style={{ textDecoration: 'none', color: 'white' }}>Rayo Vallecano</Link>
  ) : team.team === 'Osasuna' ? (
    <Link to="/osasuna" style={{ textDecoration: 'none', color: 'white' }}>Osasuna</Link>
  ) : team.team === 'Celta Vigo' ? (
    <Link to="/celtavigo" style={{ textDecoration: 'none', color: 'white' }}>Celta Vigo</Link>
  ) : team.team === 'Getafe' ? (
    <Link to="/getafe" style={{ textDecoration: 'none', color: 'white' }}>Getafe</Link>
  ) : team.team === 'Girona' ? (
    <Link to="/girona" style={{ textDecoration: 'none', color: 'white' }}>Girona</Link>
  ) : team.team === 'Alaves' ? (
    <Link to="/alaves" style={{ textDecoration: 'none', color: 'white' }}>Alaves</Link>
  ) : team.team === 'Las Palmas' ? (
    <Link to="/laspalmas" style={{ textDecoration: 'none', color: 'white' }}>Las Palmas</Link>
  ) : team.team === 'Mallorca' ? (
    <Link to="/mallorca" style={{ textDecoration: 'none', color: 'white' }}>Mallorca</Link>
  ) : team.team === 'Cadiz' ? (
    <Link to="/cadiz" style={{ textDecoration: 'none', color: 'white' }}>Cadiz</Link>
  ) : team.team === 'Granada' ? (
    <Link to="/granada" style={{ textDecoration: 'none', color: 'white' }}>Granada</Link>
  ) : team.team === 'Leganes' ? (
    <Link to="/leganes" style={{ textDecoration: 'none', color: 'white' }}>Leganes</Link>
  ) : team.team === 'Espanyol' ? (
    <Link to="/espanyol" style={{ textDecoration: 'none', color: 'white' }}>Espanyol</Link>
  ) : team.team === 'Valladolid' ? (
    <Link to="/valladolid" style={{ textDecoration: 'none', color: 'white' }}>Valladolid</Link>
  ) : (
    team.team
  )}
                  </td>
                  <td style={{ color: 'white' }}>{team.matchesPlayed}</td>
                  <td style={{ color: 'white' }}>{team.wins}</td>
                  <td style={{ color: 'white' }}>{team.draws}</td>
                  <td style={{ color: 'white' }}>{team.losses}</td>
                  <td style={{ color: 'white' }}>{team.points}</td>
                  <td className="d-none d-lg-table-cell">
                    <div className="d-flex flex-row">
                      {team.lastFive.map((result, idx) => (
                        <span
                          key={idx}
                          className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                          style={{
                            width: '23px',
                            height: '23px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.9rem',
                            marginRight: '2px'
                          }}
                        >
                          {result}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

    {/* Legends for qualifications */}
    <div className="container mt-3">
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-primary me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">UEFA Champions League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-warning me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-success me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa Conference League Qualification</div>
        </div>
        <div className="d-flex align-items-center" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-danger me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Relegation</div>
        </div>
      </div>
    </div>

    <div className='container mt-4' style={{ paddingBottom: '5px' }}>
      <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
    </div>
  </div>
  <Footer />
</div>


  );
}

export default LaLigaStandings;
