import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Torino from '../Team-Kits-Images/Torino.png'
import Napoli from '../Team-Kits-Images/Napoli.png'
import Udinese from '../Team-Kits-Images/Udinese.png'
import Juventus from '../Team-Kits-Images/Juventus.png'
import Empoli from '../Team-Kits-Images/Empoli.png'
import Inter from '../Team-Kits-Images/Inter.png'
import Milan from '../Team-Kits-Images/Milan.png'
import Lazio from '../Team-Kits-Images/Lazio.png'
import Roma from '../Team-Kits-Images/Roma.png'
import Verona from '../Team-Kits-Images/Verona.png'
import Fiorentina from '../Team-Kits-Images/Fiorentina.png'
import Atalanta from '../Team-Kits-Images/Atalanta.png'
import Bologna from '../Team-Kits-Images/Bologna.png'
import Parma from '../Team-Kits-Images/Parma.png'
import Como from '../Team-Kits-Images/Com.png'
import Genoa from '../Team-Kits-Images/Genoa.png'
import Lecce from '../Team-Kits-Images/Lecce.png'
import Venezia from '../Team-Kits-Images/Venezia.png'
import Monza from '../Team-Kits-Images/Monza.png'
import Cagliari from '../Team-Kits-Images/Cagliari.png'

import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'

function SerieAStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/135/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
      standingsMap[fixture.teams.away.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points += homeResult === "W" ? 3 : homeResult === "D" ? 1 : 0;
      standingsMap[awayTeam].points += awayResult === "W" ? 3 : awayResult === "D" ? 1 : 0;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
  
      // Update lastFive results (most recent on the right)
      standingsMap[homeTeam].lastFive = [...standingsMap[homeTeam].lastFive, homeResult].slice(-5);
      standingsMap[awayTeam].lastFive = [...standingsMap[awayTeam].lastFive, awayResult].slice(-5);
    });
  
    // Convert standings map to array
    return Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  };
  
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
      case 'Torino':
        return Torino;
      case 'Napoli':
        return Napoli;
      case 'Udinese':
        return Udinese;
      case 'Juventus':
        return Juventus;
      case 'Empoli':
        return Empoli;
      case 'Inter':
        return Inter;
      case 'AC Milan':
        return Milan;
      case 'Lazio':
        return Lazio;
      case 'AS Roma':
        return Roma;
      case 'Verona':
        return Verona;
      case 'Fiorentina':
        return Fiorentina;
      case 'Atalanta':
        return Atalanta;
      case 'Bologna':
        return Bologna;
      case 'Parma':
        return Parma;
      case 'Como':
        return Como;
      case 'Genoa':
        return Genoa;
      case 'Lecce':
        return Lecce;
      case 'Venezia':
        return Venezia;
      case 'Monza':
        return Monza;
      case 'Cagliari':
        return Cagliari;
      default:
        return null;
    }
  };
  


  return (
<div className=''>
  <div className='App-headerV8'>
    <Navbar />
    <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>Serie A Standings</h2>
    <div className='container text-center mb-2'>
      <i className='text-center text-light' style={{ fontFamily: 'Lato, sans-serif' }}>*Click on team name to view stats </i>
    </div>

    <div className='d-flex justify-content-center'>
      <div className='col-10 col-md-6 col-lg-4'>
        <select
          className='form-select mb-3 text-center'
          style={{ backgroundColor: 'purple', color: 'white' }}
          value={season}
          onChange={(e) => setSeason(e.target.value)}
        >
          <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>Serie A 24/25</option>
          <option value="2023" style={{ backgroundColor: 'white', color: 'black' }}>Serie A 23/24</option>
          <option value="2022" style={{ backgroundColor: 'white', color: 'black' }}>Serie A 22/23</option>
          <option value="2021" style={{ backgroundColor: 'white', color: 'black' }}>Serie A 21/22</option>
          <option value="2020" style={{ backgroundColor: 'white', color: 'black' }}>Serie A 20/21</option>
        </select>
      </div>
    </div>

    <div className='container'>
      {loading ? (
        <h2 className='text-center text-dark'><Spinner /></h2>
      ) : (
        <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
          <table
            className="table transparent-table"
            style={{
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ width: '5%', color: 'white' }}>#</th>
                <th scope="col" className="team-column text-light">Team</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>MP</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>W</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>D</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>L</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>Pts</th>
                <th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>Last 5</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
               <th scope="row" className={
    index < 4 ? 'text-primary' :
    index >= standings.length - 3 ? 'text-danger' :
    index === 6 ? 'text-success' :  // 7th position (index 6)
    index >= 4 && index <= 5 ? 'text-warning' : 'text-light'}>
    {index + 1}
</th>

                  <td style={{ color: 'white' }}>
                    {getTeamKit(team.team) && (
                      <img
                        src={getTeamKit(team.team)}
                        alt={`${team.team} Kit`}
                        style={{ width: '25px', marginRight: '5px' }}
                      />
                    )}
                   {team.team === 'Torino' ? (
    <Link to="/torino" style={{ textDecoration: 'none', color: 'white' }}>
      Torino
    </Link>
  ) : team.team === 'Napoli' ? (
    <Link to="/napoli" style={{ textDecoration: 'none', color: 'white' }}>Napoli</Link>
  ) : team.team === 'Udinese' ? (
    <Link to="/udinese" style={{ textDecoration: 'none', color: 'white' }}>Udinese</Link>
  ) : team.team === 'Juventus' ? (
    <Link to="/juventus" style={{ textDecoration: 'none', color: 'white' }}>Juventus</Link>
  ) : team.team === 'Empoli' ? (
    <Link to="/empoli" style={{ textDecoration: 'none', color: 'white' }}>Empoli</Link>
  ) : team.team === 'Inter' ? (
    <Link to="/inter" style={{ textDecoration: 'none', color: 'white' }}>Inter</Link>
  ) : team.team === 'AC Milan' ? (
    <Link to="/milan" style={{ textDecoration: 'none', color: 'white' }}>AC Milan</Link>
  ) : team.team === 'Lazio' ? (
    <Link to="/lazio" style={{ textDecoration: 'none', color: 'white' }}>Lazio</Link>
  ) : team.team === 'AS Roma' ? (
    <Link to="/roma" style={{ textDecoration: 'none', color: 'white' }}>AS Roma</Link>
  ) : team.team === 'Verona' ? (
    <Link to="/verona" style={{ textDecoration: 'none', color: 'white' }}>Verona</Link>
  ) : team.team === 'Fiorentina' ? (
    <Link to="/fiorentina" style={{ textDecoration: 'none', color: 'white' }}>Fiorentina</Link>
  ) : team.team === 'Atalanta' ? (
    <Link to="/atalanta" style={{ textDecoration: 'none', color: 'white' }}>Atalanta</Link>
  ) : team.team === 'Bologna' ? (
    <Link to="/bologna" style={{ textDecoration: 'none', color: 'white' }}>Bologna</Link>
  ) : team.team === 'Parma' ? (
    <Link to="/parma" style={{ textDecoration: 'none', color: 'white' }}>Parma</Link>
  ) : team.team === 'Como' ? (
    <Link to="/como" style={{ textDecoration: 'none', color: 'white' }}>Como</Link>
  ) : team.team === 'Genoa' ? (
    <Link to="/genoa" style={{ textDecoration: 'none', color: 'white' }}>Genoa</Link>
  ) : team.team === 'Lecce' ? (
    <Link to="/lecce" style={{ textDecoration: 'none', color: 'white' }}>Lecce</Link>
  ) : team.team === 'Venezia' ? (
    <Link to="/venezia" style={{ textDecoration: 'none', color: 'white' }}>Venezia</Link>
  ) : team.team === 'Monza' ? (
    <Link to="/monza" style={{ textDecoration: 'none', color: 'white' }}>Monza</Link>
  ) : team.team === 'Cagliari' ? (
    <Link to="/cagliari" style={{ textDecoration: 'none', color: 'white' }}>Cagliari</Link>
  ) : (
    team.team
  )}
                  </td>
                  <td style={{ color: 'white' }}>{team.matchesPlayed}</td>
                  <td style={{ color: 'white' }}>{team.wins}</td>
                  <td style={{ color: 'white' }}>{team.draws}</td>
                  <td style={{ color: 'white' }}>{team.losses}</td>
                  <td style={{ color: 'white' }}>{team.points}</td>
                  <td className="d-none d-lg-table-cell">
                    <div className="d-flex flex-row">
                      {team.lastFive.map((result, idx) => (
                        <span
                          key={idx}
                          className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                          style={{
                            width: '23px',
                            height: '23px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.9rem',
                            marginRight: '2px'
                          }}
                        >
                          {result}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

    {/* Legends for qualifications */}
    <div className="container mt-3">
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-primary me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">UEFA Champions League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-warning me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-success me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa Conference League Qualification</div>
        </div>
        <div className="d-flex align-items-center" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-danger me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Relegation</div>
        </div>
      </div>
    </div>

    <div className='container mt-4' style={{ paddingBottom: '5px' }}>
      <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
    </div>
  </div>
  <Footer />
</div>


  );
}

export default SerieAStandings;
