import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import Navbar from './Navbar';
import Footer from './Footer';

function ArticleDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const database = getDatabase();
    const articleRef = ref(database, `CreateArticle/${id}`);
    onValue(articleRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setArticle(data);
      }
    });
  }, [id]);

  if (!article) {
    return (
      <div className='App-headerV8'>
        <div className='text-light text-center'>
          <h2>Loading...</h2>
        </div>
      </div>
    );
  }

  const handleClick = () => {
    navigate(article.purpose);
  };

  return (
    <div>
      <Navbar />
      <div className='App-headerV8'>
        <div className="container bg-transparent"> 
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7">
              <h2 className="display-5 text-center padtopinput" style={{ color: 'white' }}>{article.title}</h2>
              <h5 className="display mb-5 text-center" style={{ color: 'white' }}>Article Published on: {article.date}</h5>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
              <div className='text-center'>
              <img 
  src={article.image} 
  alt={article.title} 
  style={{ 
    width: '50%', 
    objectFit: 'cover',
    border: '6px solid #0f5132', /* Dark green border to represent a field */
    borderRadius: '15px',        /* Rounded corners */
    padding: '5px',
    backgroundImage: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNSA_hsdpoJaTqr_AWX5wD9e5l-JzRDDPJKw&s)',
    backgroundSize: 'contain',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)' /* Soft shadow for depth */
  }} 
/>
              </div>
              <div className="mt-4" style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: article.longDescription }}></div>
              <div className="text-center mt-4">
                <button onClick={handleClick} className="btn btn-purple mb-3">Learn More!</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ArticleDetail;
