import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Spinner } from 'react-bootstrap';

function Players() {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/players');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        console.log('API Response:', responseData);
        // Check if data is an array before setting the state
        if (Array.isArray(responseData.data)) {
          setPlayers(responseData.data);
        } else {
          throw new Error('Data received is not an array');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching players:', error);
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  return (
    <div className='bg-dark'>
      <div className='App-headerV6'>
        <Navbar />
        <div className='container'>
        <h2 className='text-center text-light'>Scottish & Danish League Players</h2>
        <hr className="w-50 mx-auto mb-3 mb-xl-9 border-dark-subtle" />
        </div>
        <div className='container'>
          {loading ? (
            <h4 className='text-light text-center'><Spinner /></h4>
          ) : (
            <ul className="list-group text-center">
              {players.map((players, index) => (
    <div class="row ">
    <div class="col-sm-6 mx-auto" style={{marginBottom: '10px'}}>
      <div class="card ">
      <img src={players.image_path} className="card-img-top mx-auto" alt={players.name} style={{width: '100px'}} />

        <div class="card-body ">
          <h5 class="card-title">{players.name}</h5>
          <p class="card-text">{players.height}cm</p>
          <p class="card-text">{players.weight}kg</p>

        </div>
      </div>
    </div>

  </div>
                
              ))}
            </ul>
            
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Players;
