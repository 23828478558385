import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar'; // Assuming Navbar and Footer are components in separate files
import Footer from '../Footer';
import { Spinner } from 'react-bootstrap';

function ScottishStandingsNewAPI() {
  const [standings, setStandings] = useState([]);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/standings'); // Update the URL to point to your Node.js server
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setStandings(data.data);
      } catch (error) {
        console.error('Error fetching standings:', error);
      }
    };

    fetchStandings();
  }, []);

  return (
    <div className='bg-dark'>
      <div className='App-headerV6'>
        <Navbar />
        <h2 className='text-center text-light'>Scottish Premiership Standings(New API)</h2>
        <hr className="w-50 mx-auto mb-3 mb-xl-9 border-dark-subtle" />

        <div className='container'>
          {standings.length > 0 ? (
            <table className="table table-dark table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Team</th>
                  <th scope="col">MP</th>
                  <th scope="col">W</th>
                  <th scope="col">D</th>
                  <th scope="col">L</th>
                  <th scope="col">Pts</th>
                </tr>
              </thead>
              <tbody>
                {standings.map((team, index) => (
                  <tr key={index}>
                    <th scope="row">{team.position}</th>
                    <td>{team.team_name}</td>
                    <td>{team.overall?.games_played}</td>
                    <td>{team.overall?.won}</td>
                    <td>{team.overall?.draw}</td>
                    <td>{team.overall?.lost}</td>
                    <td>{team.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 className='text-light text-center'><Spinner /></h4>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ScottishStandingsNewAPI;
