import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import 	Blackburn from '../Team-Kits-Images/Blackburn.png'
import Derby from '../Team-Kits-Images/Derby.png'
import 	Preston from '../Team-Kits-Images/Preston.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Cardiff from '../Team-Kits-Images/Cardiff.png'
import Sunderland from '../Team-Kits-Images/Sunderland.png'
import 	Hull from '../Team-Kits-Images/Hull.png'
import 	Bristol from '../Team-Kits-Images/Bristol.png'
import 	Portsmouth from '../Team-Kits-Images/Portsmouth.png'
import Middlesbrough	 from '../Team-Kits-Images/Middlesbrough.png'
import Swansea	 from '../Team-Kits-Images/Swansea.png'
import Millwall	 from '../Team-Kits-Images/Millwall.png'
import Watford		 from '../Team-Kits-Images/Watford.png'
import Oxford	 from '../Team-Kits-Images/Oxford.png'
import Norwich	 from '../Team-Kits-Images/Norwich.png'
import QPR	 from '../Team-Kits-Images/QPR.png'
import WestBrom	 from '../Team-Kits-Images/WestBrom.png'
import Stoke	 from '../Team-Kits-Images/Stoke.png'
import Coventry	 from '../Team-Kits-Images/Coventry.png'
import Wednesday	 from '../Team-Kits-Images/Wednesday.png'
import Plymouth	 from '../Team-Kits-Images/Plymouth.png'
import Luton	 from '../Team-Kits-Images/LutonTown.png'
import Burnley	 from '../Team-Kits-Images/Burnley.png'
import Leeds	 from '../Team-Kits-Images/Leeds.png'
import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'

function ChampionshipStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
      standingsMap[fixture.teams.away.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points += homeResult === "W" ? 3 : homeResult === "D" ? 1 : 0;
      standingsMap[awayTeam].points += awayResult === "W" ? 3 : awayResult === "D" ? 1 : 0;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
  
      // Update lastFive results (most recent on the right)
      standingsMap[homeTeam].lastFive = [...standingsMap[homeTeam].lastFive, homeResult].slice(-5);
      standingsMap[awayTeam].lastFive = [...standingsMap[awayTeam].lastFive, awayResult].slice(-5);
    });
  
    // Convert standings map to array
    return Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  };
  
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
      case 'Blackburn':
        return Blackburn;
      case 'Derby':
        return Derby;
      case 'Preston':
        return Preston;
      case 'Sheffield Utd':
        return SheffieldUnited;
      case 'Cardiff':
        return Cardiff;
      case 'Sunderland':
        return Sunderland;
      case 'Hull City':
        return Hull;
      case 'Bristol City':
        return Bristol;
      case 'Portsmouth':
        return Portsmouth;
      case 'Middlesbrough':
        return Middlesbrough;
      case 'Swansea':
        return Swansea;
      case 'Millwall':
        return Millwall;
      case 'Watford':
        return Watford;
      case 'Oxford United':
        return Oxford;
      case 'Norwich':
        return Norwich;
      case 'QPR':
        return QPR;
      case 'West Brom':
        return WestBrom;
      case 'Stoke City':
        return Stoke;
      case 'Coventry':
        return Coventry;
      case 'Sheffield Wednesday':
        return Wednesday;
      case 'Plymouth':
        return Plymouth;
      case 'Luton':
        return Luton;
      case 'Burnley':
        return Burnley;
      case 'Leeds':
        return Leeds;
      default:
        return null;
    }
};


  return (
<div className=''>
  <div className='App-headerV8'>
    <Navbar />
    <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>Championship Standings</h2>
    <div className='container text-center mb-2'>
      <i className='text-center text-light' style={{ fontFamily: 'Lato, sans-serif' }}>*Click on team name to view stats </i>
    </div>

    <div className='d-flex justify-content-center'>
      <div className='col-10 col-md-6 col-lg-4'>
        <select
          className='form-select mb-3 text-center'
          style={{ backgroundColor: 'purple', color: 'white' }}
          value={season}
          onChange={(e) => setSeason(e.target.value)}
        >
          <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>Championship 24/25</option>
          <option value="2023" style={{ backgroundColor: 'white', color: 'black' }}>Championship 23/24</option>
          <option value="2022" style={{ backgroundColor: 'white', color: 'black' }}>Championship 22/23</option>
          <option value="2021" style={{ backgroundColor: 'white', color: 'black' }}>Championship 21/22</option>
          <option value="2020" style={{ backgroundColor: 'white', color: 'black' }}>Championship 20/21</option>
        </select>
      </div>
    </div>

    <div className='container'>
      {loading ? (
        <h2 className='text-center text-dark'><Spinner /></h2>
      ) : (
        <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
          <table
            className="table transparent-table"
            style={{
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ width: '5%', color: 'white' }}>#</th>
                <th scope="col" className="team-column text-light">Team</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>MP</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>W</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>D</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>L</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>Pts</th>
                <th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>Last 5</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
                                     <th scope="row" className={index < 2 ? 'text-primary' : index >= standings.length - 3 ? 'text-danger' : index == 2 ? 'text-warning' :  index == 3 ? 'text-warning' : index == 4 ? 'text-warning' : index == 5 ? 'text-warning' :  ''} style={{ backgroundColor: '', color: 'white' }}>{index + 1}</th>

                  <td style={{ color: 'white' }}>
                    {getTeamKit(team.team) && (
                      <img
                        src={getTeamKit(team.team)}
                        alt={`${team.team} Kit`}
                        style={{ width: '25px', marginRight: '5px' }}
                      />
                    )}
                     {team.team === 'Manchester United' ? (
                        <Link to="/manunited" style={{ textDecoration: 'none', color: 'white' }}>
                          Man Utd
                        </Link>
                      ) : team.team === 'Blackburn' ? (
                        <Link to="/Blackburn" style={{ textDecoration: 'none', color: 'white' }}>Blackburn</Link>
                      ) : team.team === 'Bristol City' ? (
                        <Link to="/bristol" style={{ textDecoration: 'none', color: 'white' }}>Bristol</Link>
                      ) : team.team === 'Burnley' ? (
                        <Link to="/Burnley" style={{ textDecoration: 'none', color: 'white' }}>Burnley</Link>
                      ) : team.team === 'Cardiff' ? (
                        <Link to="/cardiff" style={{ textDecoration: 'none', color: 'white' }}>Cardiff</Link>
                      ) : team.team === 'Coventry' ? (
                        <Link to="/coventry" style={{ textDecoration: 'none', color: 'white' }}>Coventry</Link>
                      ) : team.team === 'Derby' ? (
                        <Link to="/Derby" style={{ textDecoration: 'none', color: 'white' }}>Derby </Link>
                      ) : team.team === 'Hull City' ? (
                        <Link to="/hullcity" style={{ textDecoration: 'none', color: 'white' }}>Hull</Link>
                      ) : team.team === 'Leeds' ? (
                        <Link to="/leeds" style={{ textDecoration: 'none', color: 'white' }}>Leeds</Link>
                      ) : team.team === 'Luton' ? (
                        <Link to="/luton" style={{ textDecoration: 'none', color: 'white' }}>Luton</Link>
                      ) : team.team === 'Middlesbrough' ? (
                        <Link to="/middlesbrough" style={{ textDecoration: 'none', color: 'white' }}>Middlesbrough</Link>
                      ) : team.team === 'Millwall' ? (
                        <Link to="/millwall" style={{ textDecoration: 'none', color: 'white' }}>Millwall</Link>
                      ) : team.team === 'Norwich' ? (
                        <Link to="/Norwich" style={{ textDecoration: 'none', color: 'white' }}>Norwich</Link>
                      ) : team.team === 'Oxford United' ? (
                        <Link to="/oxfordunited " style={{ textDecoration: 'none', color: 'white' }}>Oxford </Link>
                      ) : team.team === 'Plymouth' ? (
                        <Link to="/Plymouth" style={{ textDecoration: 'none', color: 'white' }}>Plymouth</Link>
                      ) : team.team === 'Portsmouth' ? (
                        <Link to="/Portsmouth" style={{ textDecoration: 'none', color: 'white' }}>Portsmouth</Link>
                      ) : team.team === 'Preston' ? (
                        <Link to="/preston" style={{ textDecoration: 'none', color: 'white' }}>Preston</Link>
                      ) : team.team === 'QPR' ? (
                        <Link to="/QPR" style={{ textDecoration: 'none', color: 'white' }}>QPR</Link>
                      ) : team.team === 'Sunderland' ? (
                        <Link to="/Sunderland" style={{ textDecoration: 'none', color: 'white' }}>Sunderland</Link>
                      ) : team.team === 'Sheffield Wednesday' ? (
                        <Link to="/sheffieldwednesday" style={{ textDecoration: 'none', color: 'white' }}>Sheffield Wed</Link>
                      ) : team.team === 'Stoke City' ? (
                        <Link to="/Stokecity" style={{ textDecoration: 'none', color: 'white' }}>Stoke</Link>
                      ) : team.team === 'Swansea' ? (
                        <Link to="/Swansea" style={{ textDecoration: 'none', color: 'white' }}>Swansea</Link>
                      ) : team.team === 'Watford' ? (
                        <Link to="/Watford" style={{ textDecoration: 'none', color: 'white' }}>Watford</Link>
                      ) :  team.team === 'West Brom' ? (
                        <Link to="/WestBrom" style={{ textDecoration: 'none', color: 'white' }}>West Brom</Link>
                      ) :  team.team === 'Sheffield Utd' ? (
                        <Link to="/SheffieldUtd" style={{ textDecoration: 'none', color: 'white' }}>Sheffield Utd</Link>
                     ) : (
                        team.team
                      )}
                  </td>
                  <td style={{ color: 'white' }}>{team.matchesPlayed}</td>
                  <td style={{ color: 'white' }}>{team.wins}</td>
                  <td style={{ color: 'white' }}>{team.draws}</td>
                  <td style={{ color: 'white' }}>{team.losses}</td>
                  <td style={{ color: 'white' }}>{team.points}</td>
                  <td className="d-none d-lg-table-cell">
                    <div className="d-flex flex-row">
                      {team.lastFive.map((result, idx) => (
                        <span
                          key={idx}
                          className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                          style={{
                            width: '23px',
                            height: '23px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.9rem',
                            marginRight: '2px'
                          }}
                        >
                          {result}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

    {/* Legends for qualifications */}
    <div className='d-flex justify-content-center'>
            <div className='text-center text-primary'>| </div>
            <div className='text-light'>Promotion</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-warning'>| </div>
            <div className='text-light'>Play Off</div>
          </div>
        
          <div className='d-flex justify-content-center'>
            <div className='text-center text-danger'>| </div>
            <div className='text-light'>Relegation</div>
          </div>

    <div className='container mt-4' style={{ paddingBottom: '5px' }}>
      <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
    </div>
  </div>
  <Footer />
</div>


  );
}

export default ChampionshipStandings;
