import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import Ipswich from '../Team-Kits-Images/Ipswitch.png'
import Leicester from '../Team-Kits-Images/Leicester.png'
import Southampton from '../Team-Kits-Images/Southampton.png'
import { Spinner } from 'react-bootstrap';
import HomeTeamFixtures from './Home.Team.Fixtures';
import 	Blackburn from '../Team-Kits-Images/Blackburn.png'
import Derby from '../Team-Kits-Images/Derby.png'
import 	Preston from '../Team-Kits-Images/Preston.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Cardiff from '../Team-Kits-Images/Cardiff.png'
import Sunderland from '../Team-Kits-Images/Sunderland.png'
import 	Hull from '../Team-Kits-Images/Hull.png'
import 	Bristol from '../Team-Kits-Images/Bristol.png'
import 	Portsmouth from '../Team-Kits-Images/Portsmouth.png'
import Middlesbrough	 from '../Team-Kits-Images/Middlesbrough.png'
import Swansea	 from '../Team-Kits-Images/Swansea.png'
import Millwall	 from '../Team-Kits-Images/Millwall.png'
import Watford		 from '../Team-Kits-Images/Watford.png'
import Oxford	 from '../Team-Kits-Images/Oxford.png'
import Norwich	 from '../Team-Kits-Images/Norwich.png'
import QPR	 from '../Team-Kits-Images/QPR.png'
import WestBrom	 from '../Team-Kits-Images/WestBrom.png'
import Stoke	 from '../Team-Kits-Images/Stoke.png'
import Coventry	 from '../Team-Kits-Images/Coventry.png'
import Wednesday	 from '../Team-Kits-Images/Wednesday.png'
import Plymouth	 from '../Team-Kits-Images/Plymouth.png'
import Luton	 from '../Team-Kits-Images/LutonTown.png'
import Burnley	 from '../Team-Kits-Images/Burnley.png'
import Leeds	 from '../Team-Kits-Images/Leeds.png'


function SunderlandStats() {
  const [teamStats, setTeamStats] = useState(null);
  const [nextFixture, setNextFixture] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league-teams-stats/40/season/2024', {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        const Data = data.find(team => team.id === 746);
        if (Data) {
          setTeamStats(Data);
        } else {
          console.log(' data not found');
        }
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    const fetchNextFixture = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/2024', {
            headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
              }
            })
        if (!response.ok) {
          throw new Error('Failed to fetch fixtures');
        }
        const fixturesData = await response.json();
        console.log('All fixtures:', fixturesData);

        const Fixtures = fixturesData.filter(fixture => {
          return fixture.teams.home.id === 746 || fixture.teams.away.id === 746;
        });
        console.log('Fixtures:', Fixtures);

        // Sort the fixtures by date in ascending order
        Fixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

        // Find the first upcoming fixture
        const upcomingFixture = Fixtures.find(fixture => {
          const fixtureDate = new Date(fixture.fixture.date); // Convert fixture date string to Date object
          const currentDate = new Date(); // Get current date
          return fixtureDate > currentDate; // Return true if fixture date is in the future
        });

        setNextFixture(upcomingFixture);
      } catch (error) {
        console.error('Error fetching fixtures:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
    fetchNextFixture();
  }, []);

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  const form = teamStats?.form || '';

  // Convert the form string into an array of characters
  const formArray = form.split('');

  // Get the last 5 matches from the form array
  const lastFiveMatches = formArray.slice(-5);

  const getClassForMatch = (result) => {
    switch (result) {
      case 'W':
        return 'success'; // Corrected class name to Bootstrap's 'success'
      case 'D':
        return 'warning'; // Corrected class name to Bootstrap's 'warning'
      case 'L':
        return 'danger'; // Corrected class name to Bootstrap's 'danger'
      default:
        return '';
    }
  };

const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  
  return (
    <div>
        <Navbar />
        <div className='App-headerV8'>
      {teamStats ? (
          <section className="bg-transparent py-3 py-md-5 py-xl-8">
          <div className="container">
              <div className="row justify-content-md-center">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                      <h2 className="mb-4 display-5 text-center text-light">{teamStats.name} Team Stats</h2>
                      <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                  </div>
              </div>
          </div>

          <div className="container">
              <div className="row gy-4 gy-lg-0">
                  <div className="col-12 col-lg-4 col-xl-3">
                      <div className="row gy-4">
                          <div className="col-12 ">
                              <div className="card widget-card border-light shadow-sm">
                                  <h6 className="card-header text-bg-danger border- text-center">{teamStats.name} Home Kit</h6>
                                  <div className="card-body">
                                      <div className="text-center mb-3">
                                          <img src={Sunderland} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                      </div>
                                    
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 ">
                              <div className="card widget-card border-light shadow-sm">
                                  <h6 className="card-header text-bg-danger border- text-center">About {teamStats.name}</h6>
                                  <div className="card-body">
                               
                                      <h5 className="text-center mb-1">Manager</h5>
                                      <p className="text-center text-secondary mb-4">Régis Le Bris</p>
                                      <h5 className="text-center mb-1">Stadium</h5>
                                      <p className="text-center text-secondary mb-4">Stadium of Light</p>
                                      <h5 className="text-center mb-1">Year Founded</h5>
                                      <p className="text-center text-secondary mb-4">1879</p>

{/* <p className="text-center text-secondary mb-1">
Least Recent <FontAwesomeIcon icon={faBackward} /> <FontAwesomeIcon icon={faForward} /> Most Recent 

</p> */}

                            </div>
                              </div>
                          </div>
                          <div className="col-12">
                      <div className="card widget-card border-light shadow-sm">
                        <h6 className="card-header text-bg-danger border- text-center">{teamStats.name}'s Next EFL Fixture</h6>
                        <div className="card-body">
                          {nextFixture ? (
                            <div>
                            <div className='d-flex text-center'>
                              <b className="text-center mb-1 col-5">
                                                           {/* Start of kits */}
{nextFixture.teams.home.name === 'Blackburn' ? (
  <>
    <img src={Blackburn} alt="Blackburn" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>BLB</span>
  </>
) : nextFixture.teams.home.name === 'Bristol City' ? (
  <>
    <img src={Bristol} alt="Bristol City" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>BRC</span>
  </>
) : nextFixture.teams.home.name === 'Cardiff' ? (
  <>
    <img src={Cardiff} alt="Cardiff" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>CAR</span>
  </>
) : nextFixture.teams.home.name === 'Coventry' ? (
  <>
    <img src={Coventry} alt="Coventry" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>COV</span>
  </>
) : nextFixture.teams.home.name === 'Hull City' ? (
  <>
    <img src={Hull} alt="Hull City" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>HUL</span>
  </>
) : nextFixture.teams.home.name === 'Middlesbrough' ? (
  <>
    <img src={Middlesbrough} alt="Middlesbrough" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>MID</span>
  </>
) : nextFixture.teams.home.name === 'Millwall' ? (
  <>
    <img src={Millwall} alt="Millwall" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>MIL</span>
  </>
) : nextFixture.teams.home.name === 'Norwich' ? (
  <>
    <img src={Norwich} alt="Norwich" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>NOR</span>
  </>
) : nextFixture.teams.home.name === 'Plymouth' ? (
  <>
    <img src={Plymouth} alt="Plymouth" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>PLY</span>
  </>
) : nextFixture.teams.home.name === 'Portsmouth' ? (
  <>
    <img src={Portsmouth} alt="Portsmouth" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>POR</span>
  </>
) : nextFixture.teams.home.name === 'Preston' ? (
  <>
    <img src={Preston} alt="Preston" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>PRE</span>
  </>
) : nextFixture.teams.home.name === 'QPR' ? (
  <>
    <img src={QPR} alt="QPR" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>QPR</span>
  </>
) : nextFixture.teams.home.name === 'Sheffield Wednesday' ? (
  <>
    <img src={Wednesday} alt="Sheffield Wednesday" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>SHW</span>
  </>
) : nextFixture.teams.home.name === 'Stoke' ? (
  <>
    <img src={Stoke} alt="Stoke" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>STO</span>
  </>
) : nextFixture.teams.home.name === 'Sunderland' ? (
  <>
    <img src={Sunderland} alt="Sunderland" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>SUN</span>
  </>
) : nextFixture.teams.home.name === 'Swansea' ? (
  <>
    <img src={Swansea} alt="Swansea" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>SWA</span>
  </>
) : nextFixture.teams.home.name === 'Watford' ? (
  <>
    <img src={Watford} alt="Watford" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>WAT</span>
  </>
) : nextFixture.teams.home.name === 'West Brom' ? (
  <>
    <img src={WestBrom} alt="West Brom" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>WBA</span>
  </>
) : nextFixture.teams.home.name === 'Leeds' ? (
  <>
    <img src={Leeds} alt="Leeds" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>LEE</span>
  </>
) : nextFixture.teams.home.name === 'Derby' ? (
  <>
    <img src={Derby} alt="Derby" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>DER</span>
  </>
) : nextFixture.teams.home.name === 'Sheffield Utd' ? (
  <>
    <img src={SheffieldUnited} alt="Sheffield Utd" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>SHU</span>
  </>
) : nextFixture.teams.home.name === 'Oxford United' ? (
  <>
    <img src={Oxford} alt="Oxford United" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>OXF</span>
  </>
) : nextFixture.teams.home.name === 'Luton' ? (
  <>
    <img src={Luton} alt="Luton" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>LUT</span>
  </>
) : nextFixture.teams.home.name === 'Burnley' ? (
  <>
    <img src={Burnley} alt="Burnley" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>BUR</span>
  </>
) : (
  <span style={{ fontStyle: 'normal' }}>{nextFixture.teams.home.name}</span>
)}
{/* End of kits */}

                                </b> 
                              <h5 className="text-center mb-1 col-2 padtopstats">VS</h5>
                              <b className="text-center mb-1 col-5">
                              {/* Start of kits */}
{nextFixture.teams.away.name === 'Blackburn' ? (
  <>
    <img src={Blackburn} alt="Blackburn" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>BLB</span>
  </>
) : nextFixture.teams.away.name === 'Bristol City' ? (
  <>
    <img src={Bristol} alt="Bristol City" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>BRC</span>
  </>
) : nextFixture.teams.away.name === 'Cardiff' ? (
  <>
    <img src={Cardiff} alt="Cardiff" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>CAR</span>
  </>
) : nextFixture.teams.away.name === 'Coventry' ? (
  <>
    <img src={Coventry} alt="Coventry" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>COV</span>
  </>
) : nextFixture.teams.away.name === 'Hull City' ? (
  <>
    <img src={Hull} alt="Hull City" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>HUL</span>
  </>
) : nextFixture.teams.away.name === 'Middlesbrough' ? (
  <>
    <img src={Middlesbrough} alt="Middlesbrough" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>MID</span>
  </>
) : nextFixture.teams.away.name === 'Millwall' ? (
  <>
    <img src={Millwall} alt="Millwall" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>MIL</span>
  </>
) : nextFixture.teams.away.name === 'Norwich' ? (
  <>
    <img src={Norwich} alt="Norwich" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>NOR</span>
  </>
) : nextFixture.teams.away.name === 'Plymouth' ? (
  <>
    <img src={Plymouth} alt="Plymouth" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>PLY</span>
  </>
) : nextFixture.teams.away.name === 'Portsmouth' ? (
  <>
    <img src={Portsmouth} alt="Portsmouth" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>POR</span>
  </>
) : nextFixture.teams.away.name === 'Preston' ? (
  <>
    <img src={Preston} alt="Preston" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>PRE</span>
  </>
) : nextFixture.teams.away.name === 'QPR' ? (
  <>
    <img src={QPR} alt="QPR" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>QPR</span>
  </>
) : nextFixture.teams.away.name === 'Sheffield Wednesday' ? (
  <>
    <img src={Wednesday} alt="Sheffield Wednesday" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>SHW</span>
  </>
) : nextFixture.teams.away.name === 'Stoke' ? (
  <>
    <img src={Stoke} alt="Stoke" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>STO</span>
  </>
) : nextFixture.teams.away.name === 'Sunderland' ? (
  <>
    <img src={Sunderland} alt="Sunderland" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>SUN</span>
  </>
) : nextFixture.teams.away.name === 'Swansea' ? (
  <>
    <img src={Swansea} alt="Swansea" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>SWA</span>
  </>
) : nextFixture.teams.away.name === 'Watford' ? (
  <>
    <img src={Watford} alt="Watford" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>WAT</span>
  </>
) : nextFixture.teams.away.name === 'West Brom' ? (
  <>
    <img src={WestBrom} alt="West Brom" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>WBA</span>
  </>
) : nextFixture.teams.away.name === 'Leeds' ? (
  <>
    <img src={Leeds} alt="Leeds" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>LEE</span>
  </>
) : nextFixture.teams.away.name === 'Derby' ? (
  <>
    <img src={Derby} alt="Derby" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>DER</span>
  </>
) : nextFixture.teams.away.name === 'Sheffield Utd' ? (
  <>
    <img src={SheffieldUnited} alt="Sheffield Utd" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>SHU</span>
  </>
) : nextFixture.teams.away.name === 'Oxford United' ? (
  <>
    <img src={Oxford} alt="Oxford United" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>OXF</span>
  </>
) : nextFixture.teams.away.name === 'Luton' ? (
  <>
    <img src={Luton} alt="Luton" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>LUT</span>
  </>
) : nextFixture.teams.away.name === 'Burnley' ? (
  <>
    <img src={Burnley} alt="Burnley" style={{ width: '75px', height: 'auto' }} />
    <br />
    <span>BUR</span>
  </>
) : (
  <span style={{ fontStyle: 'normal' }}>{nextFixture.teams.away.name}</span>
)}
{/* End of kits */}
                                </b>
                            </div>
                                                          <p className='text-center text-secondary mb-4'>{convertToFormattedDateTime(nextFixture.fixture.date)}</p>

                     
                                                          </div>

                          ) : (
                            <p className='text-center text-secondary'>No upcoming fixture</p>
                          )}
                              <h5 className="text-center mb-1">Last 5 EFL</h5>
    <p className="text-center text-secondary mb-1">
  {lastFiveMatches.map((result, index) => (
    <span key={index}>
      <span className={`badge bg-${getClassForMatch(result)} me-1`}>{result}</span>
    </span>
  ))}
</p>
                        </div>
                      </div>
                    </div>

                      </div>
                  </div>
                  {/* gap */}
                  <div className="col-12 col-lg-8 col-xl-9">
                      <div className="card widget-card border-light shadow-sm">
                          <div className="card-body p-4">
                              <ul className="nav nav-tabs" id="profileTab" role="tablist">
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">Overview</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Scoring Stats</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link" id="fun-tab" data-bs-toggle="tab" data-bs-target="#fun-tab-pane" type="button" role="tab" aria-controls="fun-tab-pane" aria-selected="false">Fun Stats</button>
                                  </li>
                              </ul>
                              <div className="tab-content pt-4" id="profileTabContent">
                                  <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex="0">
                                      {/* Content for Overview tab */}
                                      <h5 className="mb-3">EFL Games</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Games</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.played && teamStats.fixtures.played.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Won</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Drawn</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.draws && teamStats.fixtures.draws.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Lost</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.loses && teamStats.fixtures.loses.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Away Wins</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.away} </div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Home Wins</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.home} </div>
                                              </div> 
                                      </div>
                                      <h5 className="mb-3">Notable Results</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Home Win</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Away Win</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.away}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Home Loss</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Away Loss</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.away}</div>
                                              </div> 
                                      </div>

                                  </div>

                                  <div className="tab-pane fade" id="fun-tab-pane" role="tabpanel" aria-labelledby="fun-tab" tabIndex="2">
  {/* Content for Other Stats tab */}
  <h5 className="mb-3">Interesting Statistics</h5>

  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Yellow Cards</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
        {teamStats.cards && teamStats.cards.yellow && Object.values(teamStats.cards.yellow).reduce((acc, cur) => acc + cur.total, 0)}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Red Cards</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
        {teamStats.cards && teamStats.cards.red && Object.values(teamStats.cards.red).reduce((acc, cur) => acc + cur.total, 0)}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Pentalties Scored</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.total}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Pentalties Missed</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.missed && teamStats.penalty.missed.total}

      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Penalty Goal Rate</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.percentage}
      </div>
    </div> 
  </div>
  <i>*note these statistics are only for the EFL</i>

</div>



                                  <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="1">
                                      {/* Content for Other Stats tab */}
                                      <h5 className="mb-3">Scoring Statistics</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored At Home</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored Away</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.away}</div>
                                              </div> 
                                      </div>
                 
                                      
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Average Goals Scored Per Game</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.average.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Average Goals Conceded Per Game</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.against && teamStats.goals.against.average.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Clean Sheets</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.clean_sheet && teamStats.clean_sheet.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Games Without Scoring</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.failed_to_score && teamStats.failed_to_score.total}</div>
                                              </div> 
                                              <i>*note these statistics are only for the EFL</i>

                                      </div>
                                    
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/*Gap finishes */}
              </div>
          </div>
          <div className='container'>
<div className="text- mt-4" style={{paddingBottom: '5px'}}>
            <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
          </div>
          </div>
      </section>
      
      ) : (
        <h2 className='text-center'><Spinner /></h2>
      )}
      </div>
      <Footer />
    </div>
  );
}
// {teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}
export default SunderlandStats;
