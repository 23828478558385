import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Image123 from './LogoWhite3.png';
import { useTranslation } from 'react-i18next';



function Footer() {
  const { t } = useTranslation();

  const pageSettings = {
    faq: {
      visible: true,
      accessible: true,
    },
  };

  return (
    <div className='Footer '>
      <footer className="footer ">
        <div className="py-3 py-md-5 py-xl-8 border-top border-dark-subtle">
          <div className="container overflow-hidden">
            <div className="row gy-3 gy-md-5 gy-xl-0 align-items-sm-center">
              <div className="col-xs-12 col-sm-6 col-xl-3 order-0 order-xl-0">
                <div className="footer-logo-wrapper text-center text-sm-start">
                  <Link to='/' className="nav-link link-secondary px-2 px-md-3">
                    <img src={Image123} alt=" Logo" width="175" height="57" />
                  </Link>
                </div>
              </div>

              <div className="col-xs-12 col-xl-6 order-2 order-xl-1">
  <ul className="nav justify-content-center">

    {pageSettings.faq.visible && <li>      <Link to='/faq' className="nav-link link-dark px-2 px-md-3">{t('faq')}</Link>
    </li>}
    <li className="nav-item">
      <Link to='/contact' className="nav-link link-dark px-2 px-md-3">{t('contactFooter')}</Link>
    </li>
    <li className="nav-item">
      <Link to='/terms' className="nav-link link-dark px-2 px-md-3">{t('terms')}</Link>
    </li>
    <li className="nav-item">
      <Link to='/privacy' className="nav-link link-dark px-2 px-md-3">{t('privacy')}</Link>
    </li>
  </ul>
</div>


              <div className="col-xs-12 col-sm-6 col-xl-3 order-1 order-xl-2">
                <ul className="nav justify-content-center justify-content-sm-end">
        
                  <li className="nav-item">
                    <a className="nav-link link-dark px-3 " href="https://www.facebook.com/people/Predictify/61560479297637/" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-facebook text-primary" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
</svg>
                    </a>
                  </li>
                  <li className="nav-item">
  <a className="nav-link link-dark px-3" href="https://www.instagram.com/TeamPredictify">
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="23" 
      height="23" 
      viewBox="0 0 16 16"
      style={{ fill: "url(#instagramGradient)" }}
    >
      <defs>
        <linearGradient id="instagramGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#f09433", stopOpacity: 1 }} />
          <stop offset="25%" style={{ stopColor: "#e6683c", stopOpacity: 1 }} />
          <stop offset="50%" style={{ stopColor: "#dc2743", stopOpacity: 1 }} />
          <stop offset="75%" style={{ stopColor: "#cc2366", stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: "#bc1888", stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
    </svg>
  </a>
</li>

          
                </ul>
              </div>
              
            </div>
            <div className="footer-copyright-wrapper text-center text-dark">
  {/* {t('footer.copyright')} */}
  Predictify ver 1.0.1 built by ATOM Technology Ltd
</div>
<div className="credits text-dark text-center mt-2 fs-7">
  {/* {t('footer.credits')}  */}
  All feedback is welcome and encouraged – <Link to ='/contact' style={{textDecoration: 'none'}}>Please contact us here
</Link>  <a href="https://google.com/" className="link-dark text-decoration-none"></a>
</div>

          </div>
        </div>


      </footer>
    </div>
  );
}

export default Footer;

