import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Spinner } from 'react-bootstrap';

function RangersSchedule() {
  const [fixtures, setFixtures] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/rangers/schedule');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log('API Response:', data);
        const fixturesData = data.data[0]?.rounds.flatMap(round => round.fixtures);
        console.log('Fixtures Data:', fixturesData);
        setFixtures(fixturesData || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching schedule:', error);
        setLoading(false);
      }
    };

    fetchSchedule();
  }, []);

  const renderTeamImage = (fixture, participantIndex) => {
    const participant = fixture.participants[participantIndex];
    const isHomeTeam = participant.meta && participant.meta.location === 'home';
    
    return (
      <img
        src={isHomeTeam ? fixture.participants[0].image_path : fixture.participants[1].image_path}
        alt={participant.name}
        style={{ maxWidth: '50px' }}
      />
    );
  };

  const sortedFixtures = fixtures.slice().sort((a, b) => {
    return new Date(a.starting_at) - new Date(b.starting_at);
  });

  return (
    <div className='bg-dark'>
      <div className='App-headerV6'>
        <Navbar />
        <h2 className='text-center text-light'>Rangers Fixtures</h2>
        <hr className="w-50 mx-auto mb-3 mb-xl-9 border-dark-subtle" />

        <div className='container'>
          {loading ? (
            <h4 className='text-light text-center'><Spinner /></h4>
          ) : (
            <ul className="list-group text-center">
              {sortedFixtures.map((fixture, index) => (
                <li key={index} className="list-group-item">
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        {renderTeamImage(fixture, 0)}
                      </div>
                      <div class="col">
                        <h3>{fixture.name}</h3>
                      </div>
                      <div class="col">
                        {renderTeamImage(fixture, 1)}
                      </div>
                    </div>
                  </div>
                  <p>{fixture.starting_at}</p>
                  {fixture.result_info && (
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          Home Goals: {fixture.scores.find(score => score.participant === 'home')?.goals}
                        </div>
                        <div class="col">
                          {fixture.result_info}
                        </div>
                        <div class="col">
                          Away Goals: {fixture.scores.find(score => score.participant === 'away')?.goals}
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RangersSchedule;
