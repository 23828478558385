import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { useTranslation } from 'react-i18next';


const AuthDetails = ({ navigate }) => {
  const [authUser, setAuthUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const userSignOut = () => {
    signOut(auth).then(() => {
      console.log('signed out');
      navigate('/login');
    }).catch(error => console.log(error));
  };

  return (
    <div>
      {authUser ? (
        <div style={{ display: 'flex', gap: '10px' }}>
          {authUser.emailVerified ? (
            <>
              {/* <button className='btn btn-outline-dark' onClick={userSignOut}>
                Sign out
              </button> */}
   <div className="p-0">
      <div className="dropdown ">
        <button
          className="btn btn-outline-light text-center dropdown-toggle p-0"
          type="button"
          id="dropdownMenuButton"
          onClick={toggleDropdown}
        >
          <div className="btn btn-purple ">
            <FaUser />
          </div>
        </button>
        <ul
          className={`dropdown-menu ${dropdownOpen ? 'show' : ''} p-0`}
          aria-labelledby="dropdownMenuButton"
        >
          <li>
            <Link to="/userprofile" className="dropdown-item custom-bg-purple" onClick={toggleDropdown}>
            {t('profile')}

            </Link>
          </li>
          <li>
            <button className="dropdown-item custom-bg-purple" onClick={userSignOut}>
            {t('logout')}

            </button>
          </li>
        </ul>
      </div>
    </div>         </>
          ) : (
            <div style={{ display: 'flex', gap: '10px' }}>
              {/* <Link to='/login'><button className='btn btn-outline-purple-bg'>Log in</button></Link> */}
              {/* <Link to='/signup'><button className='btn btn-outline-dark'>Sign up</button></Link> */}
            </div>
          )}
        </div>
      ) : 
      <div style={{ display: 'flex', gap: '10px' }}>
<Link to='/login'><button className=' btn btn-purple'>{t('login')}</button></Link>
      {/* <Link to='/signup'><button className='btn btn-outline-light'>Sign up</button></Link> */}
    </div>  }
    </div>
  );
};

export default AuthDetails;
