import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BayernMunich from '../Team-Kits-Images/BayernMunich.png'
import Leverkusen from '../Team-Kits-Images/Leverkusen.png'
import Freiburg from '../Team-Kits-Images/Freiburg.png'
import Frankfurt from '../Team-Kits-Images/Frankfurt.png'
import Berlin from '../Team-Kits-Images/Berlin.png'
import Leipzig from '../Team-Kits-Images/Leipzig.png'
import Stuttgart from '../Team-Kits-Images/Stuttgart.png'
import Dortmund from '../Team-Kits-Images/Dortmund.png'
import Heidenheim from '../Team-Kits-Images/Heidenheim.png'
import Mainz from '../Team-Kits-Images/Mainz.png'
import Bremen from '../Team-Kits-Images/Bremen.png'
import Augsburg from '../Team-Kits-Images/Augsburg.png'
import Wolfsburg from '../Team-Kits-Images/Wolfsburg.png'
import Monch from '../Team-Kits-Images/Monch.png'
import Hoffenheim from '../Team-Kits-Images/Hoffenheim.png'
import Bochum from '../Team-Kits-Images/Bochum.png'
import Pauli from '../Team-Kits-Images/Pauli.png'
import Kiel from '../Team-Kits-Images/Kiel.png'
import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'

function BundesligaStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/78/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
      standingsMap[fixture.teams.away.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points += homeResult === "W" ? 3 : homeResult === "D" ? 1 : 0;
      standingsMap[awayTeam].points += awayResult === "W" ? 3 : awayResult === "D" ? 1 : 0;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
  
      // Update lastFive results (most recent on the right)
      standingsMap[homeTeam].lastFive = [...standingsMap[homeTeam].lastFive, homeResult].slice(-5);
      standingsMap[awayTeam].lastFive = [...standingsMap[awayTeam].lastFive, awayResult].slice(-5);
    });
  
    // Convert standings map to array
    return Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  };
  
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
        case 'Bayern München':
            return BayernMunich;
        case 'Bayer Leverkusen':
            return Leverkusen;
        case 'SC Freiburg':
            return Freiburg;
        case 'Eintracht Frankfurt':
            return Frankfurt;
        case 'Union Berlin':
            return Berlin;
        case 'RB Leipzig':
            return Leipzig;
        case 'VfB Stuttgart':
            return Stuttgart;
        case 'Borussia Dortmund':
            return Dortmund;
        case '1. FC Heidenheim':
            return Heidenheim;
        case 'FSV Mainz 05':
            return Mainz;
        case 'Werder Bremen':
            return Bremen;
        case 'FC Augsburg':
            return Augsburg;
        case 'VfL Wolfsburg':
            return Wolfsburg;
        case 'Borussia Mönchengladbach':
            return Monch;
        case '1899 Hoffenheim':
            return Hoffenheim;
        case 'VfL Bochum':
            return Bochum;
        case 'FC St. Pauli':
            return Pauli;
        case 'Holstein Kiel':
            return Kiel;
        default:
            return null;
    }
};


  return (
<div className=''>
  <div className='App-headerV8'>
    <Navbar />
    <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>Bundesliga Standings</h2>
    <div className='container text-center mb-2'>
      <i className='text-center text-light' style={{ fontFamily: 'Lato, sans-serif' }}>*Click on team name to view stats </i>
    </div>

    <div className='d-flex justify-content-center'>
      <div className='col-10 col-md-6 col-lg-4'>
        <select
          className='form-select mb-3 text-center'
          style={{ backgroundColor: 'purple', color: 'white' }}
          value={season}
          onChange={(e) => setSeason(e.target.value)}
        >
          <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>Bundesliga 24/25</option>
          <option value="2023" style={{ backgroundColor: 'white', color: 'black' }}>Bundesliga 23/24</option>
          <option value="2022" style={{ backgroundColor: 'white', color: 'black' }}>Bundesliga 22/23</option>
          <option value="2021" style={{ backgroundColor: 'white', color: 'black' }}>Bundesliga 21/22</option>
          <option value="2020" style={{ backgroundColor: 'white', color: 'black' }}>Bundesliga 20/21</option>
        </select>
      </div>
    </div>

    <div className='container'>
      {loading ? (
        <h2 className='text-center text-dark'><Spinner /></h2>
      ) : (
        <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
          <table
            className="table transparent-table"
            style={{
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ width: '5%', color: 'white' }}>#</th>
                <th scope="col" className="team-column text-light">Team</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>MP</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>W</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>D</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>L</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>Pts</th>
                <th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>Last 5</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
               <th scope="row" className={
    index < 4 ? 'text-primary' :
    index >= standings.length - 3 ? 'text-danger' :
    index === 6 ? 'text-success' :  // 7th position (index 6)
    index >= 4 && index <= 5 ? 'text-warning' : 'text-light'}>
    {index + 1}
</th>

                  <td style={{ color: 'white' }}>
                    {getTeamKit(team.team) && (
                      <img
                        src={getTeamKit(team.team)}
                        alt={`${team.team} Kit`}
                        style={{ width: '25px', marginRight: '5px' }}
                      />
                    )}
                    {team.team === 'Bayern München' ? (
  <Link to="/bayernmünchen" style={{ textDecoration: 'none', color: 'white' }}>
     München
  </Link>
) : team.team === 'Bayer Leverkusen' ? (
  <Link to="/bayerleverkusen" style={{ textDecoration: 'none', color: 'white' }}>
     Leverkusen
  </Link>
) : team.team === 'SC Freiburg' ? (
  <Link to="/scfreiburg" style={{ textDecoration: 'none', color: 'white' }}>
    SC Freiburg
  </Link>
) : team.team === 'Eintracht Frankfurt' ? (
  <Link to="/eintrachtfrankfurt" style={{ textDecoration: 'none', color: 'white' }}>
     Frankfurt
  </Link>
) : team.team === 'Union Berlin' ? (
  <Link to="/unionberlin" style={{ textDecoration: 'none', color: 'white' }}>
    Union Berlin
  </Link>
) : team.team === 'RB Leipzig' ? (
  <Link to="/rbleipzig" style={{ textDecoration: 'none', color: 'white' }}>
    RB Leipzig
  </Link>
) : team.team === 'VfB Stuttgart' ? (
  <Link to="/vfbstuttgart" style={{ textDecoration: 'none', color: 'white' }}>
    VfB Stuttgart
  </Link>
) : team.team === 'Borussia Dortmund' ? (
  <Link to="/borussiadortmund" style={{ textDecoration: 'none', color: 'white' }}>
     Dortmund
  </Link>
) : team.team === '1. FC Heidenheim' ? (
  <Link to="/1.fcheidenheim" style={{ textDecoration: 'none', color: 'white' }}>
    Heidenheim
  </Link>
) : team.team === 'FSV Mainz 05' ? (
  <Link to="/fsvmainz05" style={{ textDecoration: 'none', color: 'white' }}>
    FSV Mainz 05
  </Link>
) : team.team === 'Werder Bremen' ? (
  <Link to="/werderbremen" style={{ textDecoration: 'none', color: 'white' }}>
    Werder Bremen
  </Link>
) : team.team === 'FC Augsburg' ? (
  <Link to="/fcaugsburg" style={{ textDecoration: 'none', color: 'white' }}>
    FC Augsburg
  </Link>
) : team.team === 'VfL Wolfsburg' ? (
  <Link to="/vflwolfsburg" style={{ textDecoration: 'none', color: 'white' }}>
    VfL Wolfsburg
  </Link>
) : team.team === 'Borussia Mönchengladbach' ? (
  <Link to="/borussiamönchengladbach" style={{ textDecoration: 'none', color: 'white' }}>
     Mönchenglad
  </Link>
) : team.team === '1899 Hoffenheim' ? (
  <Link to="/1899hoffenheim" style={{ textDecoration: 'none', color: 'white' }}>
     Hoffenheim
  </Link>
) : team.team === 'VfL Bochum' ? (
  <Link to="/vflbochum" style={{ textDecoration: 'none', color: 'white' }}>
    VfL Bochum
  </Link>
) : team.team === 'FC St. Pauli' ? (
  <Link to="/fcst.pauli" style={{ textDecoration: 'none', color: 'white' }}>
    FC St. Pauli
  </Link>
) : team.team === 'Holstein Kiel' ? (
  <Link to="/holsteinkiel" style={{ textDecoration: 'none', color: 'white' }}>
    Holstein Kiel
  </Link>
) : (
  team.team
)}

                  </td>
                  <td style={{ color: 'white' }}>{team.matchesPlayed}</td>
                  <td style={{ color: 'white' }}>{team.wins}</td>
                  <td style={{ color: 'white' }}>{team.draws}</td>
                  <td style={{ color: 'white' }}>{team.losses}</td>
                  <td style={{ color: 'white' }}>{team.points}</td>
                  <td className="d-none d-lg-table-cell">
                    <div className="d-flex flex-row">
                      {team.lastFive.map((result, idx) => (
                        <span
                          key={idx}
                          className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                          style={{
                            width: '23px',
                            height: '23px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.9rem',
                            marginRight: '2px'
                          }}
                        >
                          {result}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

    {/* Legends for qualifications */}
    <div className="container mt-3">
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-primary me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">UEFA Champions League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-warning me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-success me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa Conference League Qualification</div>
        </div>
        <div className="d-flex align-items-center" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-danger me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Relegation</div>
        </div>
      </div>
    </div>

    <div className='container mt-4' style={{ paddingBottom: '5px' }}>
      <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
    </div>
  </div>
  <Footer />
</div>


  );
}

export default BundesligaStandings;
