import React, { useState } from 'react';
import { auth } from './../firebase'; // Ensure this is the path to your Firebase configuration
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Footer from '../Footer';
import Navbar from '../Navbar';
import Image123 from '../LogoWhite3.png';
import Image1234 from '../LogoWhite4.png';

import '../App.css';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, get } from 'firebase/database';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// Function to sanitize the email for Firebase Realtime Database paths
const sanitizeEmail = (email) => {
  return email.replace(/[.#$[\]]/g, '_'); // Replace problematic characters with underscores
};

const LogIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [resend, setResend] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const checkIfUserBlocked = async (email) => {
    try {
      const db = getDatabase();
      const sanitizedEmail = sanitizeEmail(email); // Use the same sanitization as in blockUser
      const blockedEmailRef = ref(db, `blockedEmails/${sanitizedEmail}`);
      const snapshot = await get(blockedEmailRef);
  
      if (snapshot.exists() && snapshot.val().blocked) {
        // Email is blocked
        return true;
      } else {
        // Email is not blocked
        return false;
      }
    } catch (error) {
      console.error("Error checking block status:", error);
      return false;
    }
  };

  const loginUser = async (email, password) => {
    try {
      // Check if user is blocked
      const isBlocked = await checkIfUserBlocked(email);
      if (isBlocked) {
        console.log("User is blocked and cannot log in.");
        setError('Your account is currently blocked. Please try again later.');
        return;
      }
  
      // Proceed with authentication if not blocked
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      if (!user.emailVerified) {
        setError('Please verify your email before logging in.');
        setResend('Resend verification email');
        return;
      }
  
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      const snapshot = await get(usersRef);
  
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const currentUser = usersData[user.uid];
  
        if (currentUser && currentUser.blockedUntil && Date.now() < currentUser.blockedUntil) {
          setError('Your account is currently blocked. Please try again later.');
          return;
        }
      }
  
      // Navigate to the appropriate route on successful login
      const from = location.state?.from?.pathname || '/dashboard';
      navigate(from, { replace: true });
    } catch (error) {
      console.error("Error logging in:", error);
      setError('Invalid login credentials. Please check your email and password.');
    }
  };
  

  const signIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      // Check if the user is blocked before attempting to sign in
      const isBlocked = await checkIfUserBlocked(email);
      if (isBlocked) {
        setError('Your account is currently blocked. Please try again later.');
        setLoading(false);
        return;
      }
  
      // Proceed with authentication if not blocked
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Check if the email is verified
      if (!user.emailVerified) {
        setError('Please verify your email before logging in.');
        setResend('Resend verification email');
        setLoading(false);
        return;
      }
  
      // Get the users data from the database
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      const snapshot = await get(usersRef);
  
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const currentUser = usersData[user.uid];
  
        // Check if the user is blocked and if the block period is still active
        if (currentUser && currentUser.blockedUntil && Date.now() < currentUser.blockedUntil) {
          setError('Your account is currently blocked. Please try again later.');
          setLoading(false);
          return;
        }
      }
  
      // Redirect to the appropriate route on successful login
      const from = location.state?.from?.pathname || '/dashboard';
      navigate(from, { replace: true });
    } catch (error) {
      // Handle login errors such as invalid credentials
      setError('Invalid login credentials. Please check your email and password.');
      console.error('Error signing in:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const requestVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setError('Verification email sent. Please check your inbox.');
    } catch (error) {
      setError('Failed to send verification email.');
      console.error('Error sending verification email:', error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className='App-headerV8'>
        <section className="py-3 py-md-5 py-xl-8">
          <div className="container">
            <div className="row gy-4 align-items-center">
              <div className="col-12 col-md-6 col-xl-7">
                <div className="d-flex justify-content-center text-light">
                  <div className="col-12 col-xl-9">
                    <img className="img-fluid rounded mb-4" loading="lazy" src={Image1234} width="285" height="80" alt="login Logo" />
                    <hr className="border-primary-subtle mb-4" />
                    <h1 className="h1 mb-4"style={{ fontFamily: 'Lato, sans-serif' }}>Welcome to Predictify's Sports Predictor Game!</h1>
                    <p className="lead mb-5"style={{ fontFamily: 'Lato, sans-serif' }}>Test your sports knowledge and make predictions on your favorite events. It's free to play, so join now and see if you have what it takes to be the ultimate predictor!</p>
                    <div className="text-endx">
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-grip-horizontal" viewBox="0 0 16 16">
                        <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-5">
                <div className="card border-0 rounded-4">
                  <div className="card-body p-3 p-md-4 p-xl-5">
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-4">
                          <h2 className="h3">{t('login_title')}</h2>
                          <h3 className="fs-6 fw-normal text-secondary m-0">{t('login_description')}</h3>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={signIn}>
                      <div className="row gy-3 overflow-hidden">
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input 
                              type="email" 
                              className="form-control" 
                              name="email" 
                              id="email" 
                              placeholder="name@example.com" 
                              value={email}
                              onChange={(e) => setEmail(e.target.value)} 
                              required 
                            />
                            <label htmlFor="email" className="form-label">
                              Email <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-group mb-3">
                            <div className="form-floating">
                              <input
                                type={showPassword ? 'text' : 'password'}
                                className="form-control"
                                name="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                              <label htmlFor="password">Password <span className="text-danger">*</span></label>
                            </div>
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                        </div>
                        {error && (
                          <div className="alert alert-danger text-center" role="alert">
                            {error}
                            <br />
                            {resend && (
                              <button className="link-primary text-decoration-underline" onClick={requestVerificationEmail}>
                                {resend}
                              </button>
                            )}
                          </div>
                        )}
                        <div className="col-12">
                          <div className="d-grid">
                            <button className="btn btn-purple btn-lg" type="submit" disabled={loading}>
                              {loading ? 'Logging in...' : t('login_button')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-center mt-3">
                      <p className="m-0 text-secondary text-center">
                        {t('forgot_password')} 
                        <Link to='/passreset' className="link-primary text-decoration-none">
                          {t('password_reset')}
                        </Link>
                      </p>
                    </div>
                    <div className="col-12">
                      <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-center mt-3 mb-3">
                        <p className="m-0 text-secondary text-center">
                          {t('no_account')} 
                          <Link to='/signup' className="link-primary text-decoration-none"></Link>
                        </p>
                      </div>
                    </div>
                    <div className="text-center">
                      <Link to='/signup'>
                        <button className="btn btn-success btn-lg">Create Account</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default LogIn;
