import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Spinner } from 'react-bootstrap';

function Coaches() {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        const response = await fetch('http://192.168.1.3:3001/api/coaches');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        console.log('API Response:', responseData);
        // Check if data is an array before setting the state
        if (Array.isArray(responseData.data)) {
          setCoaches(responseData.data);
        } else {
          throw new Error('Data received is not an array');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching coaches:', error);
        setLoading(false);
      }
    };

    fetchCoaches();
  }, []);

  return (
    <div className='bg-dark'>
      <div className='App-headerV6'>
        <Navbar />
        <div className='container'>
        <h2 className='text-center text-light'>Scottish & Danish League Coaches</h2>
        <hr className="w-50 mx-auto mb-3 mb-xl-9 border-dark-subtle" />
        </div>
        <div className='container'>
          {loading ? (
            <h4 className='text-light text-center'><Spinner /></h4>
          ) : (
            <ul className="list-group text-center">
              {coaches.map((coaches, index) => (
    <div class="row ">
    <div class="col-sm-6 mx-auto" style={{marginBottom: '10px'}}>
      <div class="card ">
      <img src={coaches.image_path} className="card-img-top mx-auto" alt={coaches.name} style={{width: '100px'}} />

        <div class="card-body ">
          <h5 class="card-title">{coaches.name}</h5>
       

        </div>
      </div>
    </div>

  </div>
                
              ))}
            </ul>
            
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Coaches;
