import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'
import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { MdSportsSoccer } from "react-icons/md";
import { SiPremierleague } from "react-icons/si";
import { LiaGlobeEuropeSolid } from "react-icons/lia";


function Home() {

  const [authUser, setAuthUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [viewMore, setViewMore] = useState(false); // State to toggle view more

    const navigate = useNavigate()
    const handleClick = () => {
        alert('This should be a cool alert')
    }
    const notify = () => toast("Wow so easy!");

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };
  
    const cards = [
      {
        title: 'English Premier League',
        description: t('epl'),
        linkState: 'English Premier League',
        learnMore: '/epllearnmore',
      },
      {
        title: 'Scottish Premiership',
        description: 'The top 12 football clubs in Scotland make up the illustrious Scottish Premiership. Will Celtic defend their crown in the 24/25 season?',
        linkState: 'Scottish Premiership',
        learnMore: '/spfllearnmore',
      },
      {
        title: 'English Championship',
        description: 'With the English Championship\'s fierce competition, will Burnley hold their ground and secure promotion in the 24/25 season, or will a new contender emerge?',
        linkState: 'Championship',
        learnMore: '/championshiplearnmore',
      },
      {
        title: 'La Liga',
        description: 'With La Liga\'s fierce competition, will Atlético Madrid hold their ground and secure Champions League qualification in the 24/25 season, or will a new contender emerge to disrupt the usual top-four race?',
        linkState: 'La Liga',
        learnMore: '/laligalearnmore',
      },
      {
        title: 'Champions League',
        description: 'With the intense competition in the UEFA Champions League 24/25 season, will the traditional powerhouses maintain their dominance, or will a new contender emerge to disrupt the favorites and make a surprising run for European glory?',
        linkState: 'UCL',
        learnMore: '/ucllearnmore',
      },
      {
        title: 'Bundesliga',
        description: 'With the intense competition in the Bundesliga 24/25 season, will the traditional giants like Bayern Munich and Borussia Dortmund maintain their dominance, or will a new contender emerge to challenge the favorites and make a surprising run for the title?',
        linkState: 'Bundesliga',
        learnMore: '/bundesligalearnmore',
      },
      {
        title: 'Serie A',
        description: 'With the intense competition in the Serie A 24/25 season, will traditional powerhouses like Juventus and Inter Milan maintain their dominance, or will a new contender rise to challenge the favorites and make a surprising run for the Scudetto?',
        linkState: 'Serie A',
        learnMore: '/seriealearnmore',
      },
      {
        title: 'Premier Soccer League',
        description: 'With the intense competition in the South African Premier Soccer League (PSL) 24/25 season, will traditional powerhouses like Mamelodi Sundowns and Kaizer Chiefs maintain their dominance, or will a new contender rise to challenge the favorites and make a surprising run for the title?',
        linkState: 'SASoccer',
        learnMore: '/sasoccerlearnmore',
      },
    ];
  
    // Toggle view more/less cards
    const handleViewMore = () => {
      setViewMore(!viewMore);
    };

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setAuthUser(user);
        } else {
          setAuthUser(null);
        }
      });
  
      return () => unsubscribe();
    }, []);
  
    const userSignOut = () => {
      signOut(auth).then(() => {
        console.log('signed out');
        navigate('/login');
      }).catch(error => console.log(error));
    };
  

    return (
      <div>
      <Navbar />
      <div className='App-headerV8'>
        <section className="bg-transparent py-3 py-md-5 py-xl-8">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <h2 className="mb-4 display-5 text-center">Predictify</h2>
                <p className="text-secondary mb-2 text-center lead fs-4">Welcome to Predictify's Sports Predictor Game!</p>
                {authUser ? (
                  <div>
                    {authUser.emailVerified ? null : null}
                  </div>
                ) : (
                  <p className="text-secondary mb-5 text-center lead fs-4 d-flex justify-content-center">
                    <span className="text-secondary mb-2 lead fs-4 homehover" style={{marginRight: '5px'}}>
                      <Link className='homehover' to='/signup' style={{textDecoration: 'none', color: 'green'}}> Sign Up </Link>
                    </span>
                    now to join in the fun!
                  </p>
                )}
                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
              </div>
            </div>
          </div>

          {/* Display Cards */}
          <div className="container">
            <div className="row gy-3 gy-md-4">
              {/* Conditionally render 6 cards or all cards based on `viewMore` */}
              {cards.slice(0, viewMore ? cards.length : 4).map((card, index) => (
                <div className="col-12 col-md-6 col-lg-6" key={index}>
                  <div className="card border-dark">
                    <div className="card-body text-center p-4 p-xxl-5">
                      <MdSportsSoccer size={58} style={{color: 'purple'}} className='mb-4' />
                      <h4 className="mb-4">{card.title}</h4>
                      <p className="mb-4 text-secondary">{card.description}</p>
                      <Link to='/joinhuddle' state={{ tournament: card.linkState }}>
                        <span className="btn bg-danger text-light ">{t('playnow')}</span>
                      </Link>
                      <br />
                      <Link to={card.learnMore}>
                        <span className="btn text-dark">{t('learnmore')}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* View More/Less Button */}
          <div className="container text-center mt-4">
            <button className="btn btn-secondary" onClick={handleViewMore}>
              {viewMore ? 'View Less' : 'View More'}
            </button>
          </div>
        </section>
      </div>
      <Footer />
    </div>
    )
}

export default Home;
