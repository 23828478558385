import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Germany from '../EURO-2024-Team-Kits/Germany.png'
import Scotland from '../EURO-2024-Team-Kits/Scotland.png'
import Hungary from '../EURO-2024-Team-Kits/Hungary.png'
import Switzerland from '../EURO-2024-Team-Kits/Switzerland.png'
import Spain from '../EURO-2024-Team-Kits/Spain.png'
import Croatia from '../EURO-2024-Team-Kits/Croatia.png'
import Italy from '../EURO-2024-Team-Kits/Italy.png'
import Albania from '../EURO-2024-Team-Kits/Albania.png'
import Slovenia from '../EURO-2024-Team-Kits/Slovenia.png'
import Denmark from '../EURO-2024-Team-Kits/Denmark.png'
import Serbia from '../EURO-2024-Team-Kits/Serbia.png'
import England from '../EURO-2024-Team-Kits/England.png'
import Netherlands from '../EURO-2024-Team-Kits/Netherlands.png'
import France from '../EURO-2024-Team-Kits/France.png'
import Poland from '../EURO-2024-Team-Kits/Poland.png'
import Austria from '../EURO-2024-Team-Kits/Austria.png'
import Ukraine from '../EURO-2024-Team-Kits/Ukraine.png'
import Slovakia from '../EURO-2024-Team-Kits/Slovakia.png'
import Belgium from '../EURO-2024-Team-Kits/Belgium.png'
import Romania from '../EURO-2024-Team-Kits/Romania.png'
import Portugal from '../EURO-2024-Team-Kits/Portugal.png'
import Czechia from '../EURO-2024-Team-Kits/Czechia.png'
import Georgia from '../EURO-2024-Team-Kits/Georgia.png'
import Turkey from '../EURO-2024-Team-Kits/Turkey.png'
import { useTranslation } from 'react-i18next';


function Euro2024Fixtures() {
  const [fixtures, setFixtures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const { t } = useTranslation();

  const [dateRanges] = useState([
    { start: '2024-05-15', end: '2024-06-19' },
    { start: '2024-06-19', end: '2024-06-23' },
    { start: '2024-06-23', end: '2024-06-27' },
    { start: '2024-06-27', end: '2024-07-03'},
    { start: '2024-07-03', end: '2024-07-07'},
    { start: '2024-07-07', end: '2024-07-11'},
    { start: '2024-07-11', end: '2024-08-25'},




  ]);

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  useEffect(() => {
    // Fetch fixtures data
    fetchFixtures();
  }, []); // Empty dependency array to fetch data only once

  useEffect(() => {
    // Determine the current matchday based on the current date
    const currentDate = new Date();
    let currentMatchday = 1;
    for (let i = 0; i < dateRanges.length; i++) {
      const startDate = new Date(dateRanges[i].start);
      const endDate = new Date(dateRanges[i].end);
      if (currentDate >= startDate && currentDate <= endDate) {
        currentMatchday = i + 1;
        break;
      }
    }
    setCurrentPage(currentMatchday);
  }, [dateRanges]); // Re-run effect when dateRanges change

  useEffect(() => {
    // Filter fixtures based on current page (matchday)
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    // Sort filtered fixtures by date
    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    // Set filtered fixtures data
    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]); // Re-run effect when currentPage, fixtures, or dateRanges change

  const fetchFixtures = () => {
    fetch('https://iga-sport.azurewebsites.net/api/league/4/fixtures/season/2024', {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    })
      .then(response => response.json())
      .then(data => {
        // Set fixtures data
        setFixtures(data);
      })
      .catch(error => {
        console.error('Error fetching fixtures:', error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
    <div className=''>
    <h2 className="text-dark text-center padtop">{t('euro2024_fixtures')}</h2>
    <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
        <div className="dropdown d-inline-block" style={{paddingRight: ''}}>
          <button style={{marginRight: '2rem', marginBottom: '5px'}} className="btn btn-purple dropdown-toggle" type="button" id="groupStageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
            Group Stage Games
          </button>
          <ul className="dropdown-menu" aria-labelledby="groupStageDropdown">
            {dateRanges.slice(0, 3).map((range, index) => (
              <li key={index}>
                <button className="dropdown-item" onClick={() => handlePageChange(index + 1)}>
                  Matchday {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
        
        <div className="dropdown d-inline-block">
          <button style={{marginRight: '2rem' , marginBottom: '5px'}} className="btn btn-purple dropdown-toggle" type="button" id="knockoutStageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
            Knockout Stage
          </button>
          <ul className="dropdown-menu" aria-labelledby="knockoutStageDropdown">
            <li>
              <button className="dropdown-item" onClick={() => handlePageChange(4)}>
                Round of 16
              </button>
            </li>
            <li>
              <button className="dropdown-item" onClick={() => handlePageChange(5)}>
                Quarter-Finals
              </button>
            </li>
            <li>
              <button className="dropdown-item" onClick={() => handlePageChange(6)}>
                Semi-Finals
              </button>
            </li>
            <li>
              <button className="dropdown-item" onClick={() => handlePageChange(7)}>
                Final
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          {filteredFixtures.map(fixture => (
            <div key={fixture.fixture.id} className="col-lg-12 mx-auto mb-3">
              <div className="card" style={{ backgroundColor: '#fff' }}>
                                <b className="text-center padtop mb-3">{fixture.teams.home.name} vs {fixture.teams.away.name} </b>

                <div className="row">
                  <div className="col text-center">
{/* Display kit of home team */} 
{' '}
                                                        {fixture.teams.home.name === 'Germany' ? (
                                                            <>
                                                              <div className='Arsenal' ><img src={Germany} alt="Germany" style={{ width: '75px', height: 'auto' }} /></div>  
                                                            </>
                                                        ) : fixture.teams.home.name === 'Scotland' ? (
                                                            <>
                                                                <img src={Scotland} alt="Scotland" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) : fixture.teams.home.name === 'Hungary' ? (
                                                            <>
                                                                <img src={Hungary} alt="Hungary" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) :
                                                            fixture.teams.home.name === 'Switzerland' ? (
                                                                <>
                                                                    <img src={Switzerland} alt="Switzerland" style={{ width: '75px', height: 'auto' }} />
                                                                </>
                                                            ) :
                                                                fixture.teams.home.name === 'Spain' ? (
                                                                    <>
                                                                        <img src={Spain} alt="Spain" style={{ width: '75px', height: 'auto' }} />
                                                                    </>
                                                                ) :
                                                                    fixture.teams.home.name === 'Croatia' ? (
                                                                        <>
                                                                            <img src={Croatia} alt="Croatia" style={{ width: '75px', height: 'auto' }} />
                                                                        </>
                                                                    ) :
                                                                        fixture.teams.home.name === 'Italy' ? (
                                                                            <>
                                                                                <img src={Italy} alt="Italy" style={{ width: '75px', height: 'auto' }} />
                                                                            </>
                                                                        ) :
                                                                            fixture.teams.home.name === 'Albania' ? (
                                                                                <>
                                                                                    <img src={Albania} alt="Albania" style={{ width: '75px', height: 'auto' }} />
                                                                                </>
                                                                            ) :
                                                                                fixture.teams.home.name === 'Slovenia' ? (
                                                                                    <>
                                                                                        <img src={Slovenia} alt="Slovenia" style={{ width: '75px', height: 'auto' }} />
                                                                                    </>
                                                                                ) :
                                                                                    fixture.teams.home.name === 'Denmark' ? (
                                                                                        <>
                                                                                            <img src={Denmark} alt="Denmark" style={{ width: '75px', height: 'auto' }} />
                                                                                        </>
                                                                                    ) :
                                                                                        fixture.teams.home.name === 'Serbia' ? (
                                                                                            <>
                                                                                                <img src={Serbia} alt="Serbia" style={{ width: '75px', height: 'auto' }} />
                                                                                            </>
                                                                                        ) :
                                                                                            fixture.teams.home.name === 'England' ? (
                                                                                                <>
                                                                                                    <img src={England} alt="England" style={{ width: '75px', height: 'auto' }} />
                                                                                                </>
                                                                                            ) :
                                                                                                fixture.teams.home.name === 'Netherlands' ? (
                                                                                                    <>
                                                                                                        <img src={Netherlands} alt="Netherlands" style={{ width: '75px', height: 'auto' }} />
                                                                                                    </>
                                                                                                ) :
                                                                                                    fixture.teams.home.name === 'France' ? (
                                                                                                        <>
                                                                                                            <img src={France} alt="France" style={{ width: '75px', height: 'auto' }} />
                                                                                                        </>
                                                                                                    ) :
                                                                                                        fixture.teams.home.name === 'Poland' ? (
                                                                                                            <>
                                                                                                                <img src={Poland} alt="Poland" style={{ width: '75px', height: 'auto' }} />
                                                                                                            </>
                                                                                                        ) :
                                                                                                            fixture.teams.home.name === 'Austria' ? (
                                                                                                                <>
                                                                                                                    <img src={Austria} alt="Austria" style={{ width: '75px', height: 'auto' }} />
                                                                                                                </>
                                                                                                            ) :
                                                                                                                fixture.teams.home.name === 'Ukraine' ? (
                                                                                                                    <>
                                                                                                                        <img src={Ukraine} alt="Ukraine" style={{ width: '75px', height: 'auto' }} />
                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    fixture.teams.home.name === 'Slovakia' ? (
                                                                                                                        <>
                                                                                                                            <img src={Slovakia} alt="Slovakia" style={{ width: '75px', height: 'auto' }} />
                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        fixture.teams.home.name === 'Belgium' ? (
                                                                                                                            <>
                                                                                                                                <img src={Belgium} alt="Belgium" style={{ width: '75px', height: 'auto' }} />
                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            fixture.teams.home.name === 'Romania' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Romania} alt="Romania" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.home.name === 'Portugal' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Portugal} alt="Portugal" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.home.name === 'Czech Republic' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Czechia} alt="Czechia" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.home.name === 'Georgia' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Georgia} alt="Georgia" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.home.name === 'Türkiye' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Turkey} alt="Türkiye" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.home.name}
                                                                                                                                </span>
                                                                                                                            )} 
                                        {/* Display kit of Home team */}                  </div>
                  <div className="col padtop1 ">
                    <input
                      className="form-control text-center fs-3 border-secondary"
                      style={{ backgroundColor: '#fff' }}
                      value={fixture.fixture.status.short === 'FT' || fixture.fixture.status.short === 'AET' || fixture.fixture.status.short === 'PEN' ? fixture.goals.home : 'TBD'}
                      disabled
                    />
                  </div>
                  <div className="col padtop1">
                    <input
                      className="form-control text-center fs-3 border-secondary"
                      style={{ backgroundColor: '#fff' }}
                      value={fixture.fixture.status.short === 'FT' || fixture.fixture.status.short === 'AET' || fixture.fixture.status.short === 'PEN' ? fixture.goals.away : 'TBD'}
                      disabled
                    />
                  </div>
                  <div className="col text-center">
{/* Display kit of away team */} 
{' '}
                                                        {fixture.teams.away.name === 'Germany' ? (
                                                            <>
                                                              <div className='Arsenal' ><img src={Germany} alt="Germany" style={{ width: '75px', height: 'auto' }} /></div>  
                                                            </>
                                                        ) : fixture.teams.away.name === 'Scotland' ? (
                                                            <>
                                                                <img src={Scotland} alt="Scotland" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) : fixture.teams.away.name === 'Hungary' ? (
                                                            <>
                                                                <img src={Hungary} alt="Hungary" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) :
                                                            fixture.teams.away.name === 'Switzerland' ? (
                                                                <>
                                                                    <img src={Switzerland} alt="Switzerland" style={{ width: '75px', height: 'auto' }} />
                                                                </>
                                                            ) :
                                                                fixture.teams.away.name === 'Spain' ? (
                                                                    <>
                                                                        <img src={Spain} alt="Spain" style={{ width: '75px', height: 'auto' }} />
                                                                    </>
                                                                ) :
                                                                    fixture.teams.away.name === 'Croatia' ? (
                                                                        <>
                                                                            <img src={Croatia} alt="Croatia" style={{ width: '75px', height: 'auto' }} />
                                                                        </>
                                                                    ) :
                                                                        fixture.teams.away.name === 'Italy' ? (
                                                                            <>
                                                                                <img src={Italy} alt="Italy" style={{ width: '75px', height: 'auto' }} />
                                                                            </>
                                                                        ) :
                                                                            fixture.teams.away.name === 'Albania' ? (
                                                                                <>
                                                                                    <img src={Albania} alt="Albania" style={{ width: '75px', height: 'auto' }} />
                                                                                </>
                                                                            ) :
                                                                                fixture.teams.away.name === 'Slovenia' ? (
                                                                                    <>
                                                                                        <img src={Slovenia} alt="Slovenia" style={{ width: '75px', height: 'auto' }} />
                                                                                    </>
                                                                                ) :
                                                                                    fixture.teams.away.name === 'Denmark' ? (
                                                                                        <>
                                                                                            <img src={Denmark} alt="Denmark" style={{ width: '75px', height: 'auto' }} />
                                                                                        </>
                                                                                    ) :
                                                                                        fixture.teams.away.name === 'Serbia' ? (
                                                                                            <>
                                                                                                <img src={Serbia} alt="Serbia" style={{ width: '75px', height: 'auto' }} />
                                                                                            </>
                                                                                        ) :
                                                                                            fixture.teams.away.name === 'England' ? (
                                                                                                <>
                                                                                                    <img src={England} alt="England" style={{ width: '75px', height: 'auto' }} />
                                                                                                </>
                                                                                            ) :
                                                                                                fixture.teams.away.name === 'Netherlands' ? (
                                                                                                    <>
                                                                                                        <img src={Netherlands} alt="Netherlands" style={{ width: '75px', height: 'auto' }} />
                                                                                                    </>
                                                                                                ) :
                                                                                                    fixture.teams.away.name === 'France' ? (
                                                                                                        <>
                                                                                                            <img src={France} alt="France" style={{ width: '75px', height: 'auto' }} />
                                                                                                        </>
                                                                                                    ) :
                                                                                                        fixture.teams.away.name === 'Poland' ? (
                                                                                                            <>
                                                                                                                <img src={Poland} alt="Poland" style={{ width: '75px', height: 'auto' }} />
                                                                                                            </>
                                                                                                        ) :
                                                                                                            fixture.teams.away.name === 'Austria' ? (
                                                                                                                <>
                                                                                                                    <img src={Austria} alt="Austria" style={{ width: '75px', height: 'auto' }} />
                                                                                                                </>
                                                                                                            ) :
                                                                                                                fixture.teams.away.name === 'Ukraine' ? (
                                                                                                                    <>
                                                                                                                        <img src={Ukraine} alt="Ukraine" style={{ width: '75px', height: 'auto' }} />
                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    fixture.teams.away.name === 'Slovakia' ? (
                                                                                                                        <>
                                                                                                                            <img src={Slovakia} alt="Slovakia" style={{ width: '75px', height: 'auto' }} />
                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        fixture.teams.away.name === 'Belgium' ? (
                                                                                                                            <>
                                                                                                                                <img src={Belgium} alt="Belgium" style={{ width: '75px', height: 'auto' }} />
                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            fixture.teams.away.name === 'Romania' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Romania} alt="Romania" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.away.name === 'Portugal' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Portugal} alt="Portugal" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.away.name === 'Czech Republic' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Czechia} alt="Czechia" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.away.name === 'Georgia' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Georgia} alt="Georgia" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.away.name === 'Türkiye' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Turkey} alt="Türkiye" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.away.name}
                                                                                                                                </span>
                                                                                                                            )} 
                                        {/* Display kit of away team */}                   </div>
                </div>
                <div className="row">
                  <div className="col text-center">
    {/* Display home team Initials */}

    {' '}
                                                        {fixture.teams.home.name === 'Albania' ? (
                                                            <>
                                                                <h5>ALB </h5>
                                                            </>
                                                        ) : fixture.teams.home.name === 'Austria' ? (
                                                            <>
                                                                <h5>AUS </h5>
                                                            </>
                                                        ) : fixture.teams.home.name === 'Belgium' ? (
                                                            <>
                                                                <h5>BEL </h5>
                                                            </>
                                                        ) :
                                                            fixture.teams.home.name === 'Croatia' ? (
                                                                <>
                                                                    <h5>CRO</h5>
                                                                </>
                                                            ) :
                                                                fixture.teams.home.name === 'Czech Republic' ? (
                                                                    <>
                                                                        <h5>CZE</h5>

                                                                    </>
                                                                ) :
                                                                    fixture.teams.home.name === 'Denmark' ? (
                                                                        <>
                                                                            <h5>DEN </h5>

                                                                        </>
                                                                    ) :
                                                                        fixture.teams.home.name === 'England' ? (
                                                                            <>
                                                                                <h5>ENG </h5>

                                                                            </>
                                                                        ) :
                                                                            fixture.teams.home.name === 'France' ? (
                                                                                <>
                                                                                    <h5>FRA</h5>

                                                                                </>
                                                                            ) :
                                                                                fixture.teams.home.name === 'Georgia' ? (
                                                                                    <>
                                                                                        <h5>GEO </h5>
                                                                                    </>
                                                                                ) :
                                                                                    fixture.teams.home.name === 'Germany' ? (
                                                                                        <>
                                                                                            <h5>GER </h5>

                                                                                        </>
                                                                                    ) :
                                                                                        fixture.teams.home.name === 'Hungary' ? (
                                                                                            <>
                                                                                                <h5>HUN</h5>

                                                                                            </>
                                                                                        ) :
                                                                                            fixture.teams.home.name === 'Italy' ? (
                                                                                                <>
                                                                                                    <h5>ITA</h5>

                                                                                                </>
                                                                                            ) :
                                                                                                fixture.teams.home.name === 'Netherlands' ? (
                                                                                                    <>

                                                                                                        <h5>NET</h5>

                                                                                                    </>
                                                                                                ) :
                                                                                                    fixture.teams.home.name === 'Poland' ? (
                                                                                                        <>
                                                                                                            <h5>POL</h5>

                                                                                                        </>
                                                                                                    ) :
                                                                                                        fixture.teams.home.name === 'Portugal' ? (
                                                                                                            <>
                                                                                                                <h5>POR </h5>

                                                                                                            </>
                                                                                                        ) :
                                                                                                            fixture.teams.home.name === 'Romania' ? (
                                                                                                                <>
                                                                                                                    <h5>ROM </h5>

                                                                                                                </>
                                                                                                            ) :
                                                                                                                fixture.teams.home.name === 'Scotland' ? (
                                                                                                                    <>
                                                                                                                        <h5>SCO</h5>

                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    fixture.teams.home.name === 'Serbia' ? (
                                                                                                                        <>
                                                                                                                            <h5>SER</h5>

                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        fixture.teams.home.name === 'Slovakia' ? (
                                                                                                                            <>
                                                                                                                                <h5>SVK</h5>

                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            fixture.teams.home.name === 'Slovenia' ? (
                                                                                                                                <>
                                                                                                                                    <h5>SVN</h5>

                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.home.name === 'Slovakia' ? (
                                                                                                                              <>
                                                                                                                                  <h5>SVK</h5>
  
                                                                                                                              </>
                                                                                                                          ) :
                                                                                                                          fixture.teams.home.name === 'Spain' ? (
                                                                                                                            <>
                                                                                                                                <h5>ESP</h5>

                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                        fixture.teams.home.name === 'Switzerland' ? (
                                                                                                                          <>
                                                                                                                              <h5>SWT</h5>

                                                                                                                          </>
                                                                                                                      ) :
                                                                                                                      fixture.teams.home.name === 'Türkiye' ? (
                                                                                                                        <>
                                                                                                                            <h5>TUR</h5>

                                                                                                                        </>
                                                                                                                    ) : 
                                                                                                                    fixture.teams.home.name === 'Ukraine' ? (
                                                                                                                      <>
                                                                                                                          <h5>UKR</h5>

                                                                                                                      </>
                                                                                                                  ) :(
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.home.name}
                                                                                                                                </span>
                                                                                                                            )}    
                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display home team Initials */}                    </div>
                  <div className="col text-center"></div>
                  <div className="col text-center"></div>
                  <div className="col text-center">
       {/* Display away team Initials */}

       {' '}
                                                        {fixture.teams.away.name === 'Albania' ? (
                                                            <>
                                                                <h5>ALB </h5>
                                                            </>
                                                        ) : fixture.teams.away.name === 'Austria' ? (
                                                            <>
                                                                <h5>AUS </h5>
                                                            </>
                                                        ) : fixture.teams.away.name === 'Belgium' ? (
                                                            <>
                                                                <h5>BEL </h5>
                                                            </>
                                                        ) :
                                                            fixture.teams.away.name === 'Croatia' ? (
                                                                <>
                                                                    <h5>CRO</h5>
                                                                </>
                                                            ) :
                                                                fixture.teams.away.name === 'Czech Republic' ? (
                                                                    <>
                                                                        <h5>CZE</h5>

                                                                    </>
                                                                ) :
                                                                    fixture.teams.away.name === 'Denmark' ? (
                                                                        <>
                                                                            <h5>DEN </h5>

                                                                        </>
                                                                    ) :
                                                                        fixture.teams.away.name === 'England' ? (
                                                                            <>
                                                                                <h5>ENG </h5>

                                                                            </>
                                                                        ) :
                                                                            fixture.teams.away.name === 'France' ? (
                                                                                <>
                                                                                    <h5>FRA</h5>

                                                                                </>
                                                                            ) :
                                                                                fixture.teams.away.name === 'Georgia' ? (
                                                                                    <>
                                                                                        <h5>GEO </h5>
                                                                                    </>
                                                                                ) :
                                                                                    fixture.teams.away.name === 'Germany' ? (
                                                                                        <>
                                                                                            <h5>GER </h5>

                                                                                        </>
                                                                                    ) :
                                                                                        fixture.teams.away.name === 'Hungary' ? (
                                                                                            <>
                                                                                                <h5>HUN</h5>

                                                                                            </>
                                                                                        ) :
                                                                                            fixture.teams.away.name === 'Italy' ? (
                                                                                                <>
                                                                                                    <h5>ITA</h5>

                                                                                                </>
                                                                                            ) :
                                                                                                fixture.teams.away.name === 'Netherlands' ? (
                                                                                                    <>

                                                                                                        <h5>NET</h5>

                                                                                                    </>
                                                                                                ) :
                                                                                                    fixture.teams.away.name === 'Poland' ? (
                                                                                                        <>
                                                                                                            <h5>POL</h5>

                                                                                                        </>
                                                                                                    ) :
                                                                                                        fixture.teams.away.name === 'Portugal' ? (
                                                                                                            <>
                                                                                                                <h5>POR </h5>

                                                                                                            </>
                                                                                                        ) :
                                                                                                            fixture.teams.away.name === 'Romania' ? (
                                                                                                                <>
                                                                                                                    <h5>ROM </h5>

                                                                                                                </>
                                                                                                            ) :
                                                                                                                fixture.teams.away.name === 'Scotland' ? (
                                                                                                                    <>
                                                                                                                        <h5>SCO</h5>

                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    fixture.teams.away.name === 'Serbia' ? (
                                                                                                                        <>
                                                                                                                            <h5>SER</h5>

                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        fixture.teams.away.name === 'Slovakia' ? (
                                                                                                                            <>
                                                                                                                                <h5>SVK</h5>

                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            fixture.teams.away.name === 'Slovenia' ? (
                                                                                                                                <>
                                                                                                                                    <h5>SVN</h5>

                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.away.name === 'Slovakia' ? (
                                                                                                                              <>
                                                                                                                                  <h5>SVK</h5>
  
                                                                                                                              </>
                                                                                                                          ) :
                                                                                                                          fixture.teams.away.name === 'Spain' ? (
                                                                                                                            <>
                                                                                                                                <h5>ESP</h5>

                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                        fixture.teams.away.name === 'Switzerland' ? (
                                                                                                                          <>
                                                                                                                              <h5>SWT</h5>

                                                                                                                          </>
                                                                                                                      ) :
                                                                                                                      fixture.teams.away.name === 'Türkiye' ? (
                                                                                                                        <>
                                                                                                                            <h5>TUR</h5>

                                                                                                                        </>
                                                                                                                    ) : 
                                                                                                                    fixture.teams.away.name === 'Ukraine' ? (
                                                                                                                      <>
                                                                                                                          <h5>UKR</h5>

                                                                                                                      </>
                                                                                                                  ) :(
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.away.name}
                                                                                                                                </span>
                                                                                                                            )}    
                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display away team Initials */}                    </div>
                </div>
<h5 className='text-center'>{fixture.fixture.status.short === 'PEN' ?  `Penalties (${fixture.score.penalty.home} - ${fixture.goals.away}) `  : '' } </h5>
                <p className="text-center"> {convertToFormattedDateTime(fixture.fixture.date)}</p>
                {fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'FT' && fixture.fixture.status.short !== 'AET' && fixture.fixture.status.short !== 'PEN' && (
  <p className="text-center text-success padtop">Live - {fixture.fixture.status.elapsed}'</p>
)}

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  );
}

export default Euro2024Fixtures;
