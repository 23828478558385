import React, {useState, useEffect} from 'react'
import Arsenal from '../Team-Kits-Images/Arsenal.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Bournemouth from '../Team-Kits-Images/Bournemouth.png'
import Brentford from '../Team-Kits-Images/Brentford.png'
import Brighton from '../Team-Kits-Images/Brighton.png'
import Burnley from '../Team-Kits-Images/Burnley.png'
import Chelsea from '../Team-Kits-Images/Chelsea.png'
import CrystalPalace from '../Team-Kits-Images/CrystalPalace.png'
import Everton from '../Team-Kits-Images/Everton.png'
import Fulham from '../Team-Kits-Images/Fulham.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import Luton from '../Team-Kits-Images/LutonTown.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import ManUnited from '../Team-Kits-Images/Man.United.png'
import Newcastle from '../Team-Kits-Images/Newcastle.png'
import NottinghamForest from '../Team-Kits-Images/NottinghamForest.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Tottenham from '../Team-Kits-Images/Tottenham.png'
import WestHam from '../Team-Kits-Images/WestHam.png'
import Wolves from '../Team-Kits-Images/Wolves.png'

function HomeTeamFixtures() {

    const [teamStats, setTeamStats] = useState(null);
    const [loading, setLoading] = useState(true)
    const [nextFixture, setNextFixture] = useState(null);


    useEffect(() => {
        const fetchTeamStats = async () => {
          try {
            const response = await fetch('https://iga-sport.azurewebsites.net/api//league-teams-stats/39/season/2023', {
              headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
              }
            });
            if (!response.ok) {
              throw new Error('Failed to fetch team stats');
            }
            const data = await response.json();
            const arsenalData = data.find(team => team.id === 42);
            if (arsenalData) {
              setTeamStats(arsenalData);
            } else {
              console.log('Arsenal data not found');
            }
          } catch (error) {
            console.error('Error fetching team stats:', error);
          }
        };
    
        const fetchNextFixture = async () => {
          try {
            const response = await fetch('https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2023',{
            headers: {
              'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
            }
          })
            if (!response.ok) {
              throw new Error('Failed to fetch fixtures');
            }
            const fixturesData = await response.json();
            console.log('All fixtures:', fixturesData);
            
            const arsenalFixtures = fixturesData.filter(fixture => {
              return fixture.teams.home.id === 42 || fixture.teams.away.id === 42;
            });
            console.log('Arsenal fixtures:', arsenalFixtures);
        
    // Sort the fixtures by date in ascending order
    arsenalFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
    // Find the first upcoming fixture
    const upcomingFixture = arsenalFixtures.find(fixture => {
        const fixtureDate = new Date(fixture.fixture.date); // Convert fixture date string to Date object
        const currentDate = new Date(); // Get current date
        return fixtureDate > currentDate; // Return true if fixture date is in the future
    });
    
    // Display upcoming fixture on the front end
    if (upcomingFixture) {
        console.log(`Upcoming Fixture: ${upcomingFixture.fixture.date}`);
        // You can display other fixture details as needed
    } else {
        console.log("No upcoming fixtures found.");
    }
    
    
            setNextFixture(upcomingFixture);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching fixtures:', error);
          }
        };
    
        
    
        fetchTeamStats();
        fetchNextFixture();
      }, []);
    
      const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };

  return (
    <div>
          <div className="col text-center"> 
                                        {/* Display kit of home team */}
                                        {' '}
                                                        {nextFixture.teams.home.name === 'Arsenal' ? (
                                                            <>
                                                              <div className='Arsenal' ><img src={Arsenal} alt="Arsenal" style={{ width: '75px', height: 'auto' }} /></div>  
                                                            </>
                                                        ) : nextFixture.teams.home.name === 'Aston Villa' ? (
                                                            <>
                                                                <img src={AstonVilla} alt="Aston Villa" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) : nextFixture.teams.home.name === 'Bournemouth' ? (
                                                            <>
                                                                <img src={Bournemouth} alt="Bournemouth" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) :
                                                            nextFixture.teams.home.name === 'Brentford' ? (
                                                                <>
                                                                    <img src={Brentford} alt="Brentford" style={{ width: '75px', height: 'auto' }} />
                                                                </>
                                                            ) :
                                                                nextFixture.teams.home.name === 'Brighton' ? (
                                                                    <>
                                                                        <img src={Brighton} alt="Brighton" style={{ width: '75px', height: 'auto' }} />

                                                                    </>
                                                                ) :
                                                                    nextFixture.teams.home.name === 'Burnley' ? (
                                                                        <>
                                                                            <img src={Burnley} alt="Burnley" style={{ width: '75px', height: 'auto' }} />

                                                                        </>
                                                                    ) :
                                                                        nextFixture.teams.home.name === 'Chelsea' ? (
                                                                            <>
                                                                                <img src={Chelsea} alt="Chelsea" style={{ width: '75px', height: 'auto' }} />

                                                                            </>
                                                                        ) :
                                                                            nextFixture.teams.home.name === 'Crystal Palace' ? (
                                                                                <>
                                                                                    <img src={CrystalPalace} alt="Crystal Palace" style={{ width: '75px', height: 'auto' }} />

                                                                                </>
                                                                            ) :
                                                                                nextFixture.teams.home.name === 'Everton' ? (
                                                                                    <>
                                                                                        <img src={Everton} alt="Everton" style={{ width: '75px', height: 'auto' }} />
                                                                                    </>
                                                                                ) :
                                                                                    nextFixture.teams.home.name === 'Fulham' ? (
                                                                                        <>
                                                                                            <img src={Fulham} alt="Fulham" style={{ width: '75px', height: 'auto' }} />

                                                                                        </>
                                                                                    ) :
                                                                                        nextFixture.teams.home.name === 'Liverpool' ? (
                                                                                            <>
                                                                                                <img src={Liverpool} alt="Liverpool" style={{ width: '75px', height: 'auto' }} />

                                                                                            </>
                                                                                        ) :
                                                                                            nextFixture.teams.home.name === 'Luton' ? (
                                                                                                <>
                                                                                                    <img src={Luton} alt="Luton" style={{ width: '75px', height: 'auto' }} />

                                                                                                </>
                                                                                            ) :
                                                                                                nextFixture.teams.home.name === 'Manchester City' ? (
                                                                                                    <>

                                                                                                        <img src={ManCity} alt="Man City" style={{ width: '75px', height: 'auto' }} />

                                                                                                    </>
                                                                                                ) :
                                                                                                    nextFixture.teams.home.name === 'Manchester United' ? (
                                                                                                        <>
                                                                                                            <img src={ManUnited} alt="Man United" style={{ width: '75px', height: 'auto' }} />

                                                                                                        </>
                                                                                                    ) :
                                                                                                        nextFixture.teams.home.name === 'Newcastle' ? (
                                                                                                            <>
                                                                                                                <img src={Newcastle} alt="Newcastle" style={{ width: '75px', height: 'auto' }} />

                                                                                                            </>
                                                                                                        ) :
                                                                                                            nextFixture.teams.home.name === 'Nottingham Forest' ? (
                                                                                                                <>
                                                                                                                    <img src={NottinghamForest} alt="Nottingham Forest" style={{ width: '75px', height: 'auto' }} />

                                                                                                                </>
                                                                                                            ) :
                                                                                                                nextFixture.teams.home.name === 'Sheffield Utd' ? (
                                                                                                                    <>
                                                                                                                        <img src={SheffieldUnited} alt="Sheffield Utd" style={{ width: '75px', height: 'auto' }} />

                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    nextFixture.teams.home.name === 'Tottenham' ? (
                                                                                                                        <>
                                                                                                                            <img src={Tottenham} alt="Tottenham" style={{ width: '75px', height: 'auto' }} />

                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        nextFixture.teams.home.name === 'West Ham' ? (
                                                                                                                            <>
                                                                                                                                <img src={WestHam} alt="West Ham" style={{ width: '75px', height: 'auto' }} />

                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            nextFixture.teams.home.name === 'Wolves' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Wolves} alt="Wolves" style={{ width: '75px', height: 'auto' }} />

                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {nextFixture.teams.home.name}
                                                                                                                                </span>
                                                                                                                            )}  
                                        {/* Display kit of home team */}
                                        </div>
    </div>
  )
}

export default HomeTeamFixtures