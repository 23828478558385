import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Barcelona from '../Team-Kits-Images/Barcelona.png'
import Milan from '../Team-Kits-Images/Milan.png'
import Arsenal from '../Team-Kits-Images/Arsenal.png'
import Monaco from '../Team-Kits-Images/Monaco.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Atalanta from '../Team-Kits-Images/Atalanta.png'
import Athleti from '../Team-Kits-Images/Athleti.png'
import Leverkusen from '../Team-Kits-Images/Leverkusen.png'
import BayernMunich from '../Team-Kits-Images/BayernMunich.png'
import Benfica from '../Team-Kits-Images/Benfica.png'
import Bologna from '../Team-Kits-Images/Bologna.png'
import Dortmund from '../Team-Kits-Images/Dortmund.png'
import Brest from '../Team-Kits-Images/Brest.png'
import Celtic from '../Team-Kits-Images/Celtic.png'
import Brugge from '../Team-Kits-Images/Brugge.png'
import Zagreb from '../Team-Kits-Images/Zagreb.png'
import Feyenoord from '../Team-Kits-Images/Feyenoord.png'
import Girona from '../Team-Kits-Images/Girona.png'
import Inter from '../Team-Kits-Images/Inter.png'
import Juventus from '../Team-Kits-Images/Juventus.png'
import Lille from '../Team-Kits-Images/Lille.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import PSG from '../Team-Kits-Images/PSG.png'
import PSV from '../Team-Kits-Images/PSV.png'
import Leipzig from '../Team-Kits-Images/Leipzig.png'
import Madrid from '../Team-Kits-Images/Madrid.png'
import Belgrade from '../Team-Kits-Images/Belgrade.png'
import Salzburg from '../Team-Kits-Images/Salzburg.png'
import Shakhtar from '../Team-Kits-Images/Shakhtar.png'
import Bratislava from '../Team-Kits-Images/Bratislava.png'
import Praha from '../Team-Kits-Images/Prague.png'
import Sporting from '../Team-Kits-Images/Sporting.png'
import Graz from '../Team-Kits-Images/Graz.png'
import Stuttgart from '../Team-Kits-Images/Stuttgart.png'
import YoungBoys from '../Team-Kits-Images/YoungBoys.png'

function UclStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const [teamFixtures, setTeamFixtures] = useState({ finished: [], notStarted: [] }); // Initialize with empty arrays
  const navigate = useNavigate();

  const uclTeams = [
    'Bayern München', 'Aston Villa', 'Liverpool', 'Juventus', 'Atletico Madrid', 'Real Madrid', 'Sporting CP',
    'Arsenal', 'Atalanta', 'Barcelona', 'Bayer Leverkusen', 'Benfica', 'Bologna', 'Borussia Dortmund', 
    'Stade Brestois 29', 'Celtic', 'Club Brugge KV', 'Feyenoord', 'Girona', 'Inter', 'Manchester City', 
    'Monaco', 'Paris Saint Germain', 'RB Leipzig', 'FK Crvena Zvezda', 'Red Bull Salzburg', 'Shakhtar Donetsk',
    'Slovan Bratislava', 'Sparta Praha', 'Sturm Graz', 'PSV Eindhoven', 'VfB Stuttgart', 'AC Milan', 'Lille',
    'BSC Young Boys', 'Dinamo Zagreb'
  ];

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/2/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);

    // Fetch and display fixtures for Aston Villa
    const astonVillaFixtures = data.filter(fixture => 
      (fixture.teams.home.name === 'Aston Villa' || fixture.teams.away.name === 'Aston Villa')
    );

    // Separate finished and not started fixtures
    const finishedFixtures = astonVillaFixtures.filter(fixture => fixture.fixture.status.short === 'FT');
    const notStartedFixtures = astonVillaFixtures.filter(fixture => fixture.fixture.status.short === 'NS');

    // Filter fixtures for those played after September 16, 2024
    const cutoffDate = new Date('2024-09-16');
    const relevantFinishedFixtures = finishedFixtures.filter(fixture => new Date(fixture.fixture.date) > cutoffDate);

    setTeamFixtures({
      finished: relevantFinishedFixtures,
      notStarted: notStartedFixtures
    });

    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
    const cutoffDate = new Date('2024-09-16'); // Cutoff date
  
    // Initialize standingsMap with UCL teams and 0 points
    uclTeams.forEach((team) => {
      standingsMap[team] = {
        points: 0,
        wins: 0,
        draws: 0,
        losses: 0,
        matchesPlayed: 0,
        lastFive: [] // Initialize lastFive as an empty array
      };
    });
  
    // Calculate points and results for each concluded fixture after the cutoff date
    fixtures.forEach((fixture) => {
      const fixtureDate = new Date(fixture.fixture.date);
      if (fixtureDate <= cutoffDate || fixture.fixture.status.short !== 'FT') {
        return; // Skip fixtures before or on the cutoff date and non-concluded fixtures
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      // Skip teams that are not in the UCL
      if (!uclTeams.includes(homeTeam) && !uclTeams.includes(awayTeam)) {
        return;
      }
  
      // Determine match result
      let homePoints = 0;
      let awayPoints = 0;
      let homeResult = '';
      let awayResult = '';
  
      if (homeGoals > awayGoals) {
        homePoints = 3;
        homeResult = 'W';
        awayResult = 'L';
        if (uclTeams.includes(homeTeam)) standingsMap[homeTeam].wins += 1;
        if (uclTeams.includes(awayTeam)) standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        awayPoints = 3;
        homeResult = 'L';
        awayResult = 'W';
        if (uclTeams.includes(homeTeam)) standingsMap[homeTeam].losses += 1;
        if (uclTeams.includes(awayTeam)) standingsMap[awayTeam].wins += 1;
      } else {
        homePoints = 1;
        awayPoints = 1;
        homeResult = 'D';
        awayResult = 'D';
        if (uclTeams.includes(homeTeam)) standingsMap[homeTeam].draws += 1;
        if (uclTeams.includes(awayTeam)) standingsMap[awayTeam].draws += 1;
      }
  
      // Update standings for UCL teams only
      if (uclTeams.includes(homeTeam)) {
        standingsMap[homeTeam].points += homePoints;
        standingsMap[homeTeam].matchesPlayed += 1;
        standingsMap[homeTeam].lastFive.push(homeResult);
        if (standingsMap[homeTeam].lastFive.length > 5) {
          standingsMap[homeTeam].lastFive.shift(); // Keep only the last 5 results
        }
      }
      if (uclTeams.includes(awayTeam)) {
        standingsMap[awayTeam].points += awayPoints;
        standingsMap[awayTeam].matchesPlayed += 1;
        standingsMap[awayTeam].lastFive.push(awayResult);
        if (standingsMap[awayTeam].lastFive.length > 5) {
          standingsMap[awayTeam].lastFive.shift(); // Keep only the last 5 results
        }
      }
    });
  
    // Convert standings map to array and sort by points
    const standingsArray = Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  
    return standingsArray;
  };
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  const teamKits = {
    'Bayern München': BayernMunich,
    'Aston Villa': AstonVilla,
    'Liverpool': Liverpool,
    'Juventus': Juventus,
    'Atletico Madrid': Athleti,
    'Real Madrid': Madrid,
    'Sporting CP': Sporting,
    'Arsenal': Arsenal,
    'Atalanta': Atalanta,
    'Barcelona': Barcelona,
    'Bayer Leverkusen': Leverkusen,
    'Benfica': Benfica,
    'Bologna': Bologna,
    'Borussia Dortmund': Dortmund,
    'Stade Brestois 29': Brest,
    'Celtic': Celtic,
    'Club Brugge KV': Brugge,
    'Feyenoord': Feyenoord,
    'Girona': Girona,
    'Inter': Inter,
    'Manchester City': ManCity,
    'Monaco': Monaco,
    'Paris Saint Germain': PSG,
    'RB Leipzig': Leipzig,
    'FK Crvena Zvezda': Belgrade,
    'Red Bull Salzburg': Salzburg,
    'Shakhtar Donetsk': Shakhtar,
    'Slovan Bratislava': Bratislava,
    'Sparta Praha': Praha,
    'Sturm Graz': Graz,
    'PSV Eindhoven': PSV,
    'VfB Stuttgart': Stuttgart,
    'AC Milan': Milan,
    'Lille': Lille,
    'BSC Young Boys': YoungBoys,
    'Dinamo Zagreb': Zagreb,
};

// Updated getTeamKit function using the teamKits object
const getTeamKit = (team) => {
    return teamKits[team] || null;
};



  return (
    <div className=''>
    <div className='App-headerV8'>
      <Navbar />
      <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>UCL Standings</h2>
      <div className='container text-center mb-2'>
      </div>
  
      <div className='d-flex justify-content-center'>
        <div className='col-10 col-md-6 col-lg-4'>
          <select
            className='form-select mb-3 text-center'
            style={{ backgroundColor: 'purple', color: 'white' }}
            value={season}
            onChange={(e) => setSeason(e.target.value)}
          >
            <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>UCL 24/25</option>
   
          </select>
        </div>
      </div>
  
      <div className='container'>
        {loading ? (
          <h2 className='text-center text-dark'><Spinner /></h2>
        ) : (
          <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
            <table
              className="table transparent-table"
              style={{
                borderRadius: '15px',
                overflow: 'hidden',
              }}
            >
              <thead>
                <tr>
                  <th scope="col" style={{ width: '5%', color: 'white' }}>#</th>
                  <th scope="col" className="team-column text-light">Team</th>
                  <th scope="col" style={{ width: '5%', color: 'white' }}>MP</th>
                  <th scope="col" style={{ width: '5%', color: 'white' }}>W</th>
                  <th scope="col" style={{ width: '5%', color: 'white' }}>D</th>
                  <th scope="col" style={{ width: '5%', color: 'white' }}>L</th>
                  <th scope="col" style={{ width: '5%', color: 'white' }}>Pts</th>
                  <th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>Last 5</th>
                </tr>
              </thead>
              <tbody>
                {standings.map((team, index) => (
                  <tr key={index}>
               <th scope="row" className={
            index < 8 ? 'text-primary' : // Positions 1-8: Blue
            index >= 8 && index < 24 ? 'text-warning' : // Positions 9-24: Yellow
            'text-danger' // Positions 25 and below: Red
          }>
                        {index + 1}
                      </th>
                    <td style={{ color: 'white' }}>
                      {getTeamKit(team.team) && (
                        <img
                          src={getTeamKit(team.team)}
                          alt={`${team.team} Kit`}
                          style={{ width: '25px', marginRight: '5px' }}
                        />
                      )}
                      {team.team === 'Bayern München' ? (
  <Link style={{ textDecoration: 'none', color: 'white' }}>
    Bayern
  </Link>
) : team.team === 'Aston Villa' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Aston Villa
  </Link>
) : team.team === 'Liverpool' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Liverpool
  </Link>
) : team.team === 'Juventus' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Juventus
  </Link>
) : team.team === 'Atletico Madrid' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Atletico 
  </Link>
) : team.team === 'Real Madrid' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Real Madrid
  </Link>
) : team.team === 'Sporting CP' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Sporting CP
  </Link>
) : team.team === 'Arsenal' ? (
  <Link to="/arsenal" style={{ textDecoration: 'none', color: 'white' }}>
    Arsenal
  </Link>
) : team.team === 'Atalanta' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Atalanta
  </Link>
) : team.team === 'Barcelona' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Barcelona
  </Link>
) : team.team === 'Bayer Leverkusen' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
     Leverkusen
  </Link>
) : team.team === 'Benfica' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Benfica
  </Link>
) : team.team === 'Bologna' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Bologna
  </Link>
) : team.team === 'Borussia Dortmund' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
     Dortmund
  </Link>
) : team.team === 'Stade Brestois 29' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
     Brestois 
  </Link>
) : team.team === 'Celtic' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Celtic
  </Link>
) : team.team === 'Club Brugge KV' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
     Brugge 
  </Link>
) : team.team === 'Feyenoord' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Feyenoord
  </Link>
) : team.team === 'Girona' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Girona
  </Link>
) : team.team === 'Inter' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Inter
  </Link>
) : team.team === 'Manchester City' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Man City
  </Link>
) : team.team === 'Monaco' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Monaco
  </Link>
) : team.team === 'Paris Saint Germain' ? (
  <Link style={{ textDecoration: 'none', color: 'white' }}>
    PSG
  </Link>
) : team.team === 'RB Leipzig' ? (
  <Link style={{ textDecoration: 'none', color: 'white' }}>
    RB Leipzig
  </Link>
) : team.team === 'FK Crvena Zvezda' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
     C. Zvezda
  </Link>
) : team.team === 'Red Bull Salzburg' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
   Salzburg
  </Link>
) : team.team === 'Shakhtar Donetsk' ? (
  <Link style={{ textDecoration: 'none', color: 'white' }}>
     Donetsk
  </Link>
) : team.team === 'Slovan Bratislava' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Slovan 
  </Link>
) : team.team === 'Sparta Praha' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Sparta Praha
  </Link>
) : team.team === 'Sturm Graz' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Sturm Graz
  </Link>
) : team.team === 'PSV Eindhoven' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    PSV 
  </Link>
) : team.team === 'VfB Stuttgart' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
     Stuttgart
  </Link>
) : team.team === 'AC Milan' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    AC Milan
  </Link>
) : team.team === 'Lille' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
    Lille
  </Link>
) : team.team === 'BSC Young Boys' ? (
  <Link  style={{ textDecoration: 'none', color: 'white' }}>
     Young Boys
  </Link>
) : team.team === 'Dinamo Zagreb' ? (
  <Link style={{ textDecoration: 'none', color: 'white' }}>
     Zagreb
  </Link>
) : (
  team.team
)}
                    </td>
                    <td style={{ color: 'white' }}>{team.matchesPlayed}</td>
                    <td style={{ color: 'white' }}>{team.wins}</td>
                    <td style={{ color: 'white' }}>{team.draws}</td>
                    <td style={{ color: 'white' }}>{team.losses}</td>
                    <td style={{ color: 'white' }}>{team.points}</td>
                    <td className="d-none d-lg-table-cell">
                      <div className="d-flex flex-row">
                        {team.lastFive.map((result, idx) => (
                          <span
                            key={idx}
                            className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                            style={{
                              width: '23px',
                              height: '23px',
                              display: 'inline-flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '0.9rem',
                              marginRight: '2px'
                            }}
                          >
                            {result}
                          </span>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
  
      {/* Legends for qualifications */}
      <div className="container mt-3">
        <div className="d-flex flex-column align-items-center">
        <div className='d-flex justify-content-center'>
                <div className='text-center text-primary'>| </div>
                <div className='text-light'>Position 1 - 8 : 8th Finals</div>
              </div>
              <div className='d-flex justify-content-center'>
                <div className='text-center text-warning'>| </div>
                <div className='text-light'>Position 9 - 24 : Play-off</div>
              </div>
              <div className='d-flex justify-content-center'>
                <div className='text-center text-danger'>| </div>
                <div className='text-light'>Position 25 - 36 : Out</div>
              </div>
        </div>
      </div>
  
      <div className='container mt-4' style={{ paddingBottom: '5px' }}>
        <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default UclStandings;
