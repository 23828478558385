import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Sundowns from '../Team-Kits-Images/Sundowns.png'
import Pirates from '../Team-Kits-Images/Pirates.png'
import Arrows from '../Team-Kits-Images/Arrows.png'
import Polokwane from '../Team-Kits-Images/Polokwane.png'
import Richards from '../Team-Kits-Images/Richards.png'
import Kaizer from '../Team-Kits-Images/Kaizer.png'
import Sekh from '../Team-Kits-Images/Sekh.png'
import Chippa from '../Team-Kits-Images/Chippa.png'
import Royal from '../Team-Kits-Images/Royal.png'
import Cape from '../Team-Kits-Images/Cape.png'
import Supersport from '../Team-Kits-Images/Supersport.png'
import Amazulu from '../Team-Kits-Images/Amazulu.png'
import Galaxy from '../Team-Kits-Images/Galaxy.png'
import Stell from '../Team-Kits-Images/Stell.png'
import Magesi from '../Team-Kits-Images/Magesi.png'
import Marumo from '../Team-Kits-Images/Margumo.png'


import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'

function SASoccerStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/288/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
      standingsMap[fixture.teams.away.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points += homeResult === "W" ? 3 : homeResult === "D" ? 1 : 0;
      standingsMap[awayTeam].points += awayResult === "W" ? 3 : awayResult === "D" ? 1 : 0;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
  
      // Update lastFive results (most recent on the right)
      standingsMap[homeTeam].lastFive = [...standingsMap[homeTeam].lastFive, homeResult].slice(-5);
      standingsMap[awayTeam].lastFive = [...standingsMap[awayTeam].lastFive, awayResult].slice(-5);
    });
  
    // Convert standings map to array
    return Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  };
  
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
      case 'Mamelodi Sundowns':
        return Sundowns;
      case 'Orlando Pirates':
        return Pirates;
      case 'Golden Arrows':
        return Arrows;
      case 'Polokwane City':
        return Polokwane;
      case 'Richards Bay':
        return Richards;
      case 'Kaizer Chiefs':
        return Kaizer;
      case 'Sekhukhune United':
        return Sekh;
      case 'Chippa United':
        return Chippa;
      case 'Royal AM':
        return Royal;
      case 'Cape Town City':
        return Cape;
      case 'Supersport United':
        return Supersport;
      case 'Amazulu':
        return Amazulu;
      case 'TS Galaxy':
        return Galaxy;
      case 'Stellenbosch':
        return Stell;
      case 'Magesi':
        return Magesi;
      case 'Marumo Gallants':
        return Marumo;
      default:
        return null;
    }
  };
  


  return (
<div className=''>
  <div className='App-headerV8'>
    <Navbar />
    <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>SA Soccer Standings</h2>
    <div className='container text-center mb-2'>
      <i className='text-center text-light' style={{ fontFamily: 'Lato, sans-serif' }}>*Click on team name to view stats </i>
    </div>

    <div className='d-flex justify-content-center'>
      <div className='col-10 col-md-6 col-lg-4'>
        <select
          className='form-select mb-3 text-center'
          style={{ backgroundColor: 'purple', color: 'white' }}
          value={season}
          onChange={(e) => setSeason(e.target.value)}
        >
          <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>SA Soccer 24/25</option>
          <option value="2023" style={{ backgroundColor: 'white', color: 'black' }}>SA Soccer 23/24</option>
          <option value="2022" style={{ backgroundColor: 'white', color: 'black' }}>SA Soccer 22/23</option>
          <option value="2021" style={{ backgroundColor: 'white', color: 'black' }}>SA Soccer 21/22</option>
          <option value="2020" style={{ backgroundColor: 'white', color: 'black' }}>SA Soccer 20/21</option>
        </select>
      </div>
    </div>

    <div className='container'>
      {loading ? (
        <h2 className='text-center text-dark'><Spinner /></h2>
      ) : (
        <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
          <table
            className="table transparent-table"
            style={{
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ width: '5%', color: 'white' }}>#</th>
                <th scope="col" className="team-column text-light">Team</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>MP</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>W</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>D</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>L</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>Pts</th>
                <th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>Last 5</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
                    <th scope="row" className={index < 2 ? 'text-primary' : index >= standings.length - 1 ? 'text-danger' : index == 2 ? 'text-warning' :  index == 14 ? 'text-orange'  : ''} style={index === 14 ? { color: 'orange' } : { color: 'white' }}>{index + 1}</th>


                  <td style={{ color: 'white' }}>
                    {getTeamKit(team.team) && (
                      <img
                        src={getTeamKit(team.team)}
                        alt={`${team.team} Kit`}
                        style={{ width: '25px', marginRight: '5px' }}
                      />
                    )}
                  {team.team === 'Mamelodi Sundowns' ? (
  <Link to="/mamelodisundowns" style={{ textDecoration: 'none', color: 'white' }}>
    Sundowns
  </Link>
) : team.team === 'Orlando Pirates' ? (
  <Link to="/orlandopirates" style={{ textDecoration: 'none', color: 'white' }}>
    Orlando Pirates
  </Link>
) : team.team === 'Golden Arrows' ? (
  <Link to="/goldenarrows" style={{ textDecoration: 'none', color: 'white' }}>
    Golden Arrows
  </Link>
) : team.team === 'Polokwane City' ? (
  <Link to="/polokwanecity" style={{ textDecoration: 'none', color: 'white' }}>
    Polokwane City
  </Link>
) : team.team === 'Richards Bay' ? (
  <Link to="/richardsbay" style={{ textDecoration: 'none', color: 'white' }}>
    Richards Bay
  </Link>
) : team.team === 'Kaizer Chiefs' ? (
  <Link to="/kaizerchiefs" style={{ textDecoration: 'none', color: 'white' }}>
    Kaizer Chiefs
  </Link>
) : team.team === 'Sekhukhune United' ? (
  <Link to="/sekhukhuneunited" style={{ textDecoration: 'none', color: 'white' }}>
    Sekhukhune 
  </Link>
) : team.team === 'Chippa United' ? (
  <Link to="/chippaunited" style={{ textDecoration: 'none', color: 'white' }}>
    Chippa 
  </Link>
) : team.team === 'Royal AM' ? (
  <Link to="/royalam" style={{ textDecoration: 'none', color: 'white' }}>
    Royal AM
  </Link>
) : team.team === 'Cape Town City' ? (
  <Link to="/capetowncity" style={{ textDecoration: 'none', color: 'white' }}>
    Cape Town 
  </Link>
) : team.team === 'Supersport United' ? (
  <Link to="/supersportunited" style={{ textDecoration: 'none', color: 'white' }}>
    Supersport 
  </Link>
) : team.team === 'AmaZulu' ? (
  <Link to="/amazulu" style={{ textDecoration: 'none', color: 'white' }}>
    AmaZulu
  </Link>
) : team.team === 'TS Galaxy' ? (
  <Link to="/tsgalaxy" style={{ textDecoration: 'none', color: 'white' }}>
    TS Galaxy
  </Link>
) : team.team === 'Stellenbosch' ? (
  <Link to="/stellenbosch" style={{ textDecoration: 'none', color: 'white' }}>
    Stellenbosch
  </Link>
) : team.team === 'Magesi' ? (
  <Link to="/magesi" style={{ textDecoration: 'none', color: 'white' }}>
    Magesi
  </Link>
) : team.team === 'Marumo Gallants' ? (
  <Link to="/marumogallants" style={{ textDecoration: 'none', color: 'white' }}>
    Marumo 
  </Link>
) : (
  team.team
)}

                  </td>
                  <td style={{ color: 'white' }}>{team.matchesPlayed}</td>
                  <td style={{ color: 'white' }}>{team.wins}</td>
                  <td style={{ color: 'white' }}>{team.draws}</td>
                  <td style={{ color: 'white' }}>{team.losses}</td>
                  <td style={{ color: 'white' }}>{team.points}</td>
                  <td className="d-none d-lg-table-cell">
                    <div className="d-flex flex-row">
                      {team.lastFive.map((result, idx) => (
                        <span
                          key={idx}
                          className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                          style={{
                            width: '23px',
                            height: '23px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.9rem',
                            marginRight: '2px'
                          }}
                        >
                          {result}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

    {/* Legends for qualifications */}
    <div className="container mt-3">
      <div className="d-flex flex-column align-items-center">
      <div className='d-flex justify-content-center'>
            <div className='text-center text-primary'>| </div>
            <div className='text-light'>CAF Champions League group stage</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-warning'>| </div>
            <div className='text-light'>CAF Confederation Cup group stage</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-orange' style={{color: 'orange'}}>| </div>
            <div className='text-light'>Relegation play-offs</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-danger'>| </div>
            <div className='text-light'>Relegation</div>
          </div>
      </div>
    </div>

    <div className='container mt-4' style={{ paddingBottom: '5px' }}>
      <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
    </div>
  </div>
  <Footer />
</div>


  );
}

export default SASoccerStandings;
