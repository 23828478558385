
import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { getDatabase, ref, push, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import { auth } from './firebase';
import { useTranslation } from 'react-i18next';


function Contact() {
  const contactMessagesRef = ref(getDatabase(), 'contactMessages');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const[ error, setError] = useState('')
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!auth.currentUser) {
      setError('You must be logged in')
    }
  
    // Ensure required fields are filled
    if (!name || !email || !message) {
      alert('Please enter your name, email and message.');
      return;
    }
  
    // Create a new message object
    const messageData = {
      name,
      email,
      subject,
      message,
      timestamp: new Date().toISOString(),
      userId: auth.currentUser.uid // Assuming auth is correctly set up and contains current user info
    };
  
    try {
      // Push the new message to the 'contactMessages' reference
      const newMessageRef = push(contactMessagesRef);
      await set(newMessageRef, messageData);
      // Clear input fields after submission
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      setSent(true);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email. (You have to be logged in to send a message)');
    } 

  };

  return (
    <div>
      <Navbar />
      <div className='App-headerV8'>
      <section className=" py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
            <h2 className="mb-4 display-5 text-center text-light"style={{ fontFamily: 'Lato, sans-serif' }}>{t('contact')}</h2>
<p className="mb-5 text-center text-light"style={{ fontFamily: 'Lato, sans-serif' }}>{t('contact_description')}</p>

              <hr className="w-50 mx-auto  mb-xl-9 border-light-subtle" />
            </div>
          </div>
        </div>
        
      <div className='container col-lg-6'>
                <div className="card bg-light outline-dark border border-dark" >
  <div className="card-header text-center bg-light">
<MdEmail size={25} style={{color: 'purple'}} />
  <ul className=" list-group list-group-flush text-center">
    <li className=" bg-light list-group-item">                <a href="mailto:support@predictify.app">Send us an email </a></li>
  </ul>
  </div>

</div>
<ul></ul>
                </div>

                </section>

                </div>
      <Footer />
    </div>
  );
}

export default Contact;
