import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';

function Weather() {
  const [prediction, setPrediction] = useState('');
  const [message, setMessage] = useState('');

  // Function to send the prediction to Firebase without checking immediately
  const sendPredictionToFirebase = async (predictedTemp) => {
    const db = getDatabase();
    const predictionsRef = ref(db, 'WeatherPredictions');

    const newPrediction = {
      predictedTemp,
      timestamp: Date.now(),   // Store the current timestamp
      verified: false,         // Prediction has not been checked yet
      userId: 'user123',        // Replace this with actual user ID
    };

    await push(predictionsRef, newPrediction);
  };

  const handlePredictionSubmit = async (e) => {
    e.preventDefault();
    const predictedTemp = parseFloat(prediction);

    if (isNaN(predictedTemp)) {
      setMessage('Please enter a valid number');
      return;
    }

    // Send prediction to Firebase (without checking it immediately)
    await sendPredictionToFirebase(predictedTemp);

    setMessage(`Prediction of ${predictedTemp}° submitted!`);
    setPrediction('');
  };

  return (
    <div>
      <h2>Weather Prediction</h2>
      <form onSubmit={handlePredictionSubmit}>
        <label>Predict the temperature:</label>
        <input 
          type="number"
          value={prediction}
          onChange={(e) => setPrediction(e.target.value)}
          placeholder="Enter temperature"
          required
        />
        <button type="submit">Submit Prediction</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default Weather;
