import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { getDatabase, ref, push, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { uploadBytes, getDownloadURL, getStorage, ref as storageRef } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Sidebar from './Sidebar';

function CreateArticle() {
  const CreateBracketsRef = ref(getDatabase(), 'CreateArticle');
  const [title, setTitle] = useState('');
  const [brackets, setBrackets] = useState([]);
  const [userId, setUserUid] = useState('');
  const [description, setDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [date, setDate] = useState('');
  const [casino, setCasino] = useState('Predictify');
  const [purpose, setPurpose] = useState('');
  const [sent, setSent] = useState(false);
  const [image, setImage] = useState(null); // Ensure initial state is null
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState(false);
  const storage = getStorage(auth.app);
  const [participantsCount, setParticipantsCount] = useState(1);
  const [value, setValue] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const notify = (message) => {
    toast.success(message, {
      // autoClose: 5000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the user is authenticated

    // Check if any required fields are empty
    if (!title || !description || !image || !purpose) {
      alert('Please fill out all required fields.');
      return;
    }

    // Proceed with bracket creation
    try {
      // Upload image to Firebase Storage
      const imageRef = storageRef(storage, `ArticleImages/${image.name}`);
      await uploadBytes(imageRef, image);

      // Get the download URL of the uploaded image
      const downloadURL = await getDownloadURL(imageRef);
      setImageUrl(downloadURL);

      // Create a new bracket object with image URL
      const bracketData = {
        title,
        image: downloadURL,
        description,
        longDescription,
        date,
        casino,
        purpose,
        participants: {
          [userId]: {
            displayName: auth.currentUser.displayName,
            email: auth.currentUser.email,
          },
        },
        participantsCount: 1, // Initialize participantsCount with 1
        timestamp: new Date().toISOString(),
        userId,
      };

      // Push the new bracket to the 'CreateBracket' reference
      const newBracketRef = push(CreateBracketsRef);
      await set(newBracketRef, bracketData);

      // Clear input fields after submission
      setTitle('');
      setDescription('');
      setLongDescription('');
      setDate('');
      setCasino('');
      setPurpose('');
      setImage(null);
      setParticipantsCount(1);
      setSent(true);

      // Show success message
      notify('Article created successfully!');
    } catch (error) {
      console.error('Error creating an Article:', error);
      alert('Error creating Article.');
    }
  };


  useEffect(() => {
    const fetchBrackets = async () => {};

    fetchBrackets();

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUid(user.uid); // Set user UID when authenticated
      } else {
        setUserUid(''); // Reset user UID if not authenticated
      }
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  const changeHandler = (value) => {
    setValue(value);
  };

 

  return (
    <div>
      <Sidebar />
      <div className="App-headerV3">
        <section className="py-3 py-md-5">
          <div className="container">
            {/* Bracket creation form */}
              <section className="py-3 py-md-5">
                <div className="container">
                  <div className="row justify-content-md-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                      <h2 className="mb-4 display-5 text-center text-dark">
                        Create An Article
                      </h2>
                      <p
                        className="mb-5 text-center text-dark"
                        style={{ color: 'dark' }}
                      >
                        Please fill out all of the required fields below to
                        create your custom article.
                      </p>
                      <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row justify-content-lg-center">
                    <div className="col-12 col-lg-9">
                      <div className="bg-white border rounded shadow-sm overflow-hidden">
                        <form action="#!" onSubmit={handleSubmit}>
                          <div className="row gy-4 gy-xl-5 p-4 p-xl-5">
                            <div className="col-12 col-md-12">
                              <label
                                htmlFor="title"
                                className="form-label"
                              >
                                Title Of Article{' '}
                                <span className="text-danger"></span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  error && 'input-error'
                                }`}
                                id="title"
                                name="title"
                                placeholder="Max 25 Characters"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                // maxLength={25}
                                required
                              />
                            </div>

                            <div className="col-12 col-md-6">
                              <label
                                htmlFor="bracketImage"
                                className="form-label"
                              >
                                Article Image
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="bracketImage"
                                accept="image/*"
                                onChange={(e) =>
                                  setImage(e.target.files[0])
                                }
                              />
                            </div>

                            <div className="col-12 col-md-6">
                              <label
                                htmlFor="Purpose"
                                className="form-label"
                              >
                                Hyperlink
                              </label>
                              <div className="input-group">
                                <span className="input-group-text"></span>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Purpose"
                                  placeholder="https://example.com"
                                  value={purpose}
                                  onChange={(e) =>
                                    setPurpose(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
  <label htmlFor="tournament" className="form-label">
    Casino
  </label>
  <div className="input-group">
    <span className="input-group-text">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-trophy"
        viewBox="0 0 16 16"
      >
        <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5q0 .807-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33 33 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935m10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1q.01.775.056 1.469c.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.5.5 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667q.045-.694.056-1.469z"/>
      </svg>
    </span>
    <select
      className="form-select"
      id="csaino"
      name="casino"
      value={casino}
      onChange={(e) => setCasino(e.target.value)}
      required
    >
      <option value="Predictify">Predictify </option>
      <option value="BETPROUD">BETPROUD</option>

    </select>
  </div>
</div>
<div className="col-12 col-md-6">
  <label htmlFor="Date" className="form-label">Date</label>
  <div className="input-group">
    <span className="input-group-text">
    </span>
    <input
      type="text"
      className="form-control"
      id="Date"
      name="Date"
      maxLength={25}
      value={date}
      onChange={(e) => setDate(e.target.value)}
      required
    />
  </div>
</div>

                            <div className="col-12">
                              <label
                                htmlFor="description"
                                className="form-label"
                              >
                                Short Description
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                placeholder="Max 50 Characters"
                                rows="5"
                                value={description}
                                onChange={(e) =>
                                  setDescription(e.target.value)
                                }
                                required
                              ></textarea>
                            </div>

                            <div className="col-12">
                              <label
                                htmlFor="description"
                                className="form-label"
                              >
                                Long Description
                              </label>
                              <ReactQuill
                                value={longDescription}
                                onChange={setLongDescription}
                                theme="snow"
                              />
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-auto">
                              <button
                                type="submit"
                                className="btn btn-purple mb-3"
                              >
                                Create Article
                              </button>
                            </div>
                          </div>
                        </form>
                  
                      </div>
                    </div>
                  </div>
                </div>
              </section>
          </div>
        </section>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateArticle;
