import React, { useState } from 'react';
import { auth } from './../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import Navbar from '../Navbar';

const LoginToJoinBracket = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        navigate('/invitedtobracket');
      })
      .catch((error) => {
        setError('Invalid login credentials. Please check your email and password.');
        console.error(error);
      });
  };

  return (
    <div style={{backgroundColor: '#222427'}}>
      <Navbar />
<div className='App-headerV3'>
      <section className="bg-dark py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-md-6 col-xl-7">
              <div className="d-flex justify-content-center text-bg-dark">
                <div className="col-12 col-xl-9">
                  <img className="img-fluid rounded mb-4" loading="lazy" src="https://lirp.cdn-website.com/fc5e77e4/dms3rep/multi/opt/cropped-1200x400_Horizontal_Transparent-201w.png" width="285" height="80" alt="login Logo" />
                  <hr className="border-primary-subtle mb-4" />
                  <h1 className="h1 mb-4"> Predictify offers FREE daily contests!</h1>
                  <p className="lead mb-5">Predictify is your chance to win some major cash prizes!</p>
                  <div className="text-endx">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-grip-horizontal" viewBox="0 0 16 16">
                      <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-5">
              <div className="card border-0 rounded-4">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-4">
                        <h2 className="h3">Log In</h2>
                        <h3 className="fs-6 fw-normal text-secondary m-0">Enter your details to log in</h3>
                      </div>
                    </div>
                  </div>
                  <form action="#!" onSubmit={signIn}>
                    <div className="row gy-3 overflow-hidden">
                      <div className="col-12">
                        <div className="form-floating mb-3">
                          <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" value={email}
                            onChange={(e) => setEmail(e.target.value)} required />
                          <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-12">
              <div className="input-group mb-3">
                <div className="form-floating">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label htmlFor="password">Password <span className="text-danger">*</span></label>
                </div>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </div>



                      {error && (
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      )}
                      <div className="col-12">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" name="iAgree" id="iAgree" required defaultChecked />
                          <label className="form-check-label text-secondary" htmlFor="iAgree">
                            I agree to the <a href="#!" className="link-primary text-decoration-none">terms and conditions</a>
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-grid">
                          <button className="btn btn-primary btn-lg" type="submit">Log in</button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="col-12">
  <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-center mt-3">
    <p className="m-0 text-secondary text-center">Don't have an account? <Link to='/signup' href="#!" className="link-primary text-decoration-none">Sign up</Link></p>
  </div>
  <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-center mt-3">
    <p className="m-0 text-secondary text-center">Forgot password? <Link to='/passreset' href="#!" className="link-primary text-decoration-none">Password reset</Link></p>
  </div>
</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
      <Footer />
    </div>
  );
};

export default LoginToJoinBracket;




