import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';


function WorldCup2022() {
    const [fixtures, setFixtures] = useState([]);
    const [groups, setGroups] = useState([]);
    const [showKnockout, setShowKnockout] = useState(false);
    const [loading, setLoading ] = useState(true)

    useEffect(() => {
        const apiKey = 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg==';
        const requestOptions = {
            headers: {
                'x-functions-key': apiKey
            }
        };

        fetch('https://iga-sport.azurewebsites.net/api/league/1/fixtures/season/2022', requestOptions)
            .then(response => response.json())
            .then(data => setFixtures(data))
            .catch(error => console.error('Error fetching fixtures:', error));
    }, []);

    useEffect(() => {
        if (fixtures.length > 0) {
            const processedGroups = {};

            const teamsByGroup = {
                A: ['Senegal', 'Netherlands', 'Qatar', 'Ecuador'],
                B: ['England', 'Iran', 'USA', 'Wales'],
                C: ['Argentina', 'Saudi Arabia', 'Poland', 'Mexico'],
                D: ['France', 'Australia', 'Denmark', 'Tunisia'],
                E: ['Spain', 'Costa Rica', 'Germany', 'Japan'],
                F: ['Belgium', 'Canada', 'Morocco', 'Croatia'],
                G: ['Brazil', 'Serbia', 'Switzerland', 'Cameroon'],
                H: ['Portugal', 'Ghana', 'Uruguay', 'South Korea'],
            };

            for (let i = 0; i < 8; i++) {
                const groupName = String.fromCharCode(65 + i);
                processedGroups[groupName] = {
                    teams: teamsByGroup[groupName],
                    points: Array(4).fill(0),
                    played: Array(4).fill(0),
                };
            }

            fixtures.forEach(fixture => {
                const homeTeam = fixture.teams.home.name;
                const awayTeam = fixture.teams.away.name;
                const homeGoals = fixture.goals.home;
                const awayGoals = fixture.goals.away;

                for (const group of Object.keys(processedGroups)) {
                    const groupTeams = processedGroups[group].teams;
                    const homeIndex = groupTeams.indexOf(homeTeam);
                    const awayIndex = groupTeams.indexOf(awayTeam);

                    if (homeIndex !== -1 && awayIndex !== -1) {
                        const homePlayed = processedGroups[group].played[homeIndex];
                        const awayPlayed = processedGroups[group].played[awayIndex];

                        if (homePlayed < 3 && awayPlayed < 3) {
                            if (homeGoals > awayGoals) {
                                processedGroups[group].points[homeIndex] += 3;
                            } else if (homeGoals === awayGoals) {
                                processedGroups[group].points[homeIndex] += 1;
                                processedGroups[group].points[awayIndex] += 1;
                            } else {
                                processedGroups[group].points[awayIndex] += 3;
                            }

                            processedGroups[group].played[homeIndex]++;
                            processedGroups[group].played[awayIndex]++;
                        }
                    }
                }
            });

            Object.keys(processedGroups).forEach(groupName => {
                processedGroups[groupName].teams.sort((a, b) => {
                    const pointsA = processedGroups[groupName].points[processedGroups[groupName].teams.indexOf(a)];
                    const pointsB = processedGroups[groupName].points[processedGroups[groupName].teams.indexOf(b)];
                    const GDA = calculateGD(processedGroups, groupName, a);
                    const GDB = calculateGD(processedGroups, groupName, b);

                    if (pointsA === pointsB) {
                        return GDB - GDA;
                    }

                    return pointsB - pointsA;
                });
            });

            const groupsArray = Object.entries(processedGroups).map(([groupName, data]) => ({
                groupName,
                teams: data.teams,
                played: data.played,
            }));

            setGroups(groupsArray);
        }
    }, [fixtures]);

    function calculatePoints(group, team) {
        const firstThreeGames = fixtures.filter(fixture => {
            const homeTeam = fixture.teams.home.name;
            const awayTeam = fixture.teams.away.name;
            return homeTeam === team || awayTeam === team;
        }).slice(0, 3);

        let wins = 0;
        let draws = 0;
        let losses = 0;
        let goalsFor = 0;
        let goalsAgainst = 0;

        firstThreeGames.forEach(fixture => {
            const homeTeam = fixture.teams.home.name;
            const awayTeam = fixture.teams.away.name;
            const homeGoals = fixture.goals.home;
            const awayGoals = fixture.goals.away;

            if (homeTeam === team) {
                goalsFor += homeGoals;
                goalsAgainst += awayGoals;
                if (homeGoals > awayGoals) {
                    wins++;
                } else if (homeGoals === awayGoals) {
                    draws++;
                } else {
                    losses++;
                }
            } else if (awayTeam === team) {
                goalsFor += awayGoals;
                goalsAgainst += homeGoals;
                if (awayGoals > homeGoals) {
                    wins++;
                } else if (awayGoals === homeGoals) {
                    draws++;
                } else {
                    losses++;
                }
            }
        });

        const GD = goalsFor - goalsAgainst;
        const points = wins * 3 + draws;

        return { W: wins, D: draws, L: losses, GD, PTS: points };
    }

    const [knockoutResults, setKnockoutResults] = useState({
        semiFinal1: { team1: null, team2: null },
        semiFinal2: { team1: null, team2: null }
    });
    

    function renderKnockoutStage() {
        // Get the first team in Group A and the second team in Group B
        const teamA = groups.find(group => group.groupName === 'A').teams[0];
        const teamB = groups.find(group => group.groupName === 'B').teams[1];
        const teamC = groups.find(group => group.groupName === 'C').teams[0];
        const teamD = groups.find(group => group.groupName === 'D').teams[1];
        const teamE = groups.find(group => group.groupName === 'E').teams[0];
        const teamF = groups.find(group => group.groupName === 'F').teams[1];
        const teamG = groups.find(group => group.groupName === 'G').teams[0];
        const teamH = groups.find(group => group.groupName === 'H').teams[1];

        const teamAA = groups.find(group => group.groupName === 'A').teams[1];
        const teamBB = groups.find(group => group.groupName === 'B').teams[0];
        const teamCC = groups.find(group => group.groupName === 'C').teams[1];
        const teamDD = groups.find(group => group.groupName === 'D').teams[0];
        const teamEE = groups.find(group => group.groupName === 'E').teams[1];
        const teamFF = groups.find(group => group.groupName === 'F').teams[0];
        const teamGG = groups.find(group => group.groupName === 'G').teams[1];
        const teamHH = groups.find(group => group.groupName === 'H').teams[0];

        const winnerAB = knockoutResults.semiFinal1.team1;
        const winnerCD = knockoutResults.semiFinal1.team2;
        

        return (
            <div className='container'>
   
                <h4 className='text-center text-light mb-3'>Round of 16</h4>

                <div className='row'>
                    <div className='col-md-6 mx-auto mb-5'>
                        <div className="card bg- text-light border border-light">
                            <div className="card-header text-center bg-light">
                                <h5 className='text-dark'>Round of 16</h5>
                            </div>
                            <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{teamA}</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{teamB}</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{teamC}</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{teamD}</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{teamE}</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{teamF}</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{teamG}</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{teamH}</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 mx-auto mb-5'>
                        <div className="card bg- text-light border border-light">
                            <div className="card-header text-center bg-light">
                                <h5 className='text-dark'>Round of 16(2)</h5>
                            </div>
                            <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{teamAA}</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{teamBB}</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{teamCC}</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{teamDD}</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{teamEE}</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{teamFF}</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{teamGG}</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{teamHH}</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <h4 className='text-center text-light'> Quarter-Finals</h4>
                    <div className='row'>
                <div className='col-md-6 mx-auto mb-5'>
                    <div className="card bg- text-light border border-light">
                        <div className="card-header text-center bg-light">
                            <h5 className='text-dark'>Quarter-Final 1</h5>
                        </div>
                  
                        <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{winnerAB} winner of A/B</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{winnerCD} winner of C/D</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                    </div>
                    
                </div>
                
                <div className='col-md-6 mx-auto mb-5'>
                    <div className="card bg- text-light border border-light">
                        <div className="card-header text-center bg-light">
                            <h5 className='text-dark'>Quarter-Final 2</h5>
                        </div>
              
                        <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b> winner of E/F</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b> winner of G/H</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                    </div>
                </div>
            </div>
                </div>
                <div className='container'>
                    <div className='row'>
                <div className='col-md-6 mx-auto mb-5'>
                    <div className="card bg- text-light border border-light">
                        <div className="card-header text-center bg-light">
                            <h5 className='text-dark'>Quarter-Final 3</h5>
                        </div>
                        <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b>{winnerAB} winner of A/B(2)</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b>{winnerCD} winner of C/D(2)</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                    </div>
                </div>
                <div className='col-md-6 mx-auto mb-5'>
                    <div className="card bg- text-light border border-light">
                        <div className="card-header text-center bg-light">
                            <h5 className='text-dark'>Quarter-Final 4</h5>
                        </div>
                        <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col order-first">
                                            <b> winner of E/F(2)</b>
                                        </div>
                                        <div className="col">
                                            <b>vs</b>
                                        </div>
                                        <div className="col order-last">
                                            <b> winner of G/H(2)</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                    </div>
                </div>
            </div>
                </div>
                <div className='container'>
                    <h4 className='text-center text-light'> Semi-Finals</h4>
                    <div className='row'>
                <div className='col-md-6 mx-auto mb-5'>
                    <div className="card bg- text-light border border-light">
                        <div className="card-header text-center bg-light">
                            <h5 className='text-dark'>Semi-Final 1</h5>
                        </div>
                 
                        <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-5 order-first">
                                            <b>winner of quarter-finals 1</b>
                                        </div>
                                        <div className="col-2">
                                            <b>vs</b>
                                        </div>
                                        <div className="col-5 order-last">
                                            <b>winner of quarter-finals 2</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                    </div>
             
                </div>
                <div className='col-md-6 mx-auto mb-5'>
                    <div className="card bg- text-light border border-light">
                        <div className="card-header text-center bg-light">
                            <h5 className='text-dark'>Semi-Final 2</h5>
                        </div>
             
                             
                        <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-5 order-first">
                                            <b>winner of quarter-finals 3 </b>
                                        </div>
                                        <div className="col-2">
                                            <b>vs</b>
                                        </div>
                                        <div className="col-5 order-last">
                                            <b>winner of quarter-finals 4</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                    </div>
                </div>
            </div>
                </div>
                <div className='container'>
                    <h4 className='text-center text-light'> Final</h4>
                    <div className='row'>
                <div className='col-md-6 mx-auto mb-5'>
                    <div className="card bg- text-light border border-light">
                        <div className="card-header text-center bg-light">
                            <h5 className='text-dark'>Final</h5>
                        </div>
                    
                        <div className="card-body text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-5 order-first">
                                            <b>winner of semiFinal 1</b>
                                        </div>
                                        <div className="col-2">
                                            <b>vs</b>
                                        </div>
                                        <div className="col-5 order-last">
                                            <b>winner of semiFinal 2</b>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                    </div>
                </div>
     
            </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Navbar />
            <div className='App-headerV3'>
                <h1 className='text-center text-dark mb-4'>World Cup 2022</h1>
                <div className="container mx-auto mb-4">
    <div className="row">
        <div className="col text-center">
            <span className="text-light text-center badge bg-primary bsb-w-150" style={{ userSelect: 'none' }} onClick={() => setShowKnockout(false)}>Group Stage</span>
        </div>
        <div className="col text-center">
            {showKnockout ? (
                <span className="text-light text-center badge bg-danger bsb-w-150" style={{ userSelect: 'none' }} onClick={() => setShowKnockout(false)}>Knockout Stage</span>
            ) : (
                <span className="text-light text-center badge bg-danger bsb-w-150" style={{ userSelect: 'none' }} onClick={() => setShowKnockout(true)}>Knockout Stage</span>
            )}
        </div>
    </div>
</div>

                {showKnockout ? renderKnockoutStage() : (
                    <div className='container'>
                        <div className='row'>
                            {groups.map(group => (
                                <div key={group.groupName} className='col-md-6 mx-auto mb-5'>
                                    <div className="card  text-dark border border-dark">
                                        <div className="card-header text-center bg-purple"style={{background: 'rgb(138, 14, 138)'}}>
                                            <h5 className='text-light' >Group {group.groupName}</h5>
                                        </div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th style={{ backgroundColor: '', color: 'black' }} scope="col">Team</th>
                                                    <th style={{ backgroundColor: '', color: 'black' }} scope="col">MP</th>
                                                    <th style={{ backgroundColor: '', color: 'black' }} scope="col">W</th>
                                                    <th style={{ backgroundColor: '', color: 'black' }} scope="col">D</th>
                                                    <th style={{ backgroundColor: '', color: 'black' }} scope="col">L</th>
                                                    <th style={{ backgroundColor: '', color: 'black' }} scope="col">GD</th>
                                                    <th style={{ backgroundColor: '', color: 'black' }} scope="col">PTS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {group.teams.map((team, index) => {
                                                    const { W, D, L, GD } = calculatePoints(group, team);
                                                    const points = W * 3 + D;

                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ backgroundColor: '', color: 'black' }}>
                                                                {team === 'Netherlands' ? (
                                                                    <Link to='/netherlandsstats' style={{ textDecoration: 'none', color: 'black' }}>Netherlands</Link>
                                                                ) : (
                                                                    team
                                                                )}
                                                            </td>
                                                            <td style={{ backgroundColor: '', color: 'black' }}>{group.played[index]}</td>
                                                            <td style={{ backgroundColor: '', color: 'black' }}>{W}</td>
                                                            <td style={{ backgroundColor: '', color: 'black' }}>{D}</td>
                                                            <td style={{ backgroundColor: '', color: 'black' }}>{L}</td>
                                                            <td style={{ backgroundColor: '', color: 'black' }}>{GD}</td>
                                                            <td style={{ backgroundColor: '', color: 'black' }}>{points}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                                
        <h2 className='text-center text-dark'></h2>
        
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

function calculateGD(groups, groupName, team) {
    const group = groups[groupName];
    const teamIndex = group.teams.indexOf(team);

    if (teamIndex === -1) {
        return 0;
    }

    const goalsFor = group.points[teamIndex];
    const goalsAgainst = group.played[teamIndex];

    return goalsFor - goalsAgainst;
}

export default WorldCup2022;
