// SignupGoogle.jsx
import React, { useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';


const SignupGoogle = ({ onSubmit }) => {
  const [username, setUsername] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(username);
  };

  return (
    <div style={{backgroundColor: '#222427'}}>
        <Navbar />
    <div className='App-headerV6'>
      <section class="bg-dark p-3 p-md-4 p-xl-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-9 col-lg-7 col-xl-6 col-xxl-5">
        <div class="card border border-light-subtle rounded-4">
          <div class="card-body p-3 p-md-4 p-xl-5">
            <div class="row">
              <div class="col-12">
                <div class="mb-5">
                  <div class="text-center mb-4">
                    <a href="#!">
                      <img src="https://lirp.cdn-website.com/fc5e77e4/dms3rep/multi/opt/cropped-1200x400_Horizontal_Transparent-201w.png" alt="BootstrapBrain Logo" width="175" height="57" />
                    </a>
                  </div>
                  <h2 class="h4 text-center">Enter Username</h2>
                  <h3 class="fs-6 fw-normal text-secondary text-center m-0">By providing advancing you agree to Interstellar Predictions's <a href='' style={{textDecoration: 'none'}}>terms & conditions</a></h3>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="row gy-3 overflow-hidden">
                <div class="col-12">
                  <div class="form-floating mb-3">
                    <input type="username" class="form-control" name="username" id="user" placeholder="username" value={username} onChange={(e) => setUsername(e.target.value)} required  />
                    <label for="username" class="form-label" >Username</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-grid">
                    <button class="btn bsb-btn-xl btn-primary" type="submit">Continue</button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
    <Footer />
    </div>
  );
};

export default SignupGoogle;
