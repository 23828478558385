// Confirmation.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../firebase'; // Import your Firebase authentication and database instances

function Confirmation() {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && user.emailVerified) {
        const userData = JSON.parse(localStorage.getItem('userData'));

        if (!userData) {
          console.error('User data not found');
          navigate('/'); // Redirect to home if user data is not found
          return;
        }

        try {
          // Send user data to the database
          const userRef = database.ref(`users/${user.uid}`);
          await userRef.set(userData);

          // Redirect to home page
          navigate('/');
        } catch (error) {
          console.error('Error sending user data to database:', error.message);
        } finally {
          // Clear user data from localStorage
          localStorage.removeItem('userData');
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <div className='App-headerV6'>
      <div className='text-center'>
        <h1 className='text-light'>Please Refresh this page once you have verified your email address</h1>
      </div>
    </div>
  );
}

export default Confirmation;
