// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, updateProfile, createUserWithEmailAndPassword , setPersistence, browserSessionPersistence , GoogleAuthProvider,} from 'firebase/auth';
import { getFirestore, collection, addDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { sendEmailVerification } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { v4 } from "uuid";
import firebase from 'firebase/app';
import {  browserLocalPersistence,  } from 'firebase/auth';
import { doc, updateDoc } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxHOIfyvh3VvLB_aV6MFwtQ5GzMW-o4vQ",
  authDomain: "predictify-predictor.firebaseapp.com",
  projectId: "predictify-predictor",
  storageBucket: "predictify-predictor.appspot.com",
  messagingSenderId: "431313048912",
  appId: "1:431313048912:web:61bf3cea298c2de4970855",
  measurementId: "G-0XMNHSECL4",
  databaseURL: "https://predictify-predictor-default-rtdb.europe-west1.firebasedatabase.app",};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);
const db = getDatabase(app);
setPersistence(auth, browserSessionPersistence);
const firestore = getFirestore(app);
const commentsCollection = collection(firestore, 'profile-pics');

const handleImageUpload = async (e, setImage) => {
  const file = e.target.files[0];
  const storageRef = ref(storage, `${auth.currentUser.uid}/profile-picture.jpg`);
  try {
    await uploadBytes(storageRef, file);
    const imageURL = await getDownloadURL(storageRef);

    // Update the user's profile with the image URL
    await updateProfile(auth.currentUser, { photoURL: imageURL });

    setImage(imageURL); // Update the image in your component
  } catch (error) {
    console.error('Image upload error:', error);
  }
}
export const updateUserProfile = async ({ userId, favSport, favTeam }) => {
  try {
    // Reference to the user's document
    const userRef = doc(db, 'users', userId);

    // Update user document
    await updateDoc(userRef, {
      favSport: favSport,
      favTeam: favTeam,
    });
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Persistence set to local');
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

// Function to register a user and send a verification email
export async function registerUser(email, password) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Action code settings to configure the email verification link
    const actionCodeSettings = {
      url: 'https://predictify.app/login', // Redirect URL after email verification
      handleCodeInApp: true, // Set to true if you want to handle in the app
    };

    // Send a verification email with the specified action URL
    await sendEmailVerification(user, actionCodeSettings);

    return user;
  } catch (error) {
    console.error('Error during registration:', error);
    throw error;
  }
}

// Function to check if the user's email is verified
export function isEmailVerified(user) {
  return user && user.emailVerified;
}

export function useAuth() {
  return { auth };
}

export const database = getAuth(app)
export const imageDb = getStorage;
export { app, auth, firestore, commentsCollection, db, provider };
