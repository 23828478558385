import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'; // Ensure this import is correct
import 'bootstrap/dist/css/bootstrap.min.css';
import { auth } from '../firebase';

function Settings() {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [settings, setSettings] = useState({
    showFaqPage: true,
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const allowedEmails = ['denis@kingjtech.com', 'denis@atomtech.live', 'support@predictify.app', 'jeremy@fall.com.mt'];
        if (allowedEmails.includes(user.email)) {
          setIsAuthorized(true);
        } else {
          navigate('/'); // Redirect to homepage or error page
        }
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleChange = (event) => {
    setSettings({
      ...settings,
      [event.target.name]: event.target.checked,
    });
  };

  if (!isAuthorized) {
    return null;
  }

  return (
    <div>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1 p-3">
          <h1 className='text-center'>Admin Settings</h1>
          <div>
            <label>
              <input
                type="checkbox"
                name="showFaqPage"
                checked={settings.showFaqPage}
                onChange={handleChange}
              />
              Show FAQ Page
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
