import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Sidebar() {

    const location = useLocation(); // Get the current location

  return (
<div className="d-flex flex-column flex-shrink-0 p-3 bg-light"
     style={{
         width: '250px',
         height: '100vh',
         position: 'fixed',
         top: '0',
         bottom: '0',
         overflowY: 'auto'
     }}>      <Link  className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
        <span className="fs-4">Admin Panel</span>
      </Link>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <Link 
            to="/admin/dashboard" 
            className={`nav-link ${location.pathname === '/admin/dashboard' ? 'active' : 'link-dark'}`} 

            aria-current="page"
          >
            Dashboard
          </Link>
        </li>
        <li>
          <Link 
            to="/admin/users" 
            className={`nav-link ${location.pathname === '/admin/users' ? 'active' : 'link-dark'}`}
          >
            Users
          </Link>
        </li>
        <li>
          <Link 
            to="/admin/settings" 
            className={`nav-link ${location.pathname === '/admin/settings' ? 'active' : 'link-dark'}`}
          >
            Settings
          </Link>
        </li>
        <li>
          <Link 
            to="/createarticle" 
            className={`nav-link ${location.pathname === '/createarticle' ? 'active' : 'link-dark'}`}
          >
            Create Article
          </Link>
        </li>
        <li>
          <Link 
            to="/" 
            className="nav-link link-dark"
          >
            Exit
          </Link>
        </li>
      </ul>
      <hr />
    </div>
  );
}

export default Sidebar;
