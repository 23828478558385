import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Aberdeen from '../Team-Kits-Images/Aberdeen.png'
import Celtic from '../Team-Kits-Images/Celtic.png'
import Dundee from '../Team-Kits-Images/Dundee.png'
import DundeeUtd from '../Team-Kits-Images/DundeeUtd.png'
import Hearts from '../Team-Kits-Images/Hearts.png'
import Hibernian from '../Team-Kits-Images/Hibernian.png'
import Kilmarnock from '../Team-Kits-Images/Kilmarnock.png'
import Motherwell from '../Team-Kits-Images/Motherwell.png'
import Rangers from '../Team-Kits-Images/Rangers.png'
import RossCounty from '../Team-Kits-Images/RossCounty.png'
import StJohnstone from '../Team-Kits-Images/StJohnstone.png'
import StMirren from '../Team-Kits-Images/StMirren.png'
import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'

function ScottishStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/179/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
      standingsMap[fixture.teams.away.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points += homeResult === "W" ? 3 : homeResult === "D" ? 1 : 0;
      standingsMap[awayTeam].points += awayResult === "W" ? 3 : awayResult === "D" ? 1 : 0;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
  
      // Update lastFive results (most recent on the right)
      standingsMap[homeTeam].lastFive = [...standingsMap[homeTeam].lastFive, homeResult].slice(-5);
      standingsMap[awayTeam].lastFive = [...standingsMap[awayTeam].lastFive, awayResult].slice(-5);
    });
  
    // Convert standings map to array
    return Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  };
  
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
      case 'Aberdeen':
        return Aberdeen;
      case 'Celtic':
        return Celtic;
      case 'Dundee':
        return Dundee;
      case 'Dundee Utd':
        return DundeeUtd;
      case 'Heart Of Midlothian':
        return Hearts;
      case 'Hibernian':
        return Hibernian;
      case 'Kilmarnock':
        return Kilmarnock;
      case 'Motherwell':
        return Motherwell;
      case 'Rangers':
        return Rangers;
      case 'Ross County':
        return RossCounty;
      case 'ST Johnstone':
        return StJohnstone;
      case 'ST Mirren':
        return StMirren;
      default:
        return null;
    }
};


  return (
<div className=''>
  <div className='App-headerV8'>
    <Navbar />
    <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>SPFL Standings</h2>
    <div className='container text-center mb-2'>
      <i className='text-center text-light' style={{ fontFamily: 'Lato, sans-serif' }}>*Click on team name to view stats </i>
    </div>

    <div className='d-flex justify-content-center'>
      <div className='col-10 col-md-6 col-lg-4'>
        <select
          className='form-select mb-3 text-center'
          style={{ backgroundColor: 'purple', color: 'white' }}
          value={season}
          onChange={(e) => setSeason(e.target.value)}
        >
          <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 24/25</option>
          <option value="2023" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 23/24</option>
          <option value="2022" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 22/23</option>
          <option value="2021" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 21/22</option>
          <option value="2020" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 20/21</option>
        </select>
      </div>
    </div>

    <div className='container'>
      {loading ? (
        <h2 className='text-center text-dark'><Spinner /></h2>
      ) : (
        <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
          <table
            className="table transparent-table"
            style={{
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ width: '5%', color: 'white' }}>#</th>
                <th scope="col" className="team-column text-light">Team</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>MP</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>W</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>D</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>L</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>Pts</th>
                <th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>Last 5</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
                  <th scope="row" className={
                    index < 4 ? 'text-light' :
                    index >= standings.length - 3 ? 'text-light' :
                    index >= 4 && index <= 6 ? 'text-light' : 'text-light'}>
                    {index + 1}
                  </th>
                  <td style={{ color: 'white' }}>
                    {getTeamKit(team.team) && (
                      <img
                        src={getTeamKit(team.team)}
                        alt={`${team.team} Kit`}
                        style={{ width: '25px', marginRight: '5px' }}
                      />
                    )}
                   {team.team === 'ST Mirren' ? (
                        <Link to="/stmirren" style={{ textDecoration: 'none', color: 'white' }}>
                          ST Mirren
                        </Link>
                      ) : team.team === 'Celtic' ? (
                        <Link to="/Celtic" style={{ textDecoration: 'none', color: 'white' }}>Celtic</Link>
                      ) : team.team === 'Aberdeen' ? (
                        <Link to="/Aberdeen" style={{ textDecoration: 'none', color: 'white' }}>Aberdeen</Link>
                      ) : team.team === 'Heart Of Midlothian' ? (
                        <Link to="/heartofmidlothian" style={{ textDecoration: 'none', color: 'white' }}>Hearts</Link>
                      ) : team.team === 'Rangers' ? (
                        <Link to="/Rangers" style={{ textDecoration: 'none', color: 'white' }}>Rangers</Link>
                      ) : team.team === 'Motherwell' ? (
                        <Link to="/motherwell" style={{ textDecoration: 'none', color: 'white' }}>Motherwell</Link>
                      ) : team.team === 'Ross County' ? (
                        <Link to="/RossCounty" style={{ textDecoration: 'none', color: 'white' }}>Ross County</Link>
                      ) : team.team === 'Dundee Utd' ? (
                        <Link to="/Dundeeutd" style={{ textDecoration: 'none', color: 'white' }}>Dundee Utd</Link>
                      ) : team.team === 'Dundee' ? (
                        <Link to="/Dundee" style={{ textDecoration: 'none', color: 'white' }}>Dundee	</Link>
                      ) : team.team === 'Hibernian' ? (
                        <Link to="/Hibernian" style={{ textDecoration: 'none', color: 'white' }}>Hibernian</Link>
                      ) : team.team === 'Kilmarnock' ? (
                        <Link to="/Kilmarnock" style={{ textDecoration: 'none', color: 'white' }}>Kilmarnock</Link>
                      ) : team.team === 'ST Johnstone' ? (
                        <Link to="/stjohnstone" style={{ textDecoration: 'none', color: 'white' }}>ST Johnstone</Link>
                      ) :  (
                        team.team
                      )}
                  </td>
                  <td style={{ color: 'white' }}>{team.matchesPlayed}</td>
                  <td style={{ color: 'white' }}>{team.wins}</td>
                  <td style={{ color: 'white' }}>{team.draws}</td>
                  <td style={{ color: 'white' }}>{team.losses}</td>
                  <td style={{ color: 'white' }}>{team.points}</td>
                  <td className="d-none d-lg-table-cell">
                    <div className="d-flex flex-row">
                      {team.lastFive.map((result, idx) => (
                        <span
                          key={idx}
                          className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                          style={{
                            width: '23px',
                            height: '23px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.9rem',
                            marginRight: '2px'
                          }}
                        >
                          {result}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

    {/* Legends for qualifications */}


    <div className='container mt-4' style={{ paddingBottom: '5px' }}>
      <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
    </div>
  </div>
  <Footer />
</div>


  );
}

export default ScottishStandings;
