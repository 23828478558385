import { useState, useEffect } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Confetti from 'react-confetti';

const TestApi = () => {
  const [fixtures, setFixtures] = useState([]);
  const [userPicks, setUserPicks] = useState({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [totalPoints, setTotalPoints] = useState({});
  const [loading, setLoading] = useState(true);
  const [confetti, setConfetti] = useState(false);
  const [showPrevious, setShowPrevious] = useState(false);
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [displayName, setDisplayName] = useState('');

  const auth = getAuth();
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  // Fetch MLS fixtures from the API
  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const response = await fetch("https://iga-sport.azurewebsites.net/api/league/253/fixtures/season/2024", {
          headers: { 'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg==' }
        });
        const data = await response.json();
        setFixtures(data);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchFixtures();
  }, []);

  // Filter and sort fixtures based on whether they have finished
  useEffect(() => {
    const now = new Date();
    const filtered = fixtures.filter((fixture) => {
      const fixtureDate = new Date(fixture.fixture.date);
      return showPrevious ? fixtureDate < now : fixtureDate >= now; // Toggle based on showPrevious
    });

    // Sort by fixture date
    filtered.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(filtered);
  }, [showPrevious, fixtures]);

  // Handle pick changes for home and away goals
  const handlePickChange = (fixtureId, teamType, value) => {
    const updatedPicks = {
      ...userPicks,
      [fixtureId]: { ...userPicks[fixtureId], [teamType]: value },
    };
    setUserPicks(updatedPicks);
  };

  // Submit user picks to Firebase
 // Submit user picks to Firebase
const handleSubmitPicks = async () => {
  if (!userId) {
    alert("You need to be logged in to submit picks.");
    return;
  }

  const db = getDatabase();

  // Get user's displayName and format it for the database entry
  let userDisplayName = auth.currentUser.displayName || "Anonymous";

  // Format the display name, removing extra parentheses or other formatting
  const match = userDisplayName.match(/\(([^)]+)\)/);
  if (match && match[1]) {
    userDisplayName = match[1];
  }

  // Reference for user picks and total points in the database
  const picksRef = ref(db, `MLSPicks/${userDisplayName}`);
  const totalPointsRef = ref(db, `MLSPicksTotalPoints/${userDisplayName}`);

  try {
    // Prepare the data structure for the user's picks
    const dataToSubmit = {
      ...userPicks, // this contains the user's picks, keyed by fixture IDs
    };

    // Submit the picks to the database under the user's display name
    await set(picksRef, dataToSubmit);

    // Check if totalPoints entry exists; if not, initialize it
    const totalPointsSnapshot = await get(totalPointsRef);
    if (!totalPointsSnapshot.exists()) {
      await set(totalPointsRef, { totalPoints: 0 });
    }

    // Handle success: show confetti, reset sent flag
    setSent(true);
    setTimeout(() => setSent(false), 15000);
    setConfetti(true);
    setTimeout(() => setConfetti(false), 5000);

    console.log("Picks submitted successfully:", dataToSubmit);
  } catch (error) {
    console.error("Error submitting picks:", error);
    setError(true);
    setTimeout(() => setError(false), 5000);
  }
};


  if (loading) {
    return <div>Loading fixtures...</div>;
  }

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  return (
    <div>
      <div className='App-headerV3'>
        <h2 className='text-dark text-center'>MLS Picks</h2>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-12'>
              {userId && (
                <div className="text-center mb-3">
                  {/* <h4>Total Points: {totalPoints[userId] !== undefined ? totalPoints[userId] : 'Loading...'}</h4> */}
                </div>
              )}
              <button className='btn btn-secondary ml-2' onClick={() => setShowPrevious(!showPrevious)}>
                {showPrevious ? 'Show Upcoming Fixtures' : 'Show Previous Fixtures'}
              </button>

              {filteredFixtures.map((fixture) => (
                <div key={fixture.fixture.id} className='my-3 card'>
                  <div className='card-body text-center'>
                    <h5 className='card-title'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                    <div className='row'>
                      <div className="col">
                        <select value={userPicks[fixture.fixture.id]?.home || ''} onChange={(e) => handlePickChange(fixture.fixture.id, 'home', e.target.value)}>
                          <option value="">Pick</option>
                          {[...Array(11).keys()].map((num) => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <select value={userPicks[fixture.fixture.id]?.away || ''} onChange={(e) => handlePickChange(fixture.fixture.id, 'away', e.target.value)}>
                          <option value="">Pick</option>
                          {[...Array(11).keys()].map((num) => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='mt-2'>
                      {convertToFormattedDateTime(fixture.fixture.date)}
                    </div>
                  </div>
                </div>
              ))}

              <div className='text-center'>
                <button className='btn btn-purple' onClick={handleSubmitPicks}>Submit Picks</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {confetti && <Confetti />}
    </div>
  );
};

export default TestApi;
