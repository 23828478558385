import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const knockoutStages = {
  roundOf16: [
    { match: '30 Jun, 22:00 1st: B vs 3rd: A/D/E/F' },
    { match: '29 Jun, 22:00 1st: A vs 2nd: C' },
    { match: '1 Jul, 22:00 1st: F vs 3rd: A/B/C' },
    { match: '1 Jul, 19:00 2nd: D vs 2nd: E' },
    { match: '2 Jul, 19:00 1st: E vs 3rd: A/B/C/D' },
    { match: '2 Jul, 22:00 1st: D vs 2nd: F' },
    { match: '30 Jun, 19:00 1st: C vs 3rd: D/E/F' },
    { match: '29 Jun, 19:00 2nd: A vs 2nd: B' },
  ],
  quarterFinals: [
    { match: '5 Jul, 19:00 W39 vs W37' },
    { match: '5 Jul, 22:00 W41 vs W42' },
    { match: '6 Jul, 22:00 W43 vs W44' },
    { match: '6 Jul, 19:00 W40 vs W38' },
  ],
  semiFinals: [
    { match: '9 Jul, 22:00 W45 vs W46' },
    { match: '10 Jul, 22:00 W47 vs W48' },
  ],
  final: [
    { match: '14 Jul, 22:00 W49 vs W50' },
  ]
};



function Euro2024Standings() {
    const [standings, setStandings] = useState(null);
    const { t } = useTranslation();
  const [showKnockout, setShowKnockout] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/standings/4/season/2024', {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const data = await response.json();
        console.log('Fetched data:', data); // Log fetched data for debugging
        if (data && data.response && data.response.length > 0) {
          const leagueData = data.response[0].league;
          if (leagueData && leagueData.standings) {
            const teams = leagueData.standings.flat().map(team => ({
              ...team.team,
              points: team.points,
              goalsDiff: team.goalsDiff,
              wins: team.all.win,
              draws: team.all.draw,
              loses: team.all.lose,
            }));

            teams.forEach(team => {
              console.log('Team:', team); // Log entire team object
            });
            const uniqueTeams = Array.from(new Set(teams.map(team => team.name)))
              .map(name => teams.find(team => team.name === name));
            const groupedTeams = [];
            for (let i = 0; i < uniqueTeams.length; i += 4) {
              groupedTeams.push(uniqueTeams.slice(i, i + 4));
            }
            setStandings(groupedTeams);
          } else {
            console.error('Invalid data format or empty standings');
          }
        } else {
          console.error('Invalid data format or empty response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleToggle = () => {
    setShowKnockout(!showKnockout);
  };

  const handleToggleKnockout = () => {
    setShowKnockout(true);
  };

  const handleToggleStandings = () => {
    setShowKnockout(false);
  };

  const renderKnockoutStage = () => {
    return (

      <div>
              <div className='container'>
   

   <div className='row'>
       <div className='col-md-6 mx-auto mb-5'>
           <div className="card bg- text-light border border-light">
               <div className="card-header text-center purple-bg">
                   <h5 className='text-light'>{t('round_of_16')}</h5>
               </div>
               <div className="card-body text-center text-dark">
                   <div className="container ">
                       <div className="row ">
                           <div className="col order-first ">
                               <b>Switzerland</b>
                           </div>
                           <div className="col ">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>Italy</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Saturday 29 June 2024</p>

                   </div>
                   <hr></hr>
               </div>
               <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b>England</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>Slovakia</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Sunday 30 June 2024</p>

                   </div>
                   <hr></hr>
               </div>
               <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b>France</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>Belgium</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Monday 1 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
               <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b>Austria</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>Turkey</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Tuesday 2 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
           </div>
       </div>
       <div className='col-md-6 mx-auto mb-5 text-dark'>
           <div className="card bg- text-light border border-light">
               <div className="card-header text-center purple-bg trxt-dark">
                   <h5 className='text-light'>{t('round_of_16')}</h5>
               </div>
               <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b>Germany</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>Denmark</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Saturday 29 June 2024</p>

                   </div>
                   <hr></hr>
               </div>
               <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b>Spain</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>Georgia</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Sunday 30 June 2024</p>

                   </div>
                   <hr></hr>
               </div>
               <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b>Portugal</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>Slovenia</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Monday 1 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
               <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b>Netherlands</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>Romania</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Tuesday 2 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
           </div>
       </div>
   </div>
   <div className='container'>
       <div className='row'>
   <div className='col-md-6 mx-auto mb-5'>
       <div className="card bg- text-light border border-light">
           <div className="card-header text-center purple-bg">
               <h5 className='text-light '>{t('quarter_finals')} 1</h5>
           </div>
     
           <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b> Spain</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b> Germany</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Friday 5 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
       </div>
       
   </div>
   
   <div className='col-md-6 mx-auto mb-5'>
       <div className="card bg- text-light border border-light ">
           <div className="card-header text-center bg-light purple-bg">
               <h5 className='text-light'>{t('quarter_finals')} 2</h5>
           </div>
 
           <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b>Portugal</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>France</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Friday 5 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
       </div>
   </div>
</div>
   </div>
   <div className='container'>
       <div className='row'>
   <div className='col-md-6 mx-auto mb-5'>
       <div className="card bg- text-light border border-light">
           <div className="card-header text-center purple-bg">
               <h5 className='text-light'>{t('quarter_finals')} 3</h5>
           </div>
           <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b> Switzerland</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b>England</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Saturday 6 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
       </div>
   </div>
   <div className='col-md-6 mx-auto mb-5'>
       <div className="card bg- text-light border border-light">
           <div className="card-header text-center purple-bg">
               <h5 className='text-light'>{t('quarter_finals')} 4</h5>
           </div>
           <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col order-first">
                               <b>Netherlands</b>
                           </div>
                           <div className="col">
                               <b>vs</b>
                           </div>
                           <div className="col order-last">
                               <b> Turkey</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Saturday 6 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
       </div>
   </div>
</div>
   </div>
   <div className='container'>
       <div className='row'>
   <div className='col-md-6 mx-auto mb-5'>
       <div className="card bg- text-light border border-light">
           <div className="card-header text-center purple-bg">
               <h5 className='text-light'> {t('semi_finals')} 1</h5>
           </div>
    
           <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col-5 order-first">
                               <b>Spain</b>
                           </div>
                           <div className="col-2">
                               <b>vs</b>
                           </div>
                           <div className="col-5 order-last">
                               <b>France</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Tuesday 9 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
       </div>

   </div>
   <div className='col-md-6 mx-auto mb-5'>
       <div className="card bg- text-light border border-light">
           <div className="card-header text-center purple-bg">
               <h5 className='text-light'>{t('semi_finals')} 2 </h5>
           </div>

                
           <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col-5 order-first">
                               <b>Netherlands</b>
                           </div>
                           <div className="col-2">
                               <b>vs</b>
                           </div>
                           <div className="col-5 order-last">
                               <b>England</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Wednesday 10 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
       </div>
   </div>
</div>
   </div>
   <div className='container text-light'>
       <div className='row'>
   <div className='col-md-6 mx-auto mb-5'>
       <div className="card bg- text-light border border-light">
           <div className="card-header text-center purple-bg">
               <h5 className='text-light'>{t('final')}</h5>
           </div>
       
           <div className="card-body text-center text-dark">
                   <div className="container">
                       <div className="row">
                           <div className="col-5 order-first">
                               <b>Spain</b>
                           </div>
                           <div className="col-2">
                               <b>vs</b>
                           </div>
                           <div className="col-5 order-last">
                               <b>England</b>
                           </div>
                       </div>
                       <p className='text-center padtop'>Sunday 14 July 2024</p>

                   </div>
                   <hr></hr>
               </div>
       </div>
   </div>

</div>
   </div>
</div>
      </div>

    );
  };

  if (standings === null) {
    return <h2 className='text-center padtop App-headerV8 text-dark'>Loading...</h2>;
  }

  if (!Array.isArray(standings)) {
    return <p>Error: Standings data is not an array</p>;
  }

  return (
    <div>
      <div className='App-headerV3'>
        <Navbar />
        <h2 className='text-dark text-center mb-3 padtop'>{t('euro_2024_standings')}</h2>
        <div className='container text-center mb-2'><i className='text-center'>*Click on team name to view stats </i></div>

        <div className='container text-center'>
          <button style={{marginRight: '5px'}} className="btn btn-purple mb-3" onClick={handleToggleStandings}>
          {t('show_euro_2024_standings')}          </button>
          
          <button className="btn btn-purple mb-3 mr-3" onClick={handleToggleKnockout}>
          {t('show_knockout_stage')}          </button>
        </div>
        <div className="container row mx-auto">
          {showKnockout ? renderKnockoutStage() : (
            standings.map((group, index) => {
              if (!Array.isArray(group)) {
                console.error(`Group ${index} is not an array`);
                return null;
              }

              return (
                <div className='col-md-6 col-lg-6' key={index}>
                  <div className=''>
                    <h3 className='text-light purple-bg text-center' style={{paddingTop: '5px' , paddingBottom: '5px'}}>Group {String.fromCharCode(65 + index)}</h3>
                    <div className="table">
                      <div className=''>
                        <table className="table table-striped" style={{ backgroundColor: '' }}>
                          <thead style={{ backgroundColor: '', color: 'white' }}>
                            <tr>
                              <th className="text-dark" scope="col" style={{ backgroundColor: '' }}>#</th>
                              <th className="text-dark" scope="col" style={{ backgroundColor: '' }}>Team</th>
                              <th className="text-dark" scope="col" style={{ backgroundColor: '' }}>PTS</th>
                              <th className="text-dark" scope="col" style={{ backgroundColor: '' }}>GD</th>
                              <th className="text-dark" scope="col" style={{ backgroundColor: '' }}>W</th>
                              <th className="text-dark" scope="col" style={{ backgroundColor: '' }}>D</th>
                              <th className="text-dark" scope="col" style={{ backgroundColor: '' }}>L</th>
                            </tr>
                          </thead>
                          <tbody style={{ backgroundColor: '' }}>
                          {group.map((team, teamIndex) => (
  <tr key={teamIndex} style={{ backgroundColor: '' }}>
    <td className='text-dark' style={{ backgroundColor: '' }}>{teamIndex + 1}</td>
    <td className='text-dark' style={{ backgroundColor: '' }}>
      {team.name === 'Netherlands' ? (
        <Link to='/netherlandsstats' style={{ textDecoration: 'none', color: 'black' }}>Netherlands</Link>
      ) : (
        team.name
      )}
    </td>
    <td className='text-dark' style={{ backgroundColor: '' }}>{team.points}</td>
    <td className='text-dark' style={{ backgroundColor: '' }}>{team.goalsDiff}</td>
    <td className='text-dark' style={{ backgroundColor: '' }}>{team.wins}</td>
    <td className='text-dark' style={{ backgroundColor: '' }}>{team.draws}</td>
    <td className='text-dark' style={{ backgroundColor: '' }}>{team.loses}</td>
  </tr>
))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Euro2024Standings;
