import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import '../App.css';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { BiFootball } from "react-icons/bi";

function GlobalLeaderboard() {
    const { t } = useTranslation();
    const [eplParticipants, setEplParticipants] = useState([]);
    const [spflParticipants, setSPFLParticipants] = useState([]);
    const [championshipParticipants, setChampionshipParticipants] = useState([]);
    const [SASoccerParticipants, setSASoccerParticipants] = useState([]);
    const [UCLParticipants, setUCLParticipants] = useState([]);
    const [BundesligaParticipants, setBundesligaParticipants] = useState([]);
    const [SerieAParticipants, setSerieAParticipants] = useState([]);
    const [laligaParticipants, setLaligaParticipants] = useState([]);
    const [showAllSASoccer, setShowAllSASoccer] = useState(false);
    const [showAllLaliga, setShowAllLaliga] = useState(false);
    const [showAllEpl, setShowAllEpl] = useState(false);
    const [showAllSPFL, setShowAllSPFL] = useState(false);
    const [showAllUCL, setShowAllUCL] = useState(false);
    const [showAllBundesliga, setShowAllBundesliga] = useState(false);
    const [showAllSerieA, setShowAllSerieA] = useState(false);
    const [showAllChampionship, setShowAllChampionship] = useState(false);
    const [currentSport, setCurrentSport] = useState('Football'); // Default sport
    const [currentCompetition, setCurrentCompetition] = useState('EPL'); // Default competition set to EPL
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            console.log("Logged in user:", user); // Debug log
            setCurrentUser({
                userId: user.uid,
                displayName: user.displayName || user.email,
            });
        }

        const fetchData = async (competition) => {
            try {
                const db = getDatabase();
                let leaderboardRef;

                switch (competition) {
                    case 'EPL':
                        leaderboardRef = ref(db, 'EPL2024TotalPoints');
                        break;
                        case 'Championship':
                        leaderboardRef = ref(db, 'Championship2024TotalPoints');
                        break;
                    case 'SPFL':
                        leaderboardRef = ref(db, 'Scottish2024TotalPoints');
                        break;
                        case 'UCL':
                            leaderboardRef = ref(db, 'UCLTotalPoints');
                            break;
                            case 'Bundesliga':
                                leaderboardRef = ref(db, 'BundesligaTotalPoints');
                                break;
                                case 'Serie A':
                                    leaderboardRef = ref(db, 'SerieATotalPoints');
                                    break;
                        case 'La Liga':
                            leaderboardRef = ref(db, 'LaLiga2024TotalPoints');
                            break;
                            case 'SASoccer':
                                leaderboardRef = ref(db, 'SASoccerTotalPoints');
                                break;
                    default:
                        return;
                }

                if (leaderboardRef) {
                    const leaderboardSnapshot = await get(leaderboardRef);
                    const leaderboardData = leaderboardSnapshot.val();

                    if (leaderboardData) {
                        const entries = Object.entries(leaderboardData)
                            .filter(([userId, data]) => data.totalPoints !== undefined && !isNaN(parseInt(data.totalPoints)))
                            .map(([userId, data]) => ({ userId, ...data }));

                        entries.sort((a, b) => b.totalPoints - a.totalPoints);

                        if (competition === 'EPL') {
                            setEplParticipants(entries);
                        } else if (competition === 'SPFL') {
                            setSPFLParticipants(entries);
                        }
                        else if (competition === 'Championship') {
                            setChampionshipParticipants(entries);
                        }
                        else if (competition === 'UCL') {
                            setUCLParticipants(entries);
                        }
                        else if (competition === 'Bundesliga') {
                            setBundesligaParticipants(entries);
                        }
                        else if (competition === 'Serie A') {
                            setSerieAParticipants(entries);
                        }
                        else if (competition === 'La Liga') {
                            setLaligaParticipants(entries);
                        }
                        else if (competition === 'SASoccer') {
                            setSASoccerParticipants(entries);
                        }
                    } else {
                        console.error(`No ${competition} leaderboard data found`);
                    }
                }
            } catch (error) {
                console.error(`Error fetching ${competition} leaderboard data:`, error);
            }
        };

        // Fetch the data for the current competition
        fetchData(currentCompetition);
    }, [currentCompetition]);

    const extractUsernameFromDisplayName = (displayName) => {
        if (!displayName) {
            return '';
        }
        const match = displayName.match(/\(([^)]+)\)/);
        return match ? match[1] : displayName;
    };

    const handleSportSelection = (sport) => {
        setCurrentSport(sport);
        // If Football is selected, default to EPL
        if (sport === 'Football') {
            setCurrentCompetition('EPL');
            setShowAllEpl(false); // Reset show more state
        } else {
            setCurrentCompetition(''); // Reset competition if not Football
        }
    };

    const handleCompetitionSelection = (competition) => {
        setCurrentCompetition(competition);
    };

    const handleViewMoreEpl = () => setShowAllEpl(true);
    const handleViewMoreSPFL = () => setShowAllSPFL(true);
    const handleViewMoreUCL = () => setShowAllUCL(true);
    const handleViewMoreBundesliga = () => setShowAllBundesliga(true);
    const handleViewMoreSerieA = () => setShowAllSerieA(true);
    const handleViewMoreSASoccer = () => setShowAllSASoccer(true);
    const handleViewMoreLaliga = () => setShowAllLaliga(true);
    const handleViewMoreChampionship = () => setShowAllChampionship(true);

    return (
        <div>
            <Navbar />
            <div className='App-headerV8'>
                <section className="bsb-service-7 py-5 py-xl-8">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-10 col-lg-8 col-xl-7">
                                <h2 className="display-5 mb-5 mb-xl-9 text-center text-light" style={{ fontFamily: 'Lato, sans-serif' }}>{t('global_leaderboard')}</h2>
                                <div className='container text-center text-light' style={{ fontFamily: 'Lato, sans-serif', fontWeight: '300' }}><i>*Select which leaderboard you would like to view</i></div>
                                <hr className="w-50 mx-auto mb-5 mb-xl-9" />
                                {currentUser && (
                                    <div className="text-center mb-4">
                                        {/* <strong>User: </strong>{extractUsernameFromDisplayName(currentUser.displayName)} */}
                                    </div>
                                )}
                       <div className="mb-4 d-flex justify-content-center align-items-center gap-2">
{/* Sport Selection Dropdown */}
<div className="btn-group">
    <button
        type="button"
        className="btn btn-purple-leaderboard text-light dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
    >
        <BiFootball /> Sport
    </button>
    <ul className="dropdown-menu">
        <li>
            <button className="dropdown-item" onClick={() => handleSportSelection('Football')}>
                Football
            </button>
        </li>
        {/* Add more sports as needed */}
    </ul>
</div>


    {/* Competition Selection Dropdown */}
{/* Competition Selection Dropdown */}
<div className="btn-group">
    <button
        type="button"
        className="btn btn-purple-leaderboard text-light dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
    >
        <BiFootball /> Competition
    </button>
    <ul className="dropdown-menu">
        <li><button className="dropdown-item" onClick={() => handleCompetitionSelection('EPL')}>EPL 24/25</button></li>
        <li><button className="dropdown-item" onClick={() => handleCompetitionSelection('SPFL')}>SPFL 24/25</button></li>
        <li><button className="dropdown-item" onClick={() => handleCompetitionSelection('Championship')}>Championship 24/25</button></li>
        <li><button className="dropdown-item" onClick={() => handleCompetitionSelection('UCL')}>Champions League 24/25</button></li>
        <li><button className="dropdown-item" onClick={() => handleCompetitionSelection('Bundesliga')}>Bundesliga 24/25</button></li>
        <li><button className="dropdown-item" onClick={() => handleCompetitionSelection('Serie A')}>Serie A 24/25</button></li>
        <li><button className="dropdown-item" onClick={() => handleCompetitionSelection('La Liga')}>La Liga 24/25</button></li>
        <li><button className="dropdown-item" onClick={() => handleCompetitionSelection('SASoccer')}>SA Soccer 24/25</button></li>
    </ul>
</div>

</div>


                                {currentCompetition && (
                                    <div className="card bg-transparent border-0">
                                        <div className="card-body">
                                            <h5 className="card-title text-center text-light">
                                                {currentCompetition === 'EPL' ? 'EPL 2024/25 Leaderboard' :
                                                    currentCompetition === 'SPFL' ? 'SPFL 2024/25 Leaderboard' :
                                                    currentCompetition === 'Championship' ? 'Championship 2024/25 Leaderboard' :
                                                    currentCompetition === 'UCL' ? 'UCL 2024/25 Leaderboard' :
                                                    currentCompetition === 'Bundesliga' ? 'Bundesliga 2024/25 Leaderboard' :
                                                    currentCompetition === 'Serie A' ? 'Serie A 2024/25 Leaderboard' :

                                                    currentCompetition === 'SASoccer' ? 'SASoccer 2024/25 Leaderboard' :
                                                    currentCompetition === 'La Liga' ? 'La Liga 2024/25 Leaderboard' :


                                                        `${currentCompetition} Leaderboard`}
                                            </h5>
                                            <div className="table-responsive">
                                                <table className="table transparent-table text-light ">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center text-light '>#</th>
                                                            <th className='text-center text-light '>Users</th>
                                                            <th className='text-center text-light '>Points</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-center text-light '>
                                                        {currentCompetition === 'EPL' && eplParticipants.length > 0 ? (
                                                            eplParticipants.slice(0, showAllEpl ? eplParticipants.length : 10).map((data, index) => {
                                                                const username = extractUsernameFromDisplayName(data.displayName || data.userId);
                                                                const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

                                                                return (
                                                                    <tr key={data.userId} className={isCurrentUser ? 'current-user-row' : ''}>
                                                                        <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{index + 1}</td>
                                                                        <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user' : ''}>
                                                                            {username} {isCurrentUser && '(YOU)'}
                                                                        </td>
                                                                        <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{data.totalPoints}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) :
                                                            currentCompetition === 'SPFL' && spflParticipants.length > 0 ? (
                                                                spflParticipants.slice(0, showAllSPFL ? spflParticipants.length : 10).map((data, index) => {
                                                                    const username = extractUsernameFromDisplayName(data.displayName || data.userId);
                                                                    const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

                                                                    return (
                                                                        <tr key={data.userId} className={isCurrentUser ? 'current-user-row' : ''}>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{index + 1}</td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user' : ''}>
                                                                                {username} {isCurrentUser && '(YOU)'}
                                                                            </td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{data.totalPoints}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : 
                                                            currentCompetition === 'Championship' && championshipParticipants.length > 0 ? (
                                                                championshipParticipants.slice(0, showAllChampionship ? championshipParticipants.length : 10).map((data, index) => {
                                                                    const username = extractUsernameFromDisplayName(data.displayName || data.userId);
                                                                    const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

                                                                    return (
                                                                        <tr key={data.userId} className={isCurrentUser ? 'current-user-row' : ''}>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{index + 1}</td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user' : ''}>
                                                                                {username} {isCurrentUser && '(YOU)'}
                                                                            </td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{data.totalPoints}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) :
                                                            currentCompetition === 'UCL' && UCLParticipants.length > 0 ? (
                                                                UCLParticipants.slice(0, showAllUCL ? UCLParticipants.length : 10).map((data, index) => {
                                                                    const username = extractUsernameFromDisplayName(data.displayName || data.userId);
                                                                    const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

                                                                    return (
                                                                        <tr key={data.userId} className={isCurrentUser ? 'current-user-row' : ''}>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{index + 1}</td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user' : ''}>
                                                                                {username} {isCurrentUser && '(YOU)'}
                                                                            </td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{data.totalPoints}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) :
                                                            currentCompetition === 'Bundesliga' && BundesligaParticipants.length > 0 ? (
                                                                BundesligaParticipants.slice(0, showAllBundesliga ? BundesligaParticipants.length : 10).map((data, index) => {
                                                                    const username = extractUsernameFromDisplayName(data.displayName || data.userId);
                                                                    const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

                                                                    return (
                                                                        <tr key={data.userId} className={isCurrentUser ? 'current-user-row' : ''}>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{index + 1}</td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user' : ''}>
                                                                                {username} {isCurrentUser && '(YOU)'}
                                                                            </td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{data.totalPoints}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) :
                                                            currentCompetition === 'Serie A' && SerieAParticipants.length > 0 ? (
                                                                SerieAParticipants.slice(0, showAllSerieA ? SerieAParticipants.length : 10).map((data, index) => {
                                                                    const username = extractUsernameFromDisplayName(data.displayName || data.userId);
                                                                    const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

                                                                    return (
                                                                        <tr key={data.userId} className={isCurrentUser ? 'current-user-row' : ''}>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{index + 1}</td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user' : ''}>
                                                                                {username} {isCurrentUser && '(YOU)'}
                                                                            </td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{data.totalPoints}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) :
                                                            currentCompetition === 'La Liga' && laligaParticipants.length > 0 ? (
                                                                laligaParticipants.slice(0, showAllLaliga ? laligaParticipants.length : 10).map((data, index) => {
                                                                    const username = extractUsernameFromDisplayName(data.displayName || data.userId);
                                                                    const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

                                                                    return (
                                                                        <tr key={data.userId} className={isCurrentUser ? 'current-user-row' : ''}>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{index + 1}</td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user' : ''}>
                                                                                {username} {isCurrentUser && '(YOU)'}
                                                                            </td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{data.totalPoints}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) :
                                                            currentCompetition === 'SASoccer' && SASoccerParticipants.length > 0 ? (
                                                                SASoccerParticipants.slice(0, showAllSASoccer ? SASoccerParticipants.length : 10).map((data, index) => {
                                                                    const username = extractUsernameFromDisplayName(data.displayName || data.userId);
                                                                    const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

                                                                    return (
                                                                        <tr key={data.userId} className={isCurrentUser ? 'current-user-row' : ''}>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{index + 1}</td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user' : ''}>
                                                                                {username} {isCurrentUser && '(YOU)'}
                                                                            </td>
                                                                            <td style={{ color: 'white' }} className={isCurrentUser ? 'current-user-row current-user' : ''}>{data.totalPoints}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) :
                                                            (
                                                                <tr>
                                                                    <td colSpan="3" className="text-center">No data available</td>
                                                                </tr>
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="text-center">
                                                {currentCompetition === 'EPL' && !showAllEpl && eplParticipants.length > 10 && (
                                                    <button className="btn btn-outline-purple" onClick={handleViewMoreEpl}>View More</button>
                                                )}
                                                {currentCompetition === 'SPFL' && !showAllSPFL && spflParticipants.length > 10 && (
                                                    <button className="btn btn-outline-purple" onClick={handleViewMoreSPFL}>View More</button>
                                                )}
                                                 {currentCompetition === 'La Liga' && !showAllLaliga && laligaParticipants.length > 10 && (
                                                    <button className="btn btn-outline-purple" onClick={handleViewMoreLaliga}>View More</button>
                                                )}
                                                 {currentCompetition === 'SASoccer' && !showAllSASoccer && SASoccerParticipants.length > 10 && (
                                                    <button className="btn btn-outline-purple" onClick={handleViewMoreSASoccer}>View More</button>
                                                )}
                                                  {currentCompetition === 'UCL' && !showAllUCL && UCLParticipants.length > 10 && (
                                                    <button className="btn btn-outline-purple" onClick={handleViewMoreUCL}>View More</button>
                                                )}
                                                  {currentCompetition === 'Bundesliga' && !showAllBundesliga && BundesligaParticipants.length > 10 && (
                                                    <button className="btn btn-outline-purple" onClick={handleViewMoreBundesliga}>View More</button>
                                                )}
                                                  {currentCompetition === 'Serie A' && !showAllSerieA && SerieAParticipants.length > 10 && (
                                                    <button className="btn btn-outline-purple" onClick={handleViewMoreSerieA}>View More</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default GlobalLeaderboard;
