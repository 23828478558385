import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

function WorldCup2022PicksBracket() {
  const [fixtures, setFixtures] = useState([]);
  const [selectedRound, setSelectedRound] = useState(1); // Default selected round is 1
  const [showAllFixtures, setShowAllFixtures] = useState(false);

  const rounds = [
    { number: 1, startDate: new Date('2022-11-20'), endDate: new Date('2022-11-30') },
    { number: 2, startDate: new Date('2022-11-30'), endDate: new Date('2022-12-06') },
    { number: 3, startDate: new Date('2022-12-06'), endDate: new Date('2022-12-13') },
    { number: 4, startDate: new Date('2022-12-13'), endDate: new Date('2022-12-23') },
  ];

  useEffect(() => {
    const fetchFixturesData = async () => {
      try {
        // Fetch fixtures data from the API
        const response = await fetch("https://iga-sport.azurewebsites.net/api/league/1/fixtures/season/2022", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const data = await response.json();
        console.log("Fetched fixtures data:", data);

        // Sort the fixtures by start date
        data.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

        // Set the fixtures state with the fetched data
        setFixtures(data);
      } catch (error) {
        console.error("Error fetching fixtures data:", error);
      }
    };

    fetchFixturesData();
  }, []);

  const handleRoundButtonClick = (roundNumber) => {
    setSelectedRound(roundNumber);
  };

  const handleLoadMoreClick = () => {
    setShowAllFixtures(true);
  };

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  return (
    <div className='App-headerV4'>
      <div className='App-headerV4'>
        {/* Button group to select between rounds */}
        <div className="container mb-3 text-center">
          <div className="btn-group" role="group" aria-label="Select Round">
            {rounds.map((round) => (
              <button
                key={round.number}
                type="button"
                className={`btn btn-outline-light ${selectedRound === round.number ? 'active' : ''}`}
                onClick={() => handleRoundButtonClick(round.number)}
              >
                Round {round.number}
              </button>
            ))}
          </div>
        </div>

        {/* Display fixtures for the selected round */}
        <div className="container">
          <div className=''>
            {fixtures.slice(0, showAllFixtures ? fixtures.length : 8).map(fixture => (
              (new Date(fixture.fixture.date) >= rounds[selectedRound - 1].startDate && new Date(fixture.fixture.date) <= rounds[selectedRound - 1].endDate) &&
              <div key={fixture.fixture.id} className='mb-3' style={{ backgroundColor: '#e6eeff' }}>
                {/* Display relevant information for each game */}
                <p className='text-center'>{fixture.league.name}</p>
                <div className="container text-light text-center mb-3">
                  <div className="row">
                    <div className="col order-first text-dark">
                      {fixture.teams.home.name}
                    </div>
                    <div className="col">
                      <input type="text" className="form-control text-center" aria-label="Sizing example input" placeholder='Home Pick' aria-describedby="inputGroup-sizing-sm" />
                    </div>
                    <div className="col">
                      <input type="text" className="form-control text-center" aria-label="Sizing example input" placeholder='Away Pick' aria-describedby="inputGroup-sizing-sm" />
                    </div>
                    <div className="col order-last text-dark">
                      {fixture.teams.away.name}
                    </div>
                  </div>
                </div>
                <p className='text-center'>Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                {/* Add more information as needed */}
              </div>
            ))}
          </div>
          {!showAllFixtures && (
            <div className="text-center mb-3">
              <button type="button" className="btn btn-primary" onClick={handleLoadMoreClick}>Load More</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WorldCup2022PicksBracket;
