import React, { useState } from 'react';
import Image123 from './LuckyClint.png';

function AdComponent() {
  const [isVisible, setIsVisible] = useState(true); // State to control visibility

  const handleClose = () => {
    setIsVisible(false); // Hide the ad when X is clicked
  };

  if (!isVisible) {
    return null; // Don't render the ad if it's not visible
  }

  return (
    <div className="d-flex justify-content-center my-3 position-relative">
      <a href="https://www.luckyclint.bet" target="_blank" rel="noopener noreferrer">
        <img
          src={Image123}
          alt="Ad Banner"
          className="img-fluid"
          style={{
            width: '100%',
            maxWidth: '1200px', // Increase maximum width for larger screens
            aspectRatio: '4 / 1', // Maintain a 3:1 width-to-height ratio
            height: 'auto',
            cursor: 'pointer',
            borderRadius: '3px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        />
      </a>

      {/* Close Button */}
      <button
        onClick={handleClose}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          fontSize: '16px',
          fontWeight: 'bold',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        X
      </button>
    </div>
  );
}

export default AdComponent;
