import React, { useState, useEffect } from 'react';
import { auth, provider } from './../firebase';
import { signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';

const Login1 = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [value, setValue] = useState('');
  const firestore = getFirestore();

  // Check if the user is already signed in when the component mounts
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, redirect to home page
        navigate('/');
      }
    });
    return unsubscribe; // Cleanup function
  }, [navigate]);

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        navigate('/');
      })
      .catch((error) => {
        setError('Invalid login credentials. Please check your email and password.');
        console.error(error);
      });
  };

  const handleClick = () => {
    // Proceed with Google sign-in
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
  
        // Check if the user exists in Firebase Authentication
        if (user && user.email) {
          // Check if the user exists in your database (you might have a separate database)
          // For simplicity, let's assume there's a function checkIfUserExists
          const userExists = checkIfUserExists(user.email);
  
          if (!userExists) {
            // User does not exist in your database, display error and sign out
            setError("This account does not exist.");
            auth.signOut();
            return;
          }
        } else {
          // User data incomplete, sign out and display error
          setError("Failed to sign in with Google. Please try again.");
          auth.signOut();
          return;
        }
  
        // User exists in your database or Firebase Authentication
        // Proceed with your navigation or additional logic here
        setValue(localStorage.getItem('email'));
        navigate('/');
      })
      .catch((error) => {
        setError("Failed to sign in with Google.");
        console.error(error);
      });
  };
  
  const checkIfUserExists = async (email) => {
    // Implement your logic here to check if the user exists in your database
    // You might use Firebase Firestore or another database service
  
    try {
      const userDoc = await getDoc(doc(collection(firestore, 'users'), email));
      return userDoc.exists();
    } catch (error) {
      console.error("Error checking user existence:", error);
      return false;
    }
  };
  
  return (
    <div style={{ backgroundColor: '#222427' }}>
      <Navbar />
      <div className='App-headerV3'>
        <section className="bg-dark py-3 py-md-5 py-xl-8">
          <div className="container">
            <div className="row gy-4 align-items-center">
              <div className="col-12 col-md-6 col-xl-7">
                <div className="d-flex justify-content-center text-bg-dark">
                  <div className="col-12 col-xl-9">
                    <img className="img-fluid rounded mb-4" loading="lazy" src="https://lirp.cdn-website.com/fc5e77e4/dms3rep/multi/opt/cropped-1200x400_Horizontal_Transparent-201w.png" width="285" height="80" alt="login Logo" />
                    <hr className="border-primary-subtle mb-4" />
                    <h1 className="h1 mb-4"> Predictify FREE daily contests!</h1>
                    <p className="lead mb-5">Predictify is your chance to win some major cash prizes!</p>
                    <div className="text-endx">
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-grip-horizontal" viewBox="0 0 16 16">
                        <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-5">
                <div className="card border-0 rounded-4">
                  <div className="card-body p-3 p-md-4 p-xl-5">
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-4">
                          <h2 className="h3 text-center mb-3">Log In</h2>
                          <h3 className="fs-6 fw-normal text-secondary m-0 text-center">By continuing you agree to the <a href='' style={{ textDecoration: 'none' }}>terms & consitions</a> of Predictify.</h3>
                        </div>
                      </div>
                    </div>
                    <form action="#!">
                      <div className="row gy-3 overflow-hidden">
                        <div>
                          <a href="/login" className="btn bsb-btn-2xl btn-outline-dark rounded-0 d-flex align-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app-indicator" viewBox="0 0 16 16">
  <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1z"/>
  <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
</svg>
                            <span className="ms-2 fs-6 flex-grow-1" onClick={handleClick}>
                              Continue with Sports Pedictor
                              </span>
                          </a>
                        </div>
                        <div>
                          <a href="#!" className="btn bsb-btn-2xl btn-outline-dark rounded-0 d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google text-danger" viewBox="0 0 16 16">
                              <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                            </svg>
                            <span className="ms-2 fs-6 flex-grow-1" onClick={handleClick}>Continue with Google</span>
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Login1;
